const NotFoundPage = () => {
  return (
    <div
      style={{ height: "70vh", fontSize: 124, color: "#12b6ca" }}
      className='w-100 d-flex align-items-center justify-content-center'>
      404
    </div>
  );
};

export default NotFoundPage;
