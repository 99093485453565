import { gql, useQuery } from "@apollo/client";
import useLangContext from "hooks/useLangContext";
import { useState } from "react";
import Recess from "typedef/Recess";

/**
 * Hook to get the list of recess of the CityHall's calendar
 * @param cityHallId id del municipio del que se quiere obtener el calendario
 */
const useCalendar = (cityHallId: string | null): [Recess[], boolean] => {
  const [recesses, setRecesses] = useState<Recess[]>([]);
  const { lang } = useLangContext();
  const { loading } = useQuery(query, {
    onCompleted: ({ city_hall_detail: { recesses_agenda: recesses } }) =>
      setRecesses(recesses),
    onError: error =>
      console.error(
        `Problema al cargar los ReCreos del calendario del municipio. ${JSON.stringify(
          error
        )}`
      ),
    variables: {
      id: cityHallId,
      lang: lang.current
    }
  });

  return [recesses, loading];
};

const query = gql`
  query getCityHall($id: String!, $lang: String) {
    city_hall_detail(id: $id) {
      recesses_agenda(lang: $lang) {
        id
        title
        icon {
          uri
        }
        is_favorite
        is_multiple
        score
        date_from
        date_to
        categories(lang: $lang) {
          id
          name
          icon {
            uri
          }
        }
        circuit {
          id
        }
        agenda {
          id
        }
        city_hall {
          id
          name
        }
      }
    }
  }
`;

export default useCalendar;
