import dayjs from "dayjs";
import usePrompt from "hooks/usePrompt";
import { useCallback } from "react";
import DaySelector from "./DaySelector";

const useDatePicker = (
  setSelectedDay: (date: dayjs.Dayjs) => any,
  currentDate: any
) => {
  const prompt = usePrompt();

  return useCallback(async () => {
    prompt<any>(props => (
      <DaySelector
        onClickDate={date => setSelectedDay(date)}
        currentDate={currentDate}
        {...props}
      />
    ))
      .then(response => console.log())
      .catch(error => console.error(error));
  }, [currentDate]);
};

export default useDatePicker;
