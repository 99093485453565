import { useState } from "react";

const useScrollCategories = () => {
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(true);
  //Scroll for the categories inside de bar

  const scrollLeft = (e: any) => {
    e.preventDefault();
    // grab the element
    const element = document.getElementById("filter");
    // get positioning and max
    var containerScrollPosition = element?.scrollLeft;
    let scrollRatio = containerScrollPosition ? containerScrollPosition : 0;

    let maxScrollLeft = 0;

    if (element) {
      maxScrollLeft = element.scrollWidth - element.clientWidth;
    }
    // scrolling
    document.getElementById("filter")?.scrollTo({
      top: 0,
      left: scrollRatio + 350,
      behavior: "smooth"
    });

    setPrev(true);

    if (maxScrollLeft <= scrollRatio + 350) {
      setNext(false);
    }
  };

  const scrollRight = (e: any) => {
    e.preventDefault();

    var containerScrollPosition = document.getElementById("filter")?.scrollLeft;

    let scrollRatio = containerScrollPosition ? containerScrollPosition : 0;

    document.getElementById("filter")?.scrollTo({
      top: 0,
      left: scrollRatio - 350,
      behavior: "smooth"
    });

    setNext(true);

    if (scrollRatio <= 350) {
      setPrev(false);
    }
  };

  return {
    prev,
    next,
    setPrev,
    setNext,
    scrollRight: scrollRight,
    scrollLeft: scrollLeft
  };
};
export default useScrollCategories;
