import React, {
  createContext,
  Fragment,
  useCallback,
  useContext,
  useState
} from "react";

export const PromptContext = createContext<{
  prompt?: <T extends unknown>(
    FunctionalComponent: React.FC<PromptProps<T>>
  ) => Promise<T>;
}>({});

export type PromptProps<T> = {
  onClose: (response: T) => void;
};

export type Prompt = {
  id: number;
  Component: React.FC;
};

export const PromptProvider: React.FC = ({ children }) => {
  const [Prompts, setPrompts] = useState<Array<Prompt>>([]);

  const prompt = useCallback(
    async <T extends unknown>(
      FunctionalComponent: React.FC<PromptProps<T>>
    ) => {
      return new Promise<T>(res => {
        setPrompts(prev => {
          if (prev.length) {
            prev = [];
          }
          const newId = (prev[prev.length - 1]?.id || 0) + 1;

          function onClose(response: T) {
            setPrompts(prev => prev.filter(prompt => prompt.id !== newId));
            res(response);
          }

          return [
            ...prev,
            {
              id: newId,
              Component: () => (
                <Fragment>
                  <div
                    className='container-full modalContainer'
                    onClick={onClose}
                  />
                  <FunctionalComponent onClose={onClose} />
                </Fragment>
              )
            }
          ];
        });
      });
    },
    []
  );

  return (
    <PromptContext.Provider value={{ prompt }}>
      {children}
      {Prompts.map((Prompt, i) => (
        <Prompt.Component key={i} />
      ))}
    </PromptContext.Provider>
  );
};

const usePrompt = () => {
  const context = useContext(PromptContext);

  return context.prompt as <T extends unknown>(
    FunctionalComponent: React.FC<PromptProps<T>>
  ) => Promise<T>;
};

export default usePrompt;
