import { WHITE } from "assets/colors";
import Icon from "assets/icons";
import Stars from "components/Stars/Stars";
import { formatExternalAssetUrl } from "helpers";
import useFaved from "hooks/useFaved";
import React from "react";
import { useHistory } from "react-router";
import CityHall from "typedef/CityHall";

export type CityHallProps = {
  cityHall: CityHall;
};

const CityHallBox: React.FC<CityHallProps> = ({ cityHall }) => {
  const history = useHistory();
  const [faved, fav] = useFaved(cityHall.id, "municipio", cityHall.is_favorite);

  return (
    <div key={cityHall.id} className={`col-11 col-md-3 mb-3 individual`}>
      <div className='ratio-1x1 container'>
        <div className='ratio-1x1 item'>
          <div
            className='w-100 h-100 d-flex align-items-end overflow-hidden clickable position-relative rounded'
            style={{
              background:
                "linear-gradient(360deg, rgba(0, 0, 0, 0.75) 27.78%, rgba(0, 0, 0, 0) 100%), url(" +
                `${formatExternalAssetUrl(cityHall.icon)}` +
                ") center / cover no-repeat"
            }}
            onClick={() => history.push(`cityhall/${cityHall.id}`)}>
            <div className='text position-absolute d-flex flex-column p-3 w-100'>
              <h6 className=' flex-fill text-white'>{cityHall.name}</h6>
              <div className='d-flex flex-row align-items-end justify-content-between'>
                <Stars
                  value={cityHall.score}
                  color={WHITE}
                  disabled
                  size={15}
                  spacing={1}
                />

                <button
                  style={{ all: "unset" }}
                  onClick={e => {
                    e.stopPropagation();
                    fav();
                  }}>
                  <Icon
                    custom='Heart'
                    className='align-self-center'
                    size={20}
                    stroke={WHITE}
                    fill={faved ? WHITE : "transparent"}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CityHallBox;
