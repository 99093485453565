import useCityHall from "./useCityHall";
import Tabs from "../../components/Tabs/Tabs";
import Tab from "../../components/Tabs/Tab";
import useImperdibles from "./useImperdibles";
import useBenefits from "./useBenefits";
import useCalendar from "./useCalendar";
import useInterests from "./useInterests";
import useCityHallFirebaseEvent from "./useCityHallFirebaseEvent";
import RecessCard from "components/RecessCard";
import CityHallHero from "./cityHallHero";
import Card from "components/Card";
import { useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";
import BTNViewInTheMap from "components/BTNViewInTheMap";
import useScrollToTop from "hooks/useScrollToTop";
import AccordionPointOfInterest from "../../components/AccordionPointOfInterest/AccordionPointOfInterest";
import useLangContext from "hooks/useLangContext";
import useCircuits from "./useCircuits";
import ErrorMSJ from "components/ErrorMSJ";
import Loader from "components/Loader";

const CityHall = () => {
  const param = useParams<{ id: string }>();
  const [cityHall, loading] = useCityHall(param.id);
  const { imperdibles } = useImperdibles(param.id);
  const [benefits] = useBenefits(param.id);
  const [agenda] = useCalendar(param.id);
  const [interests] = useInterests(param.id);
  const { lang } = useLangContext();
  const history = useHistory();
  const [circuits] = useCircuits(parseInt(param.id));
  useCityHallFirebaseEvent(param.id);
  useScrollToTop();

  return (
    <div className='container-full'>
      {!loading ? (
        <div className='container-fluid p-0 overflow-hidden'>
          {!loading && cityHall && <CityHallHero {...cityHall} />}
          <Tabs colorHasGray={true}>
            <Tab title={lang.CityHall.safetyPin}>
              {imperdibles.length > 0 ? (
                <>
                  <div className='d-flex justify-content-center mb-5'>
                    <BTNViewInTheMap
                      Text={lang.CityHall.startTour}
                      goToMap={() => history.push(`/ways-route/${param.id}`)}
                    />
                  </div>
                  <div className='row'>
                    {imperdibles.map(imperdible => (
                      <RecessCard recess={imperdible} key={imperdible.id} />
                    ))}
                  </div>
                </>
              ) : (
                <div className='p-3 mb-5 w-100 d-flex justify-content-center'>
                  <h5 className='text-center'>
                    {lang.CityHall.noResultImperdibles}
                    {cityHall.name}
                  </h5>
                </div>
              )}
            </Tab>

            <Tab title={lang.Agendas.title}>
              <div className='row'>
                {Object.keys(agenda) ? (
                  Object.keys(agenda).length > 0 ? (
                    agenda.map(evento => {
                      return (
                        <RecessCard key={evento.id} recess={evento}>
                          {evento.date_from !== null ||
                          evento.date_to !== null ? (
                            <h5 className='text-muted small'>
                              {dayjs(evento.date_from).format("DD / MM")} -{" "}
                              {dayjs(evento.date_to).format("DD / MM")}
                            </h5>
                          ) : (
                            <h5 className='text-muted small' />
                          )}
                          <p className='small mb-2'>{evento.title}</p>
                          <h6 className='small text-muted'>
                            {evento.city_hall?.name}
                          </h6>
                        </RecessCard>
                      );
                    })
                  ) : (
                    <div className='col-lg-8 m-auto'>
                      <h5 className='text-center'>
                        No hay resultados para mostrar.
                      </h5>
                    </div>
                  )
                ) : (
                  <Loader />
                )}
              </div>
            </Tab>

            <Tab title='Circuitos'>
              <div className='row'>
                {circuits?.length > 0 &&
                  circuits?.map(circuit => {
                    return <RecessCard recess={circuit} key={circuit.id} />;
                  })}
                {/* {
                    <RecessCard recess={circuit} key={circuit.id} />
                  } */}
                {/* {circuits &&
                  circuits?.map(
                    circuit =>
                      circuit?.circuit.length > 0 &&
                      circuit?.circuit?.map(_circuit => {
                        return (
                          <RecessCard recess={_circuit} key={_circuit.id} />
                        );
                      })
                  )} */}
                {circuits.length === 0 && (
                  <ErrorMSJ
                    title={`No se encuentran Circuitos disponibles para ${cityHall.name}`}
                  />
                )}
              </div>
            </Tab>

            <Tab title={lang.CityHall.pointOfInt}>
              <AccordionPointOfInterest accordionElements={interests} />
            </Tab>

            <Tab title={lang.Beneficios.title}>
              {benefits.length ? (
                <div className='row'>
                  {benefits.map((benefit, i) => {
                    return (
                      <Card
                        key={benefit.id}
                        score={benefit.score}
                        onClick={() => history.push(`/benefit/${benefit.id}`)}
                        containerClassName={`col-12 col-md-6 mb-3`}
                        img={benefit.icon}>
                        <h6 className='text-danger'>
                          {benefit.commerce?.name}
                        </h6>
                        <h6>{benefit.name}</h6>
                      </Card>
                    );
                  })}
                </div>
              ) : (
                <div className='p-3 mb-5 w-100 d-flex justify-content-center'>
                  <h5 className='text-center'>
                    {lang.CityHall.noResultBenefit1}
                    <span className='font-italic'>{cityHall.name}</span>
                    {lang.CityHall.noResultBenefit2}
                  </h5>
                </div>
              )}
            </Tab>

            <Tab title={lang.CityHall.didYouKnow}>
              <div className='col-lg-8 m-auto'>
                {cityHall.extra_information}
              </div>
            </Tab>
          </Tabs>
        </div>
      ) : null}
    </div>
  );
};

export default CityHall;
