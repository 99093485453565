import MainIcon from "assets/images/benefits.svg";
import useScrollToTop from "hooks/useScrollToTop";
import Tabs from "components/Tabs/Tabs";
import Tab from "components/Tabs/Tab";
import BTNViewInTheMap from "components/BTNViewInTheMap";
import TermsAndConditions from "./ReCreditsList/TermsAndConditions";
import useUser from "hooks/useUser";
import { useState } from "react";
import BenefitsList from "./BenefitsList/index";
import ReCreditsList from "./ReCreditsList";
import useRecreoditsPoints from "./ReCreditsList/useRecreditsPoints";
import classes from "./classes.module.scss";
import useLangContext from "hooks/useLangContext";

const Benefits = () => {
  const [user] = useUser();
  const [isVisible, setIsVisible] = useState(false);
  const acceptTerm = localStorage.getItem(`acceptTerm&condUser` + user?.id);
  const { points } = useRecreoditsPoints();
  const { lang } = useLangContext();
  useScrollToTop();

  return (
    <div className={`container benefits ${classes.titleFlexMobile}`}>
      <div className='section-title d-flex align-items-center justify-content-between'>
        <div className=' d-flex align-items-center my-4'>
          <img src={MainIcon} className='mr-4 logo-sub-title' />
          <h2 className='text-secondary font-weight-bold'>
            {lang.Beneficios.title}
          </h2>
        </div>
        <div className={`btn-viewmap`}>
          <BTNViewInTheMap goToMap={() => setIsVisible(!isVisible)} />
        </div>
      </div>

      {/* points ,devuelve un mensaje si es que no hay campaña de recredito activo, por ende no se deberia mostrar el tab. */}
      <Tabs
        // isView={
        //   user === undefined || points === "No hay ninguna campaña activa"
        //     ? false
        //     : true
        // }
        isView={false}
        position={`justify-content-start`}
        colorHasGray={true}>
        <Tab title={"ReBeneficios"}>
          <BenefitsList mapVisible={isVisible} />
        </Tab>
        <Tab title={"ReCréditos"}>
          {
            //si estas logeado, puedes ver los recreditos. Pero primero tenes que aceptar los terminos y condiciones
            user !== undefined && (
              <>
                {acceptTerm !== "true" ? (
                  <TermsAndConditions userId={user.id} />
                ) : (
                  //en caso de ser aceptados se despliega la lista de recreditos si es que hay y se cargaron
                  <>
                    <ReCreditsList mapVisible={isVisible} />
                  </>
                )}
              </>
            )
          }
        </Tab>
      </Tabs>
    </div>
  );
};

export default Benefits;
