export default {
  title: "Atualmente está em",
  level: "Nivel",
  virtual: "virtual",
  curious: "Curioso",
  explorer: "Explorador",
  expert: "Expert",

  descriptVirtual:
    "Você até gosta de navegar no aplicativo, mas só faz uma vizitinha de vez em quando",
  descriptCurius:
    "Você começa a gostar cada vez mais de viajar e conhecer pessoalmente tudo o que você só viu pelas redes",
  descriptExplorer:
    "Você já visitou e descobriu vários lugares e eventos incríveis para recomendar",
  desciptExpert:
    "Você se formou como guia especialista para mostrar a provincia"
};
