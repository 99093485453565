import useCarousel from "./useCarousel";
import CarruselHero from "../../../components/CarruselHero/CarruselHero";

export type HeaderProps = {
  loading?: boolean;
};

const Destacado = () => {
  const [media] = useCarousel();
  return (
    <div>
      <CarruselHero uris={media} className='col-lg-12 col-md-12 col-12' />
    </div>
  );
};

export default Destacado;
