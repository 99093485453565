import * as React from "react";

function SvgUserCircle(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={32} height={32} {...props}>
      <path d='M16.156.462C7.516.462.512 7.466.512 16.106S7.516 31.75 16.156 31.75 31.8 24.746 31.8 16.106 24.796.462 16.156.462zM2.645 16.107a13.512 13.512 0 1124.128 8.355 13.678 13.678 0 00-6.748-5.255 6.761 6.761 0 001.82-4.7c0-3.556-2.553-6.4-5.689-6.4s-5.689 2.844-5.689 6.4a6.736 6.736 0 001.963 4.821 12.654 12.654 0 00-6.613 5.461 13.448 13.448 0 01-3.172-8.683zm13.867 2.489c-.39.002-.779.023-1.166.064a4.174 4.174 0 01-2.745-4.153c0-2.354 1.593-4.267 3.556-4.267s3.556 1.913 3.556 4.267a4.216 4.216 0 01-2.617 4.11c-.192-.007-.384-.021-.583-.021zm-9.116 7.822a10.405 10.405 0 017.822-5.561 4.868 4.868 0 002.091-.05 10.932 10.932 0 017.95 5.276 13.46 13.46 0 01-17.884.334h.021z' />
    </svg>
  );
}

export default SvgUserCircle;
