import React from "react";
import classes from "./index.module.scss";
type Props = {
  title?: string;
};

const Tab: React.FC<Props> = ({ children }) => {
  return (
    <div className={`container p-0 ${classes.content}`}>
      <div>{children}</div>
    </div>
  );
};

export default Tab;
