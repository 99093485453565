import * as React from "react";

function SvgGenders(props) {
  return (
    <svg width={19} height={26} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M6.583 18.88v3.124H4.389a.439.439 0 00-.44.439v.878c0 .242.197.438.44.438h2.194v1.756c0 .242.196.439.439.439h.877a.439.439 0 00.44-.44V23.76h2.194a.439.439 0 00.438-.438v-.878a.439.439 0 00-.438-.439H8.338v-3.125A7.465 7.465 0 0013.32 6.855l3.358-3.358v1.83c0 .243.196.44.438.44h.878a.439.439 0 00.439-.44V.94A.439.439 0 0017.993.5h-4.388a.439.439 0 00-.44.439v.878c0 .242.197.438.44.438h1.83l-3.358 3.358C7.29 1.82 0 5.158 0 11.47a7.46 7.46 0 006.583 7.408zM7.46 5.765a5.705 5.705 0 11-5.706 5.705 5.712 5.712 0 015.706-5.705z' />
      <path d='M5.266 14.105h4.389a.878.878 0 100-1.756H5.266a.878.878 0 100 1.756zM5.266 10.594h4.389a.878.878 0 100-1.756H5.266a.878.878 0 000 1.756z' />
    </svg>
  );
}

export default SvgGenders;
