import useCityHalls from "./useCityHalls";
import CityHallBox from "./CityHallBox";

const CityHalls = () => {
  const cityHalls = useCityHalls();

  return (
    <div className='container-full block d-flex flex-row overflow-auto align-items-center justify-content-between scrollable mt-3'>
      {cityHalls.list.map((cityHall, i) => (
        <CityHallBox key={i} cityHall={cityHall} />
      ))}
    </div>
  );
};

export default CityHalls;
