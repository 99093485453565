export default {
  startTour: "start tour",
  safetyPin: "Safety Pin",
  pointOfInt: "Points of interest",
  noResultBenefit1: "Oops There are still no ",
  noResultBenefit2:
    " benefits in Enter Benefits and find out the ReBenefits we have for you",
  noResultImperdibles: "There are still no safety pins available in ",
  didYouKnow: "¿Did you know?"
};
