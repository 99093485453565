import * as React from "react";

function SvgTemperature(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={32} height={32} {...props}>
      <path d='M31.089 5.578a4.502 4.502 0 00-7.685-3.183L9.266 16.533a7.252 7.252 0 00-6.501 2.57 7.311 7.311 0 005.433 11.99l.188.002a7.244 7.244 0 005.163-2.14 7.326 7.326 0 002.082-6.054L29.769 8.763a4.466 4.466 0 001.319-3.184zM14.471 22.203a.653.653 0 00-.183.574 6.01 6.01 0 01-1.667 5.248 5.995 5.995 0 01-8.692-.225 5.992 5.992 0 015.461-9.923.653.653 0 00.574-.183l14.37-14.37a3.188 3.188 0 014.508 4.508l-14.37 14.371z' />
      <path d='M25.418 4.408L10.247 19.579l-1.115-.188a4.442 4.442 0 00-4.184 1.539 4.523 4.523 0 00.119 5.842 4.434 4.434 0 003.313 1.473 4.456 4.456 0 004.393-5.213l-.188-1.116 15.172-15.17a1.653 1.653 0 10-2.338-2.338zM11.416 21.229a.658.658 0 00-.183.574l.246 1.45a3.138 3.138 0 01-3.098 3.679 3.13 3.13 0 01-2.226-.922c-.038-.039-.077-.079-.113-.12a3.188 3.188 0 01-.085-4.116 3.132 3.132 0 012.957-1.089l1.449.246a.656.656 0 00.575-.183l8.542-8.542.48.48-8.545 8.541zM26.827 5.818l-5.94 5.94-.481-.481 5.94-5.94a.34.34 0 01.568.152.34.34 0 01-.088.328z' />
    </svg>
  );
}

export default SvgTemperature;
