import { gql, useQuery } from "@apollo/client";
import { formatExternalAssetUrl } from "helpers";
import useLangContext from "hooks/useLangContext";
import { useState } from "react";
import { useHistory } from "react-router";
import CityHall from "typedef/CityHall";

const useCityHall = (id: string | null): [Partial<CityHall>, boolean] => {
  const [cityHall, setCityHall] = useState<Partial<CityHall>>({ media: [] });
  const [loading, setLoading] = useState(true);
  const { lang } = useLangContext();
  const history = useHistory();
  useQuery(query, {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      if (data?.city_hall_detail) {
        setCityHall({
          ...cityHall,
          ...data.city_hall_detail,
          media: [
            ...data.city_hall_detail.images.map((image: { uri: string }) => ({
              uri: formatExternalAssetUrl(image),
              title: data.city_hall_detail.name
            })),
            ...data.city_hall_detail.videos
          ]
        });
      } else {
        history.goBack();
        alert("No existe el municipio al que intenta ingresar");
      }
      setLoading(false);
    },
    variables: {
      id: id,
      lang: lang.current
    }
  });

  return [cityHall, loading];
};

const query = gql`
  query getCityHall($id: String!, $lang: String) {
    city_hall_detail(id: $id, lang: $lang) {
      id
      name
      latitude
      longitude
      is_favorite
      review
      images {
        uri
      }
      videos {
        url
      }
      extra_information
    }
  }
`;

export default useCityHall;
