import Icon from "assets/icons";
import { formatExternalAssetUrl } from "helpers";
import useSearchQuery from "hooks/useSearchQuery";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import LeftArrow from "assets/images/slider-left.svg";
import Arrow from "assets/images/slider-arrow.svg";
import BellIcon from "assets/images/bell-icon.svg";
import useScrollCategories from "./useScrollCategories";
import { max } from "dayjs";

export type Util = {
  icon: {
    uri: string;
  };
  title?: string;
  name?: string;
  id: string;
};

export type UtilityBarProps = {
  title: string;
  utils: Array<Util>;
  searchBar?: string;
  className?: string;
  action?: string;
  hasSearchBar?: Boolean;
};

const UtiliyBar: React.FC<UtilityBarProps> = ({
  title,
  utils,
  className,
  searchBar,
  action,
  hasSearchBar
}) => {
  const searchQuery = useSearchQuery();
  const [focus, setFocus] = useState(false);
  const showClear = useMemo(() => focus || searchQuery.get("busqueda"), [
    searchQuery,
    focus
  ]);
  const location = useLocation();

  const useScroll = useScrollCategories();
  function triggerSearchQuery(e: any) {
    e.target.value ? setFocus(true) : setFocus(false);

    if (e.currentTarget.value.length > 0) {
      if (location.pathname === "/" && !searchQuery.get("busqueda")) {
        searchQuery.append("busqueda", e.currentTarget.value);
      } else if (location.pathname === "/" && searchQuery.get("busqueda")) {
        location.search = "";
        searchQuery.append("busqueda", e.currentTarget.value);
      }
    } else {
      searchQuery.delete("busqueda");
      location.search = "";
    }
  }

  function filterAction(e: any, id: string) {
    if (searchQuery.get("filtro") === id) {
      e.preventDefault();
      searchQuery.delete("filtro");
      window.location.reload();
    }
    if (searchQuery.get("busqueda")) {
      searchQuery.delete("busqueda");
    }
  }

  return (
    <div
      className={`container-full bg-white p-3 rounded position-relative ${className}`}>
      <div className='d-lg-flex flex-row align-items-center'>
        <h5 className='flex-fill flex-wrap font-weight-light d-none d-sm-block'>
          <small>{title}</small>
        </h5>

        {hasSearchBar == undefined && !hasSearchBar ? (
          <form action={action}>
            <div className='d-flex flex-row align-items-center'>
              <label className={`search input ${showClear ? "focus" : ""}`}>
                <input
                  defaultValue={searchQuery.get("busqueda") || ""}
                  className='flex-fill'
                  name='busqueda'
                  onChange={e => triggerSearchQuery(e)}
                  placeholder={searchBar}
                />
                <Icon className='' custom='Search' size={15} />
                <button
                  className={showClear ? "clear visible" : "clear"}
                  type='reset'
                  onClick={() => searchQuery.delete("busqueda")}>
                  <Icon
                    className={showClear ? "clear visible" : "clear"}
                    custom='Cross'
                  />
                </button>
              </label>
            </div>
          </form>
        ) : null}
      </div>
      <form
        action={action}
        className='d-flex jusfity-content-between align-items-center'>
        <div
          onClick={e => useScroll.scrollRight(e)}
          className={`slider-button left mr-2 ${
            !useScroll.prev ? "inactive" : "active"
          }`}>
          <img src={LeftArrow} />
        </div>
        <div
          id='filter'
          className='container-full d-flex flex-row overflow-auto align-items-center justify-content-start scrollable mt-3'>
          {utils.map(util => (
            <button
              key={util.name || util.title}
              name='filtro'
              value={util.id}
              className={`secondary secondary--purple ${
                searchQuery.get("filtro") === util.id && "selected"
              } `}
              onClick={e => filterAction(e, util.id)}>
              {util.id == -1 ? (
                <div className='d-flex flex-row align-items-center justify-content-center'>
                  <img height={29} width={29} src={BellIcon} />
                  <p className='text-nowrap' style={{ fontSize: "16px" }}>
                    {util.title || util.name}
                  </p>
                </div>
              ) : (
                <div className='d-flex flex-row align-items-center justify-content-center'>
                  <img
                    height={29}
                    width={29}
                    src={formatExternalAssetUrl(util.icon)}
                  />
                  <p className='text-nowrap' style={{ fontSize: "16px" }}>
                    {util.title || util.name}
                  </p>
                </div>
              )}
            </button>
          ))}
        </div>
        <div
          onClick={e => useScroll.scrollLeft(e)}
          className={`slider-button right ml-2 ${
            !useScroll.next ? "inactive" : "active"
          }`}>
          <img src={Arrow} />
        </div>
      </form>
    </div>
  );
};

export default UtiliyBar;
