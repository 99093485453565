import React from "react";
import { useHistory } from "react-router-dom";
import BackArrow from "./../../assets/images/back-arrow.svg";
import "./style.scss";
import { formatExternalAssetUrl, YoutubeIdVideosToEmbed } from "helpers";
import Recess from "../../typedef/Recess";
import Stars from "../Stars/Stars";
import { WHITE } from "../../assets/colors/index";
import useOnClick from "../../screens/Home/Destacado/useOnClick";
import useCarruselSelected from "./useCarruselSelected";
import useGalleryTransform from "./useGalleryTransform";
import useYoutubeIdVideos from "components/CarruselHero/useYoutubeIdVideos";
import useLangContext from "hooks/useLangContext";

export type CarruselHeroProps = {
  uris: Array<{
    uri?: string;
    recess?: Recess;
    image_focus: string;
  }>;
  videos?: Array<{ url: string }>;
  className?: string;
};

const CarruselHero: React.FC<CarruselHeroProps> = images => {
  const videos = useYoutubeIdVideos(images.videos);
  let carrouselValues = useGalleryTransform(images?.uris, videos);
  const history = useHistory();
  const { lang } = useLangContext();
  const sizing = images.className
    ? images.className
    : "col-lg-6 col-md-6 col-12";

  const [carruselSelected, setCarruselSelected] = useCarruselSelected(
    images.uris?.length
  );
  const onClick = useOnClick();

  return (
    <div className={` ${sizing} p-0`}>
      {/*changing the layout for the home slider*/}

      {history.location.pathname === "/" ||
      history.location.pathname === "/agenda" ? (
        <div className='carrusel-hero home'>
          <div className='points-carrusel container'>
            <div className='data content'>
              {images.uris?.map((item, i) => (
                <div key={item.recess?.id}>
                  {i === carruselSelected ? (
                    <div
                      className='inner-content'
                      onClick={onClick(item.recess)}>
                      <p className='subtitle text-white'>
                        {item.recess?.city_hall?.name}
                      </p>
                      <h5 className='title font-weight-bold text-white'>
                        {item.recess?.title}
                      </h5>
                      {item.recess?.categories ? (
                        <div className='d-flex flex-row mb-3'>
                          <img
                            width={20}
                            height={20}
                            style={{ filter: "invert(1)" }}
                            src={formatExternalAssetUrl(
                              item.recess?.categories[0].icon
                            )}
                          />
                          <h6 className='text-white ml-1 my-0 font-weight-500'>
                            {item.recess?.categories[0].name}
                          </h6>
                        </div>
                      ) : null}
                      {item.recess?.score !== undefined && (
                        <Stars
                          value={item.recess?.score}
                          disabled
                          color={WHITE}
                        />
                      )}
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
            <div className='points-container'>
              {images.uris?.map((item, i, array) => {
                if (array.length > 1) {
                  if (carruselSelected == i) {
                    return (
                      <div className='point selected' key={i}>
                        {" "}
                      </div>
                    );
                  }
                  return (
                    <div
                      className='point'
                      onClick={() => setCarruselSelected(i)}
                      key={i}></div>
                  );
                }
              })}
            </div>
          </div>

          {images.uris?.map((item, i) => {
            // Si tiene uri es imagen

            return item.uri ? (
              <div
                key={i}
                className={`carrusel-item ${
                  i === carruselSelected ? "active" : ""
                }`}>
                <img
                  style={{
                    objectFit: "cover",
                    objectPosition:
                      item.image_focus == 1
                        ? "center top"
                        : item.image_focus == 3
                        ? "center bottom"
                        : ""
                  }}
                  src={formatExternalAssetUrl(item)}></img>
              </div>
            ) : (
              <div
                key={i}
                className={`carrusel-item ${
                  i === carruselSelected ? "active" : ""
                }`}>
                <iframe
                  width='100%'
                  height='480px'
                  type='text/html'
                  src={`https://www.youtube.com/embed/${YoutubeIdVideosToEmbed(
                    item.video_url
                  )}?enablejsapi=1&playsinline=1&controls=1&enablejsapi=1&showinfo=0`}
                  title='YouTube video player'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen></iframe>
              </div>
            );
          })}
        </div>
      ) : (
        <div className='carrusel-hero'>
          <div className='points-carrusel'>
            <button className='backButton' onClick={() => history.goBack()}>
              <img src={BackArrow} />
              {lang.Elements.btnReturn}
            </button>
            <div className='points-container'>
              {carrouselValues?.length > 1 &&
                carrouselValues?.map((item, i, array) => {
                  if (carruselSelected == i) {
                    return (
                      <div className='point selected' key={i}>
                        {" "}
                      </div>
                    );
                  }
                  return (
                    <div
                      className='point'
                      onClick={() => setCarruselSelected(i)}
                      key={i}></div>
                  );
                })}
            </div>
          </div>
          {carrouselValues?.map((item, i) => {
            if (item[i]?.uri) {
              return (
                <div
                  key={i}
                  className={`carrusel-item ${
                    i === carruselSelected ? "active" : ""
                  }`}>
                  <img src={formatExternalAssetUrl(item[i])}></img>
                </div>
              );
            } else {
              if (item[i]?.video_url) {
                return (
                  <div
                    key={i}
                    className={`carrusel-item ${
                      i === carruselSelected ? "active" : ""
                    }`}>
                    <iframe
                      width='100%'
                      height='480px'
                      type='text/html'
                      src={`https://www.youtube.com/embed/${YoutubeIdVideosToEmbed(
                        item[i]?.video_url
                      )}?enablejsapi=1&playsinline=1&controls=1&enablejsapi=1&showinfo=0`}
                      title='YouTube video player'
                      frameBorder='0'
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                      allowFullScreen></iframe>
                  </div>
                );
              }
            }
          })}
        </div>
      )}
    </div>
  );
};

export default CarruselHero;
