import * as React from "react";

function SvgPalmerCircle(props) {
  return (
    <svg
      width={27}
      height={26}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 30 30'
      {...props}>
      <g fill='none' fillRule='evenodd'>
        <circle
          cx={12.65}
          cy={12.65}
          r={12.65}
          stroke='#666'
          strokeWidth={0.55}
          transform='translate(1 .6)'
        />
        <path
          d='M21.337 20.107h-3.3c0-3.96-2.029-7.198-3.727-9.24l-.075-.083c.349-.418.833-.7 1.369-.797 1.5-.26 3.106.933 3.132.95a.66.66 0 00.797-1.051c-.084-.062-2.077-1.549-4.145-1.201a3.52 3.52 0 00-1.676.77c.682-.805 1.914-1.853 3.52-1.479a.66.66 0 10.303-1.284c-1.883-.44-3.344.44-4.299 1.35.137-1.051.572-2.433 1.853-3.225a.66.66 0 00-.695-1.122c-2.2 1.369-2.53 3.885-2.544 5.153a6.635 6.635 0 00-5.055 1.98.66.66 0 10.933.932 5.324 5.324 0 013.748-1.606c-1.32.555-2.675 1.703-2.675 3.78a.66.66 0 001.32 0c0-1.478 1.025-2.2 1.866-2.56-1.18 1.887-.563 4.756-.533 4.9a.66.66 0 001.29-.285c-.19-.88-.396-3.12.598-4.22 1.549 1.861 3.375 4.796 3.375 8.325h-9.46a.66.66 0 000 1.32h14.08a.66.66 0 000-1.32v.013z'
          fill='#666'
        />
      </g>
    </svg>
  );
}

export default SvgPalmerCircle;
