import { useEffect } from "react";

const useNotificationsAuthorization = () => {
  // const token = useSelector(store => store.user.accessToken);
  // const client = useCustomClient(token);

  useEffect(() => {
    // async function requestUserPermission() {
    //   try {
    //     const authStatus = await messaging().requestPermission();
    //     const enabled = authStatus === messaging.AuthorizationStatus.AUTHORIZED || authStatus === messaging.AuthorizationStatus.PROVISIONAL;
    //     if (enabled) {
    //       const token = await messaging().getToken();
    //       await client.mutate({mutation, variables: {token}});
    //     }
    //   } catch (error) {
    //     console.error(`Tuvimos un problema configurando las notificaciones ${JSON.stringify(error)}`);
    //   }
    // };
    // messaging().onTokenRefresh(async token => await client.mutate({mutation, variables: {token}}));
    // requestUserPermission();
  }, []);
};

export default useNotificationsAuthorization;
