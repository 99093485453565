import { useMemo, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import useGPS from "hooks/useGPS";
import CityHall from "typedef/CityHall";
import useLangContext from "hooks/useLangContext";

const useCityHalls = () => {
  const [cityHalls, setCityHalls] = useState<Array<CityHall>>([]);
  const [coords] = useGPS();
  const { lang } = useLangContext();
  const { loading } = useQuery(query, {
    onCompleted: data => setCityHalls(data.city_hall_list.data),
    onError: err =>
      console.error(
        "Tuvimos un problema cargando los municipios para el home:",
        JSON.stringify(err)
      ),
    variables: {
      latitude: coords?.latitude,
      longitude: coords?.longitude,
      lang: lang.current
    }
  });

  return useMemo(
    () => ({
      list: cityHalls,
      loading
    }),
    [cityHalls, loading]
  );
};

const query = gql`
  query getCityHalls($latitude: Float, $longitude: Float, $lang: String) {
    city_hall_list(
      limit: 4
      latitude: $latitude
      longitude: $longitude
      lang: $lang
    ) {
      data {
        id
        name
        is_favorite
        longitude
        latitude
        icon {
          uri
        }
      }
    }
  }
`;

export default useCityHalls;
