import * as React from "react";

function SvgHandSanitizerCircle(props) {
  return (
    <svg
      width={27}
      height={27}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 30 30'
      {...props}>
      <g transform='translate(1)' fill='none' fillRule='evenodd'>
        <g fill='#666' fillRule='nonzero'>
          <path d='M18.577 18.638a.233.233 0 00-.165-.068h-4.664a.993.993 0 01-.7-.292l-2.13-2.145a.233.233 0 00-.33.328l2.13 2.145c.27.273.646.43 1.03.43h4.568l1.902 1.901a.232.232 0 10.329-.329l-1.97-1.97z' />
          <path d='M22.42 17.485l-2.26-2.258a1.994 1.994 0 00-1.919-1.445c-.742 0-1.418.407-1.766 1.063a.027.027 0 01-.023.016h-1.11l-1.81-1.81a.888.888 0 00-.631-.262.888.888 0 00-.857.643.874.874 0 00-1.444.38.943.943 0 00-1.235 1.416l.523.527a.233.233 0 10.33-.328l-.523-.527a.473.473 0 010-.674.473.473 0 01.674 0l2.706 2.707a.233.233 0 00.165.068h3.54a.233.233 0 100-.466h-2.393a.605.605 0 010-1.209h2.065c.182 0 .349-.1.435-.263a1.53 1.53 0 011.354-.815c.703 0 1.314.475 1.485 1.156.01.041.032.078.062.108l2.302 2.303a.232.232 0 00.33 0 .233.233 0 000-.33zm-9.084-.95l-2.187-2.187a.408.408 0 01.576-.577l1.697 1.697a1.063 1.063 0 00.083 1.067h-.169zm.377-1.435l-1.115-1.115a.429.429 0 01.606-.605l1.48 1.48h-.297c-.255 0-.49.09-.674.24zM12.492 19.095a.233.233 0 00-.233.233v.52c0 .381-.31.692-.693.692h-3.79a.693.693 0 01-.692-.693v-6.842a.233.233 0 00-.466 0v6.842c0 .639.52 1.159 1.158 1.159h3.79a1.16 1.16 0 001.159-1.159v-.52a.233.233 0 00-.233-.232zM6.85 12.245a.233.233 0 00.234-.233v-.296c0-.41.157-.799.442-1.093L8.545 9.57h2.253l1.019 1.052c.285.294.442.682.442 1.093v.428a.233.233 0 00.466 0v-.428c0-.532-.204-1.035-.574-1.417l-1.087-1.123a.232.232 0 00-.034-.029v-.894a.738.738 0 00-.657-.732v-.807h2.267v.626c0 .264.215.479.479.479h.656a.48.48 0 00.48-.48v-.755c0-.818-.667-1.484-1.485-1.484H9.538a.233.233 0 000 .466h3.232a1.02 1.02 0 011.018 1.018v.756a.013.013 0 01-.013.013h-.656a.013.013 0 01-.014-.013v-.756a.336.336 0 00-.335-.335H7.71a.039.039 0 01-.038-.039v-.606c0-.02.017-.038.038-.038h.836a.233.233 0 000-.466H7.71a.505.505 0 00-.504.504v.606c0 .278.226.504.504.504h1.26v.807a.738.738 0 00-.657.732v.894a.234.234 0 00-.034.029l-1.087 1.123c-.37.382-.574.885-.574 1.417v.296c0 .129.104.233.233.233zm3.057-5.53v.801h-.472v-.802h.472zM9.05 7.981h1.243c.15 0 .27.122.27.271v.852H8.78v-.852c0-.15.121-.27.27-.27z' />
          <path d='M14.167 8.49c-.634.901-.956 1.566-.956 1.976 0 .564.307 1.083.802 1.356a.232.232 0 00.316-.091.233.233 0 00-.091-.317 1.084 1.084 0 01-.561-.948c0-.142.085-.591.871-1.707.05-.07.126-.11.211-.11.086 0 .163.04.212.11.255.362.468.702.615.983A.233.233 0 0016 9.525a9.557 9.557 0 00-.647-1.034.725.725 0 00-1.185 0zM15.574 10.482c-.684.918-.828 1.386-.828 1.617 0 .626.51 1.136 1.136 1.136.627 0 1.136-.51 1.136-1.136 0-.231-.143-.699-.828-1.617a.38.38 0 00-.308-.154.38.38 0 00-.308.154zm.979 1.617a.671.671 0 01-1.341 0c0-.203.26-.687.67-1.25.41.563.67 1.047.67 1.25z' />
        </g>
        <circle
          cx={12.65}
          cy={12.65}
          r={12.65}
          stroke='#666'
          strokeWidth={0.55}
          transform='translate(1 .6)'
        />
      </g>
    </svg>
  );
}

export default SvgHandSanitizerCircle;
