import { Fragment, useEffect } from "react";
import useSearch from "hooks/useSearch";
import useSearchQuery from "hooks/useSearchQuery";
import useGPS from "hooks/useGPS";
import Loader from "assets/images/loader.svg";
import RecessCard from "components/RecessCard";
import Recess from "typedef/Recess";
import CityHallCard from "components/CityHallCard";
import CityHall from "typedef/CityHall";

const SearchResults = () => {
  const searchQuery = useSearchQuery();
  const key = searchQuery.get("busqueda") || "";
  const search = useSearch();
  const [coords] = useGPS();

  useEffect(() => {
    if (key.length > 3) {
      const timeout = setTimeout(() => {
        search.search({ variables: { key } });
      }, 500);
      return () => clearTimeout(timeout);
    } else {
      return;
    }
  }, [key]);

  if (search.noResults)
    return (
      <div className='noResults text-center'>
        <h3 className='my-5 font-weight-bold'>
          ¡Ups! No se encontraron resultados para esta busqueda
        </h3>
      </div>
    );

  if (search.loading)
    return (
      <div className='noResults text-center'>
        <img src={Loader} />
      </div>
    );

  return (
    <Fragment>
      {search.results.map((result, i) => (
        <div className='row' key={i}>
          <h5 className='text-muted font-weight-bold col-12 my-5'>
            <div className='section-title'>
              <img src={result.icon} />
              {result.title}
            </div>
          </h5>
          {result.data.map((item: any) =>
            item.__typename === "CityHall" ? (
              <CityHallCard
                coords={coords}
                key={item.id + item.__typename}
                cityHall={item as CityHall}
              />
            ) : (
              <RecessCard
                key={item.id + item.__typename}
                recess={item as Recess}
              />
            )
          )}
        </div>
      ))}
    </Fragment>
  );
};

export default SearchResults;
