import * as React from "react";

function SvgChessBlueMagenta(props) {
  return (
    <svg width={41} height={42} xmlns='http://www.w3.org/2000/svg' {...props}>
      <g fill='none' fillRule='evenodd'>
        <path fill='#24B5C7' d='M0 0h41v42H0z' />
        <path fill='#E2287D' d='M0 0h20v21H0zM20 21h21v21H20z' />
      </g>
    </svg>
  );
}

export default SvgChessBlueMagenta;
