import Logo from "../../assets/images/logo-recreo-blanco.svg";
import Banner from "assets/images/banner.png";
import BannerMobile from "assets/images/banner-mobile.png";
import BannerText from "assets/images/banner-text.png";
import AppStore from "../../assets/images/app-store-button.png";
import PlayStore from "../../assets/images/google-play-button.png";
import Claim from "./../../assets/images/claim.png";
import FooterLogo from "../../assets/images/landing-footer-logo.png";
import Facebook from "./../../assets/images/facebook-logo.svg";
import Instagram from "./../../assets/images/instagram-logo.svg";
import "./style.scss";
import { useRedirectBySistemOperative } from "./useRedirectBySistemOperative";

// paradores img
// top
import LogoRecreo from "./../../assets/images/paradores/img/logo-recreo-blanco.1eb9da5d.svg";
// main
import MainImage from "./../../assets/images/paradores/img/1920X.png";
import MainImageMob from "./../../assets/images/paradores/img/500X500.png";
// paradores
import ParadoresIco from "./../../assets/images/paradores/img/ico.png";
import TitleEntrada from "./../../assets/images/paradores/img/title-entrada.png";
//badges
import BadgesGooglePlay from "./../../assets/images/paradores/img/badge-google-play.png";
import BadgesAppStore from "./../../assets/images/paradores/img/badge-app-store.png";
// footer
import Footer1 from "./../../assets/images/paradores/img/footer1.png";
import Footer2 from "./../../assets/images/paradores/img/footer2.png";
import Footer3 from "./../../assets/images/paradores/img/footer3.png";
import Footer4 from "./../../assets/images/paradores/img/footer4.png";

const Paradores = () => {
  useRedirectBySistemOperative();

  return (
    <div className='paradores-container container-full'>
      <div className='p-0'>
        <div className='paradoresPage'>
          {/* NAV */}
          <nav className='navbar navbar-default back-navbar'>
            <div className='container-fluid'>
              <div className='navbar-header'>
                <a
                  href='https://recreo.gba.gob.ar/'
                  alt='ReCreo en la Provincia'>
                  <img
                    className='img-fluid img-navbar'
                    src={LogoRecreo}
                    alt='ReCreo en la Provincia'
                  />
                </a>
              </div>
            </div>
          </nav>
          {/* END NAV */}

          {/* MAIN IMAGE */}
          <picture>
            <source srcSet={MainImage} media='(min-width: 1000px)'></source>
            <source srcSet={MainImageMob} media='(min-width: 800px)'></source>
            <img
              src={MainImageMob}
              className='img-fluid'
              alt='ReCreo en la Provincia'
            />
          </picture>
          {/* END MAIN IMAGE */}

          {/* PARADORES */}
          <div className='wrapper-red'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-2 offset-md-1'>
                  <a href='https://recreo.page.link/gNKbi9LxmWC6cZnF8'>
                    <div className='paradores'>
                      <img src={ParadoresIco} alt='ReCreo en la Provincia' />
                      <h3>CHASCOMÚS</h3>
                    </div>
                  </a>
                </div>

                <div className='col-md-2'>
                  <a href='https://recreo.page.link/aX59xrKmEo7uqLgn7'>
                    <div className='paradores'>
                      <img src={ParadoresIco} alt='ReCreo en la Provincia' />
                      <h3>LA COSTA</h3>
                      <p>(Mar de Ajó)</p>
                    </div>
                  </a>
                </div>

                <div className='col-md-2'>
                  <a href='https://recreo.page.link/NxqzV3eSDVfzPA427'>
                    <div className='paradores'>
                      <img src={ParadoresIco} alt='ReCreo en la Provincia' />
                      <h3>
                        VILLA
                        <br />
                        GESELL
                      </h3>
                    </div>
                  </a>
                </div>

                <div className='col-md-2'>
                  <a href='https://recreo.page.link/zGZj7Bhn6MjUFJsn7'>
                    <div className='paradores'>
                      <img src={ParadoresIco} alt='ReCreo en la Provincia' />
                      <h3>
                        MAR DEL
                        <br />
                        PLATA
                      </h3>
                    </div>
                  </a>
                </div>

                <div className='col-md-2'>
                  <a href='https://recreo.page.link/wzarmt6eBe1VQMat5'>
                    <div className='paradores'>
                      <img src={ParadoresIco} alt='ReCreo en la Provincia' />
                      <h3>
                        MONTE
                        <br />
                        HERMOSO
                      </h3>
                    </div>
                  </a>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  <img
                    src={TitleEntrada}
                    className='img-fluid paradores-title-2 rt20'
                    alt='ReCreo en la Provincia'
                  />
                </div>
              </div>
            </div>
          </div>
          {/* END PARADORES */}

          {/* APP BADGES */}
          <div className='wrapper-green'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <h3 className='app-mensaje'>
                    Bajate la app y conocé toda la programación, participá de
                    trivias, sorteos, etc.
                  </h3>
                  <a
                    className='mr-1'
                    target='_blank'
                    href='https://play.google.com/store/apps/details?id=org.gba.recreo'
                    rel='noreferrer'>
                    <img
                      src={BadgesGooglePlay}
                      className='img-badges'
                      alt='ReCreo en la Provincia'
                    />
                  </a>
                  <a
                    className='ml-1'
                    target='_blank'
                    href='https://apps.apple.com/us/app/recreo-en-la-provincia/id1542361713?app=itunes&amp;ign-mpt=uo%3D4'
                    rel='noreferrer'>
                    <img
                      src={BadgesAppStore}
                      className='img-badges'
                      alt='ReCreo en la Provincia'
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* END APP BADGES */}

          {/* LINE UP */}
          <div className='wrapper-lightgreen'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <h3 className='bandas-title'>SHOWS EN VIVO</h3>
                  <p className='bandas'>
                    <span className='bandas-red'>COTI - </span>
                    <span className='bandas-white'>BENJAMIN AMADEO - </span>
                    <span className='bandas-red'>SUPER RATONES - </span>
                    <span className='bandas-white'>MISS BOLIVIA - </span>
                    <span className='bandas-red'>
                      BIGOLATES DE CHOCOLATE -{" "}
                    </span>
                    <span className='bandas-white'>
                      PANCETA Y LOS PAPAFRITAS -{" "}
                    </span>
                    <span className='bandas-red'>LOS RAVIOLIS - </span>
                    <span className='bandas-white'>LA CUMPARCITA - </span>
                    <span className='bandas-red'>LAS MOROCHAS - </span>
                    <span className='bandas-white'>... y muchos más</span>
                  </p>
                  <p className='bandas-app'>
                    BAJATE LA APP Y CONOCE TODOS LOS SHOWS
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* END LINE UP */}

          {/* FOOTER  */}
          <div className='footer-recreo'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-4'>
                  <img
                    src={Footer1}
                    className='img-fluid center-block'
                    alt='ReCreo en la Provincia'
                  />
                </div>
                <div className='col-md-2'>
                  <img
                    src={Footer2}
                    className='img-fluid center-block'
                    alt='ReCreo en la Provincia'
                  />
                </div>
                <div className='col-md-3'>
                  <img
                    src={Footer3}
                    className='img-fluid center-block'
                    alt='ReCreo en la Provincia'
                  />
                </div>
                <div className='col-md-3'>
                  <img
                    src={Footer4}
                    className='img-fluid center-block'
                    alt='Gobierno de la provincia de Buenos Aires'
                  />
                </div>
              </div>
            </div>
          </div>
          {/* END FOOTER  */}
        </div>
      </div>
    </div>
  );
};

export default Paradores;
