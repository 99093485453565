import { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import CityHall from "typedef/CityHall";

const useSearch = (search?: string): [CityHall[], boolean] => {
  const [cityHalls, setCityHalls] = useState<CityHall[]>([]);
  const { loading } = useQuery(query, {
    fetchPolicy: "network-only",
    variables: {
      search
    },
    onCompleted: ({ search }) => {
      setCityHalls(search.city_halls);
    },
    onError: error =>
      console.error(`Problema buscando Municipios en la pantalla de Municipios`)
  });

  return [cityHalls, loading];
};

const query = gql`
  query searchCityHall($search: String) {
    search(search: $search) {
      city_halls {
        id
        name
        icon {
          uri
        }
        longitude
        latitude
        review
      }
    }
  }
`;

export default useSearch;
