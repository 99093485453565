import { gql, useMutation } from "@apollo/client";

const useSaveConfigs = configs => {
  const [saveConfigs, { loading }] = useMutation(query, {
    onCompleted: response => {
      alert("La configuración seleccionada fue guardada con éxito");
      window.location.replace("/");
    },
    onError: error => {
      console.error(error, `For input ${JSON.stringify(configs)}`);
      alert(
        "Tuvimos un problema. Parece que tuvimos un problema cargando la configuración, por favor vuelve a intentarlo más tarde."
      );
    },
    variables: {
      notification: true,
      categories_recess: configs.recessCategoriesEnabled
        ? configs.recessCategories
        : [],
      categories_benefit: configs.recessCategoriesEnabled
        ? configs.benefitsCategories
        : [],
      city_hall: configs.cityHallsEnabled ? configs.cityHalls : [],
      geo_location: configs.geoNotificationsEnabled
    }
  });

  return [saveConfigs, loading];
};

const query = gql`
  mutation saveConfigs(
    $notification: Boolean!
    $categories_recess: [Int]
    $categories_benefit: [Int]
    $city_hall: [Int]
    $geo_location: Boolean
  ) {
    user_notification(
      notification: $notification
      categories_recess: $categories_recess
      categories_benefit: $categories_benefit
      city_hall: $city_hall
      geo_location: $geo_location
    ) {
      categories_benefit
      categories_recess
      notification
      city_hall
      geo_location
    }
  }
`;

export default useSaveConfigs;
