const useCategories = () => {
  return [
    "Evento cultural",
    "Evento turístico",
    "Establecimiento cultural",
    "Establecimiento turístico",
    "Espacios recreativos",
    "Comercio",
    "Productos Regionales",
    "Hospedajes y Hotelería",
    "Servicios para el turismo",
    "Ferias y Fiestas"
  ];
};
export default useCategories;
