import * as React from "react";

function SvgTemperatureCircle(props) {
  return (
    <svg
      width={27}
      height={26}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 30 30'
      {...props}>
      <g fill='none' fillRule='evenodd'>
        <g fill='#666'>
          <path d='M20.573 8.615a2.11 2.11 0 00-3.602-1.492l-6.628 6.627a3.399 3.399 0 00-3.047 1.204 3.427 3.427 0 002.547 5.62l.088.002a3.395 3.395 0 002.42-1.004 3.434 3.434 0 00.976-2.838l6.627-6.627c.398-.395.62-.932.619-1.492zm-7.79 7.793a.307.307 0 00-.086.269 2.817 2.817 0 01-.781 2.46A2.81 2.81 0 1110.4 14.38a.307.307 0 00.27-.086l6.735-6.736a1.494 1.494 0 012.113 2.113l-6.736 6.737z' />
          <path d='M17.914 8.066l-7.111 7.112-.523-.088a2.082 2.082 0 00-1.96.721 2.12 2.12 0 00.13 2.817 2.089 2.089 0 003.538-1.83l-.089-.524 7.112-7.111a.775.775 0 10-1.097-1.097zm-6.563 7.885a.308.308 0 00-.086.27l.116.68a1.472 1.472 0 01-2.495 1.292c-.018-.019-.037-.037-.053-.056a1.494 1.494 0 01-.04-1.93 1.47 1.47 0 011.386-.51l.68.115a.308.308 0 00.269-.086l4.004-4.004.225.225-4.006 4.004zm7.224-7.224l-2.784 2.785-.225-.226 2.784-2.784a.16.16 0 01.225.225z' />
        </g>
        <circle
          cx={12.65}
          cy={12.65}
          r={12.65}
          stroke='#666'
          strokeWidth={0.55}
          transform='translate(1 .6)'
        />
      </g>
    </svg>
  );
}

export default SvgTemperatureCircle;
