import * as React from "react";

function SvgHandSanitizer(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={32} height={32} {...props}>
      <path d='M23.674 25.672a.439.439 0 00-.31-.128h-8.78c-.491 0-.972-.2-1.318-.549l-4.007-4.038a.438.438 0 10-.622.618l4.007 4.038a2.75 2.75 0 001.94.808h8.599l3.58 3.58a.436.436 0 00.62 0 .439.439 0 000-.62l-3.708-3.708z' />
      <path d='M30.907 23.501l-4.251-4.251a3.754 3.754 0 00-3.614-2.719c-1.396 0-2.67.767-3.324 2.001a.051.051 0 01-.045.029h-2.087l-3.407-3.407c-.318-.318-.74-.493-1.19-.493s-.872.175-1.19.493a1.673 1.673 0 00-.424.717 1.645 1.645 0 00-2.717.715 1.776 1.776 0 00-2.324.16 1.775 1.775 0 00-.001 2.506l.984.992a.438.438 0 10.622-.618l-.985-.993c-.169-.169-.263-.394-.263-.634s.093-.465.263-.634c.169-.169.394-.263.634-.263s.465.093.634.263l5.095 5.095a.439.439 0 00.31.128h6.663a.438.438 0 100-.876h-4.504a1.139 1.139 0 010-2.276h3.886c.343 0 .657-.19.819-.495a2.88 2.88 0 012.549-1.535c1.324 0 2.473.895 2.796 2.177a.434.434 0 00.115.203l4.334 4.334a.436.436 0 00.62 0 .439.439 0 000-.62zM13.81 21.713l-4.118-4.118a.768.768 0 011.085-1.085l3.194 3.194a2.008 2.008 0 00.155 2.009h-.317zm.709-2.7l-2.099-2.099a.806.806 0 011.14-1.139l2.787 2.787h-.559c-.481 0-.922.169-1.269.451zM12.22 26.532a.438.438 0 00-.438.438v.977c0 .719-.585 1.304-1.304 1.304H3.344a1.306 1.306 0 01-1.304-1.304v-12.88a.438.438 0 10-.876 0v12.88c0 1.202.978 2.18 2.18 2.18h7.134c1.202 0 2.18-.978 2.18-2.18v-.977a.438.438 0 00-.438-.438zM1.601 13.638a.438.438 0 00.438-.438v-.559c0-.772.296-1.503.833-2.057l1.917-1.98H9.03l1.917 1.98c.537.555.833 1.285.833 2.057v.806a.438.438 0 10.876 0v-.806a3.811 3.811 0 00-1.08-2.667L9.529 7.861a.47.47 0 00-.064-.054V6.124c0-.714-.542-1.303-1.236-1.378V3.228h4.266v1.177c0 .497.405.902.902.902h1.235a.903.903 0 00.902-.902V2.984A2.797 2.797 0 0012.74.19H6.657a.438.438 0 100 .876h6.083a1.92 1.92 0 011.918 1.918v1.421a.025.025 0 01-.025.025h-1.235a.025.025 0 01-.025-.025V2.984a.633.633 0 00-.632-.632H3.218a.073.073 0 01-.073-.073v-1.14c0-.04.033-.073.073-.073H4.79a.438.438 0 100-.876H3.218a.95.95 0 00-.949.949v1.14a.95.95 0 00.949.949h2.37v1.518a1.388 1.388 0 00-1.236 1.378v1.683a.47.47 0 00-.064.054L2.241 9.974a3.816 3.816 0 00-1.08 2.667v.559c0 .242.196.438.438.438zM7.355 3.227v1.51h-.889v-1.51h.889zM5.741 5.613h2.34a.51.51 0 01.51.51v1.604H5.23V6.123a.51.51 0 01.51-.51z' />
      <path d='M15.374 6.571c-1.194 1.695-1.8 2.946-1.8 3.718 0 1.062.578 2.04 1.509 2.553a.437.437 0 10.423-.767 2.04 2.04 0 01-1.056-1.786c0-.266.16-1.112 1.64-3.213a.484.484 0 01.796 0c.48.681.88 1.321 1.158 1.85a.438.438 0 00.776-.408 17.858 17.858 0 00-1.218-1.947 1.365 1.365 0 00-2.23 0zM18.023 10.32c-1.288 1.728-1.559 2.608-1.559 3.043a2.14 2.14 0 004.277 0c0-.434-.27-1.314-1.559-3.043-.138-.185-.349-.291-.58-.291s-.442.106-.579.291zm1.841 3.042c0 .696-.566 1.262-1.262 1.262s-1.262-.566-1.262-1.262c0-.382.491-1.293 1.262-2.351.771 1.058 1.262 1.97 1.262 2.351z' />
    </svg>
  );
}

export default SvgHandSanitizer;
