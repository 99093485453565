import { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import useLangContext from "hooks/useLangContext";

const useBenefits = () => {
  const [benefits, setBenefits] = useState([]);
  const { lang } = useLangContext();
  const { loading } = useQuery(query, {
    fetchPolicy: "no-cache",
    variables: {
      lang: lang.current
    },
    onCompleted: ({ benefit_category_list: { data } }) => setBenefits(data),
    onError: error =>
      console.error(
        "Parece que tuvimos un problema cargando las categorías de beneficios",
        error
      )
  });

  return [benefits, loading];
};

const query = gql`
  query getCategories($lang: String) {
    benefit_category_list(lang: $lang) {
      data {
        id
        name
      }
    }
  }
`;

export default useBenefits;
