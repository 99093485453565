import useRecredit from "./useRecredit";
import ReCreditHero from "./ReCreditHero";
import Tabs from "components/Tabs/Tabs";
import Tab from "components/Tabs/Tab";
import classes from "./../Benefit/classes.module.scss";
import DaysList from "components/DaysList/DaysList";
import RecreoFeatures from "components/RecreoFeatures";
import dayjs from "dayjs";
import Map from "components/Map";
import useRecreditsModal from "components/ReCreditViewQR/useRecreditsModal";
import useScrollToTop from "hooks/useScrollToTop";
import WhereToUse from "components/WhereToUse";
import { useParams } from "react-router-dom";

const ReCredit = () => {
  const param = useParams<{ id: string }>();
  const { recredit, loading } = useRecredit(param.id);
  const viewModalQR = useRecreditsModal(recredit);
  useScrollToTop();

  return (
    <>
      {" "}
      {/* es probable que no devuelva nada... o haya un error*/}
      {recredit !== undefined && (
        <>
          <ReCreditHero
            openModal={viewModalQR}
            recredit={recredit}
            loadingRecredit={loading}
          />
          <Tabs colorHasGray={true}>
            <Tab title='Detalle'>
              <div className='container'>
                <div className='container-fluid py-4 col-12 col-md-7 col-lg-9 mx-auto my-5 '>
                  <div className={`${classes.details}`}>
                    {recredit?.apply_days && (
                      <DaysList days={recredit?.apply_days} />
                    )}
                    <div className={`${classes.validity_to}`}>
                      Vigente hasta el{" "}
                      {dayjs(recredit?.validity_to).format("DD/MM/YYYY")}
                    </div>
                  </div>
                  <hr />
                  <div className='description'>{recredit?.description}</div>
                  <button
                    onClick={viewModalQR}
                    className='secondary secondary--magenta d-block my-5'>
                    Presentar mi QR
                  </button>
                  <hr />
                  <div className='d-flex align-items-center mb-4'>
                    <RecreoFeatures
                      features={[
                        {
                          icon: "Recycle",
                          available: recredit?.commerce?.sustainability
                        },
                        {
                          icon: "Accesibility",
                          available: recredit?.commerce?.is_accessibility
                        },
                        {
                          icon: "HandSanitizer",
                          available: recredit?.commerce?.preventive_measures
                        },
                        {
                          icon: "GenderFluid",
                          available: recredit?.commerce?.gender_diversity
                        }
                      ]}
                    />
                  </div>
                </div>
              </div>
            </Tab>
            <Tab title='¿Dónde lo uso?'>
              {recredit?.commerce.branch_offices && (
                <div style={{ marginBottom: 70 }}>
                  <WhereToUse
                    branch_offices={recredit?.commerce.branch_offices}
                  />
                </div>
              )}
            </Tab>
            <Tab title='Mapa'>
              <div className={`container-fluid py-4`} id='mapa'>
                <div className='container'>
                  <div className='row mb-5' id='ReCredit'>
                    <div className='w-100'>
                      {recredit?.commerce &&
                        recredit.commerce?.latitude &&
                        recredit.commerce?.longitude && (
                          <Map
                            isVisible={true}
                            ubications={[
                              {
                                id: recredit?.id,
                                name: recredit.commerce?.name,
                                longitude: recredit.commerce?.longitude,
                                latitude: recredit.commerce?.latitude
                              }
                            ]}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </>
      )}
    </>
  );
};

export default ReCredit;
