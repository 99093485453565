import { gql, useQuery } from "@apollo/client";
import { formatExternalAssetUrl } from "helpers";
import { useCallback, useState } from "react";
import dayjs from "dayjs";
import MediaItem from "typedef/MediaItem";
import useLangContext from "hooks/useLangContext";

export type useCarouselReturnType = [Array<MediaItem>, boolean, () => void];

const useCarousel = (): useCarouselReturnType => {
  const [cards, setCards] = useState([]);
  const { lang } = useLangContext();
  const { loading, refetch } = useQuery(query, {
    nextFetchPolicy: "no-cache",
    variables: {
      lang: lang.current
    },
    onCompleted: data => {
      const slides = data.home_slide.data.filter(slide => {
        return slide.is_for_web || slide.is_for_app_and_web;
      });
      setCards(
        slides.map((media: MediaItem) => ({
          ...media,
          type: media.recess?.is_multiple
            ? "multiple"
            : media.recess?.is_circuit
            ? "circuit"
            : "simple",
          is_favorite: media.recess?.is_favorite,
          score: media.recess?.score,
          id: media.recess?.id,
          uri: media?.uri,
          upperSubtitle: media.recess?.city_hall?.name,
          subtitle: media.recess?.categories?.[0]?.name,
          upperTitle: media.recess?.date_from
            ? dayjs(media.recess?.date_from).format("DD/MM")
            : "",
          __typename: media.video_url ? "Video" : "Image"
        }))
      );
    },

    onError: error => console.error(error)
  });

  const reload = useCallback(() => {
    refetch()
      .then(({ data }) => {
        const slides = data.home_slide.data.filter(slide => {
          return slide.is_for_web || slide.is_for_app_and_web;
        });

        setCards(
          slides.map((media: MediaItem) => ({
            ...media,
            type: media.recess?.is_multiple
              ? "multiple"
              : media.recess?.is_circuit
              ? "circuit"
              : "simple",
            is_favorite: media.recess?.is_favorite,
            id: media.recess?.id,
            uri: media?.uri,
            score: media.recess?.score,
            upperSubtitle: media.recess?.city_hall?.name,
            subtitle: media.recess?.categories?.[0]?.name,
            upperTitle: media.recess?.date_from
              ? dayjs(media.recess?.date_from).format("DD/MM")
              : "",
            __typename: media.video_url ? "Video" : "Image"
          }))
        );
      })
      .catch(error => console.error(error));
  }, []);

  return [cards, loading, reload];
};

const query = gql`
  query getSlide($lang: String) {
    home_slide(type: 3, lang: $lang) {
      data {
        title
        uri
        video_url
        is_for_web
        is_for_app
        is_for_app_and_web
        image_focus
        recess {
          id
          title
          is_favorite
          is_multiple
          date_to
          date_from
          score
          is_simple
          is_multiple
          is_circuit
          agenda {
            id
          }
          circuit {
            id
          }
          city_hall {
            name
          }
          categories {
            name
            icon {
              uri
            }
          }
        }
      }
    }
  }
`;

export default useCarousel;
