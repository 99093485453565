import React, { useState } from "react";
import "./Style.scss";

const AccordionFAQ = ({ data }) => {
  const [accordionSelected, setAccordionSelected] = useState(0);

  return (
    <div className='container-accordion'>
      {
        //recorro las pregutnas frecuentes
        data.map((item, i) => {
          //si es el item seleccionado entonces se agrega la clase active, este sera el que desplegara el contenido.
          if (accordionSelected == i) {
            return (
              <div key={item.question + i} className='item active mb-2 '>
                <button
                  className='accordion'
                  onClick={() => setAccordionSelected(i)}>
                  <p className='font-weight-bold bg-white'>
                    {item.question} <span></span>
                  </p>
                </button>

                <div className='panel small text-muted description overflow-hidden '>
                  <hr />
                  {data[i].answer.split("\n").map((parr, j) => {
                    return <p key={j}>{parr}</p>;
                  })}
                </div>
              </div>
            );
          }
          //En caso de que no sea el item seleccionado entonces se desplegara normalmente (sin la clase active)
          return (
            <div key={item.question + i} className='item mb-2 '>
              <button
                className='accordion'
                onClick={() => setAccordionSelected(i)}>
                <p className='font-weight-bold bg-white'>
                  {item.question} <span></span>
                </p>
              </button>
              <div className='panel small text-muted description overflow-hidden '>
                <hr />
                <p> {item.answer} </p>
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default AccordionFAQ;
