import { useState, useMemo } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Proposal } from "typedef/Proposal";
import useUser from "hooks/useUser";

export interface AsyncConvocatoria extends Partial<Proposal> {
  loading: boolean;
  hasProposal: boolean;
}

const useSimpleRecess = (convo_id: string | null): AsyncConvocatoria => {
  const [user] = useUser();
  const [propuesta, setPropuesta] = useState<Proposal | undefined>();
  const [hasProposal, setHasProposal] = useState(false);
  const { loading } = useQuery(query, {
    onCompleted: ({ user_has_proposal: propuestax }) => {
      if (propuestax) {
        setPropuesta(propuestax);
        setHasProposal(true);
      } else {
        console.error("Parece que no existe la propuesta", propuestax);
      }
    },
    onError: error => {
      console.log(
        "Tuvimos un problema al cargar la Convocatoria",
        JSON.stringify(error)
      );
      console.error(
        "Tuvimos un problema al cargar la Convocatoria",
        JSON.stringify(error)
      );
    },
    variables: {
      id: user?.id,
      convo_id
    }
  });

  return useMemo(
    () => ({
      ...propuesta,
      loading,
      hasProposal
    }),
    [propuesta, loading]
  );
};

const query = gql`
  query getProposal($id: Int!, $convo_id: Int!) {
    user_has_proposal(user_id: $id, annoucement_id: $convo_id) {
      id
      description
      about
      approved_by_admin
      multimedia {
        path
        type
        annoucement_item_id
        visibility
      }
      hasVotedByUser
    }
  }
`;

export default useSimpleRecess;
