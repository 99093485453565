import * as React from "react";

function SvgFilter(props) {
  return (
    <svg width={24} height={22} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M1.221 4.07h1.49a3.02 3.02 0 005.814 0h14.146a.825.825 0 000-1.65H8.531a3.02 3.02 0 00-5.814 0H1.221a.825.825 0 100 1.65zm4.4-2.2a1.375 1.375 0 110 2.75 1.375 1.375 0 010-2.75zM22.671 10.12h-7.909a3.02 3.02 0 00-5.632 0H1.221a.825.825 0 000 1.65h7.755a3.025 3.025 0 005.945 0h7.75a.825.825 0 000-1.65zm-10.725 2.475a1.375 1.375 0 110-2.75 1.375 1.375 0 010 2.75zM22.671 17.82h-1.49a3.02 3.02 0 00-5.814 0H1.221a.825.825 0 000 1.65h14.14a3.02 3.02 0 005.814 0h1.496a.825.825 0 000-1.65zm-4.4 2.2a1.375 1.375 0 110-2.75 1.375 1.375 0 010 2.75z' />
    </svg>
  );
}

export default SvgFilter;
