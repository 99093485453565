import { gql, useQuery } from "@apollo/client";
import useLangContext from "hooks/useLangContext";
import { useState } from "react";

export type FAQ = {
  questions: string;
  answer: string;
};

type QueryResponse = {
  faq_list: {
    data: Array<FAQ>;
  };
};

const useFAQ = (): [FAQ[], boolean] => {
  const [faqs, setFAQs] = useState<FAQ[]>([]);
  const { lang } = useLangContext();
  const { loading } = useQuery(query, {
    fetchPolicy: "no-cache",
    variables: {
      lang: lang.current
    },
    onCompleted: ({ faq_list: { data } }: QueryResponse) => setFAQs(data),
    onError: error =>
      console.error(
        "Tuvimos un problema cargando las preguntas frecuentes",
        error
      )
  });

  return [faqs, loading];
};

const query = gql`
  query getFAQ($lang: String) {
    faq_list(lang: $lang) {
      data {
        question
        answer
      }
    }
  }
`;

export default useFAQ;
