import { gql, useQuery } from "@apollo/client";
import useLangContext from "hooks/useLangContext";
import { useState } from "react";
import Recess from "typedef/Recess";

const useCircuits = (id: any): any => {
  const [circuits, setCircuits] = useState<Recess[]>([]);
  const { lang } = useLangContext();
  let idParseado = id.toString();
  const { loading } = useQuery(query, {
    fetchPolicy: "no-cache",
    variables: {
      cityhall_id: id,
      lang: lang.current
    },
    onCompleted: ({ recess_multiple_circuit_list: { data: circuits } }) =>
      setCircuits(
        circuits?.filter(
          (circuits: Recess) => circuits?.city_hall?.id === idParseado
        )
      ),
    onError: error =>
      console.error(`Tuvimos un problema cargando los circuitos: ${error}`)
  });

  console.log(circuits);

  return [circuits];
};

const query = gql`
  query getCircuits($cityhall_id: Int, $lang: String) {
    recess_multiple_circuit_list(cityhall_id: $cityhall_id, lang: $lang) {
      data {
        id
        title
        is_favorite
        date_from
        date_to
        city_hall {
          id
          name
        }
        circuit {
          id
          title
        }
        reward {
          id
        }
        icon {
          uri
        }
      }
    }
  }
`;

export default useCircuits;
