import { gql, useQuery } from "@apollo/client";
import { useMemo, useState } from "react";
import Recess from "typedef/Recess";
import Reward from "typedef/Reward";

type QueryResponse = {
  city_hall_detail: {
    safety_pins: Array<Recess>;
    reward?: Reward;
  } | null;
};

const useImperdibles = (id: string | null) => {
  const [imperdibles, setImperdibles] = useState<Recess[]>([]);
  const [premio, setPremio] = useState<{ miPremio?: any; active?: boolean }>();
  const { loading } = useQuery(query, {
    onCompleted: (data: QueryResponse) => {
      setImperdibles(
        [...(data.city_hall_detail?.safety_pins || [])].sort(
          (a, b) => (a.safety_pin_order || 0) - (b.safety_pin_order || 0)
        )
      );

      setPremio({
        miPremio: data.city_hall_detail?.reward,
        active: data.city_hall_detail?.safety_pins.every(
          item => item.visited === true || !item.hasQR
        )
      });
    },
    onError: error =>
      console.error(
        `Tuvimos un problema al cargar los imperdibles del municipio: ${JSON.stringify(
          error
        )}`
      ),
    variables: {
      id
    }
  });

  return useMemo(
    () => ({
      imperdibles,
      premio,
      loading
    }),
    [imperdibles, loading]
  );
};

const query = gql`
  query getImperdibles($id: String!) {
    city_hall_detail(id: $id) {
      reward {
        id
        name
        code_qr
        commerce {
          name
          address
          latitude
          longitude
          logo {
            uri
          }
        }
      }
      safety_pins {
        id
        title
        safety_pin_order
        is_multiple
        visited
        hasQR
        categories {
          name
          icon {
            uri
          }
        }
        score
        date_from
        date_to
        city_hall {
          name
        }
        icon {
          id
          uri
        }
        latitude
        longitude
        is_favorite
      }
    }
  }
`;

export default useImperdibles;
