import User from "typedef/User";
import Benefit from "typedef/Benefit";
import { useHistory } from "react-router";
import React, { createRef, useMemo } from "react";
import useBenefitQRModal from "hooks/useBenefitQRModal";
import { formatExternalAssetUrl } from "helpers";
import { LIGHT_BLUE } from "assets/colors";
import Icon from "assets/icons";
import ShareIcon from "assets/images/share.svg";
import useSocialShare from "hooks/useSocialShare";

export type BenefitCardProps = {
  item: Benefit;
  index: number;
  user?: User;
  className?: string;
  hasQR?: boolean; // carga por defecto el logo de qr al menos que se diga que no tiene qr
  used?: boolean;
  onClick?: () => void; //si le paso un onclick diferente hara otra cosa que no sea abrir el modal con el codigo qr
};

const BenefitCard: React.FC<BenefitCardProps> = ({
  item: benefit,
  onClick,
  className,
  hasQR,
  used
}) => {
  const shareBTN = createRef<HTMLButtonElement>();
  const history = useHistory();
  const showQR = useBenefitQRModal(benefit.id, () =>
    history.push(`/benefit/${benefit.id}/map`)
  );
  const useShareSocial = useSocialShare(benefit.id, "beneficio", benefit);

  const useShare = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    useShareSocial();
  };

  return useMemo(
    () => (
      <div className='col-12 col-md-6 mb-3' onClick={hasQR ? showQR : onClick}>
        <div
          style={{ maxHeight: 150 }}
          className={`${
            used ? "bg-disabled" : "bg-white"
          } p-0 d-flex flex-row overflow-hidden clickable rounded ${className}`}>
          <div
            className='d-flex flex-column justify-content-end p-2'
            style={{
              minWidth: 150,
              minHeight: 150,
              maxWidth: 150,
              maxHeight: 150,
              background: `url(${formatExternalAssetUrl(
                benefit?.icon
              )}) center / cover no-repeat`
            }}></div>
          <div className='p-3 d-flex flex-column justify-content-between overflow-hidden w-100'>
            {<div>{benefit.commerce?.city_hall?.name}</div>}
            <div className='d-flex flex-column'>
              <div>
                <b>{benefit.commerce?.name} </b>
              </div>
              <div>
                {benefit.type == "pretrip" ? "Acepta previaje" : benefit.name}
              </div>
            </div>
          </div>
          <div className='d-flex flex-column justify-content-between'>
            <div className='flex-fill' />
            {useShare && (
              <>
                <button
                  ref={shareBTN}
                  className='mb-auto'
                  onClick={useShare}
                  style={{ all: "unset" }}>
                  <img className='m-3' src={ShareIcon} />
                </button>
                <div className='h-100'></div>
              </>
            )}
            {hasQR && (
              <button style={{ all: "unset" }}>
                <Icon
                  size={24}
                  className='m-3'
                  custom='QrSimple'
                  fill='transparent'
                  stroke={LIGHT_BLUE}
                />
              </button>
            )}
          </div>
        </div>
      </div>
    ),
    [benefit]
  );
};

export default BenefitCard;
/*<Card
      onClick={!hasQR ? onClick : showQR}
      key={benefit.id}
      haveQRLogo={hasQR}
      containerClassName={`col-12 col-md-6 mb-3`}
      img={benefit.icon}>
      <h6 className='text-danger'>{benefit.name}</h6>
      <p className='small mb-2 font-weight-bold text-uppercase'>
        {benefit.commerce.name}
      </p>
    </Card>*/
