import { VIOLET } from "assets/colors";
import Icon from "assets/icons";
import CityHallCard from "components/CityHallCard";
import RecessCard from "components/RecessCard";
import useLangContext from "hooks/useLangContext";
import useSearchQuery from "hooks/useSearchQuery";
import { useState } from "react";
import useFavCityHalls from "./useFavCityHalls";
import useFavRecesses from "./useFavRecesses";

const Favs = () => {
  const [favRecesses] = useFavRecesses();
  const [favCityHalls] = useFavCityHalls();
  const searchQuery = useSearchQuery();
  const { lang } = useLangContext();

  return (
    <div className='container'>
      <div className='section-title d-flex align-items-center my-4'>
        <Icon
          custom='Heart'
          fill='transparent'
          className='mr-3'
          size={40}
          stroke={VIOLET}
          strokeWidth={"20"}
        />
        <h2 className='text-secondary font-weight-bold'>{lang.MyFavs.title}</h2>
      </div>
      <form>
        <div className='d-flex flex-row bg-white p-3 rounded scrollable'>
          <button
            name='filtro'
            value={""}
            className={`secondary secondary--purple purpleHover mr-2 ${
              (searchQuery.get("filtro") === null ||
                searchQuery.get("filtro") === "") &&
              "selected"
            } `}>
            <p className='text-nowrap' style={{ fontSize: "16px" }}>
              {lang.Elements.all}
            </p>
          </button>
          <button
            name='filtro'
            value={"recreos"}
            className={`secondary secondary--purple purpleHover mr-2 ${
              searchQuery.get("filtro") === "recreos" && "selected"
            } `}>
            <p className='text-nowrap' style={{ fontSize: "16px" }}>
              {lang.Navbar.header.main.recreos}
            </p>
          </button>
          <button
            name='filtro'
            value={"municipios"}
            className={`secondary secondary--purple purpleHover mr-2 ${
              searchQuery.get("filtro") === "municipios" && "selected"
            } `}>
            <p className='text-nowrap' style={{ fontSize: "16px" }}>
              {lang.CityHalls.tabMinicipio}
            </p>
          </button>
        </div>
      </form>
      <div className='row my-5'>
        {favRecesses.length > 0 || favCityHalls.length > 0 ? (
          <>
            {(searchQuery.get("filtro") === "recreos" ||
              searchQuery.get("filtro") === "" ||
              searchQuery.get("filtro") === null) &&
              favRecesses.map(recess => (
                <RecessCard key={recess.id} recess={recess} />
              ))}
            {(searchQuery.get("filtro") === "municipios" ||
              searchQuery.get("filtro") === "" ||
              searchQuery.get("filtro") === null) &&
              favCityHalls.map(cityHall => (
                <CityHallCard key={cityHall.id} cityHall={cityHall} />
              ))}
          </>
        ) : (
          <div className='p-3 mb-5 w-100 d-flex justify-content-center'>
            <h5 className='text-center'>{lang.MyFavs.noResult}</h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default Favs;
