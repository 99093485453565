import { RegisterContext } from "hooks/useRegisterContext";
import validatePassword from "./validatePassword";
import React, {
  Reducer,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useState
} from "react";
import validate from "./validate";
import validatePasswordIsNew from "./validatePasswordIsNew";
import { useHistory } from "react-router";
import useLangContext from "hooks/useLangContext";

export type Credentials = {
  email?: string;
  emailError?: string;
  password?: string;
  passwordError?: string;
  passwordConfirmation?: string;
  passwordConfirmationError?: string;
};

export type CredentialsInputs = {
  email: HTMLInputElement;
  password: HTMLInputElement;
  passwordConfirmation: HTMLInputElement;
};

interface CredentialsForm extends HTMLFormElement, CredentialsInputs {}

const useForm = () => {
  const history = useHistory();
  const registerContext = useContext(RegisterContext);
  const [loading, setLoading] = useState(false);
  const { lang } = useLangContext();
  const [credentials, dispatch] = useReducer(reducer, {
    email: "",
    password: "",
    passwordConfirmation: ""
  });

  /** Function to get a ChangeEvent that set's the given property */
  const set = useCallback(
    (credential: keyof Credentials) => (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      dispatch({
        type: credential,
        payload: { value: event.currentTarget.value }
      });
    },
    []
  );

  /** Function to calculate error */
  const check = useCallback(
    (type: keyof Credentials) => (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
      dispatch({ type, payload: { value: event.currentTarget.value } });
    },
    []
  );

  /** Submit credentials to registro-perfil (Defined in RegisterProfile) */
  const submit = useCallback(
    async (event: React.FormEvent<CredentialsForm>) => {
      event.preventDefault();
      setLoading(true);
      if (validate(credentials, registerContext.data.provider !== undefined)) {
        try {
          if (await validatePasswordIsNew(credentials.email || "")) {
            registerContext.dispatch({
              type: "email",
              payload: credentials.email
            });
            registerContext.dispatch({
              type: "password",
              payload: credentials.password
            });
            registerContext.dispatch({
              type: "password_confirmation",
              payload: credentials.passwordConfirmation
            });
            history.push("/registro-perfil");
          } else {
            alert("Parece que ya hay una cuenta con ese correo electrónico.");
          }
        } catch (error) {
          console.error(error);
          alert(`
            Parece que tuvimos un problema validando el correo.
            Por favor, vuelva a intentarlo más tarde.
          `);
        } finally {
          setLoading(false);
        }
      } else {
        dispatch({
          type: "emailError",
          payload: {
            value: credentials.email || "",
            error: lang.CreateAccount.registerWithEmailError
          }
        });
        dispatch({
          type: "passwordError",
          payload: {
            value: credentials.password || "",
            error: lang.CreateAccount.passwordNoCorrect
          }
        });
        dispatch({
          type: "passwordConfirmationError",
          payload: {
            value: credentials.passwordConfirmation || "",
            error: lang.CreateAccount.passwordRule
          }
        });
      }
    },
    [credentials]
  );

  return useMemo(
    () => ({
      credentials,
      loading,
      submit,
      check,
      set
    }),
    [credentials, loading]
  );
};

type Action = {
  type: keyof Credentials;
  payload: { value: string; error?: string };
};

const reducer: Reducer<Credentials, Action> = (
  credentials,
  { type, payload }
) => {
  switch (type) {
    case "email":
      return {
        ...credentials,
        emailError: undefined,
        email: payload.value
      };
    case "emailError":
      return {
        ...credentials,
        emailError: !payload.value.includes("@") ? payload.error : undefined
      };
    case "password":
      return {
        ...credentials,
        password: payload.value,
        passwordError: undefined
      };
    case "passwordError":
      return {
        ...credentials,
        passwordError: !validatePassword(payload.value)
          ? payload.error
          : undefined
      };
    case "passwordConfirmation":
      return {
        ...credentials,
        passwordConfirmationError: undefined,
        passwordConfirmation: payload.value
      };
    case "passwordConfirmationError":
      return {
        ...credentials,
        passwordConfirmationError:
          payload.value !== credentials.password ? payload.error : undefined
      };
    default:
      return {
        ...credentials,
        [type]: payload
      };
  }
};

export default useForm;
