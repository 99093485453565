export default {
  title: "Create Account",
  subTitle: "How do you want to create your account?",
  loginFacebook: "Connect with Facebook",
  loginGmail: "Connect with Gmail",
  createwithEmail: "Login with e-mail",
  acepptTermAndCondi:
    "Pressing continue you are accepting our terms and conditions ",
  ifHaveAccount: "Do you already have an account? ",
  registerWithEmail: "Login with e-mail",
  registerWithEmailPlaceholder: "Complete your email",
  registerWithEmailError: "Invalid e-mail address",
  createAPass: "Create a password",
  passwordHelper:
    "Your password must include a minimum of 8 characters, at least one number and one capital letter",
  passworRepeat: "Repeat password",
  passwordRule: "Passwords must be the same",
  passwordNoCorrect: "The password is not correct",

  recoverPassword: "Recover your password",
  copyCode:
    "Copy and paste the code that we sent you by e-mail to be able to create a new password."
};
