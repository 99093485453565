import { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import RecessCategory from "typedef/RecessCategory";
import useLangContext from "hooks/useLangContext";

const useCategories = (): [RecessCategory[], boolean] => {
  const [categories, setCategories] = useState([]);
  const { lang } = useLangContext();
  const { loading } = useQuery(query, {
    nextFetchPolicy: "no-cache",
    variables: {
      lang: lang.current
    },
    onCompleted: ({ recess_category_list: { data: categories } }) =>
      setCategories(categories),
    onError: error => {
      setTimeout(() => {
        alert(
          "Tuvimos un problema. No pudimos cargar las categorías de ReCreos."
        );
      }, 1000);
      console.error(
        `Tuvimos un problema al cargar las categorías de recreos: ${JSON.stringify(
          error
        )}`
      );
    }
  });

  return [categories, loading];
};

const query = gql`
  query recessCategoryList($lang: String) {
    recess_category_list(limit: 99999, lang: $lang) {
      data {
        id
        name
        icon {
          uri
        }
      }
    }
  }
`;

export default useCategories;
