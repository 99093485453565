import React, { CSSProperties } from "react";
import * as Icons from "./svg";
import SvgProps from "./SvgProps";

export interface IconProps extends SvgProps {
  size?: number /**@default [20] */;
  custom: keyof typeof Icons;
  fill?: string;
  stroke?: string;
  strokeWidth?: string;
  color?: string /**@default ["none"] */;
  style?: CSSProperties;
  className?: string;
}

export type IconName = keyof typeof Icons;

const Icon: React.FC<IconProps> = ({
  custom,
  className,
  fill = "black",
  strokeWidth,
  color = "none",
  size = 30,
  style,
  stroke = "none",
  ...props
}) => {
  const Icon = Icons[custom] || Icons["Menu"];

  return (
    <Icon
      className={className}
      style={style}
      color={color}
      strokeWidth={strokeWidth}
      fill={fill}
      height={size}
      width={size}
      stroke={stroke}
      {...props}
    />
  );
};

export default Icon;
