import { LIGHT_GRAY } from "assets/colors";
import Icon from "assets/icons";
import QR from "components/QR";
import classes from "hooks/useBenefitQRModal/classes.module.scss";
import useUser from "hooks/useUser";
import ReCredit from "typedef/ReCredits";

type recreditModalProps = {
  onClose: (response: any) => void;
  recredit?: ReCredit;
};

const ReCreditModal: React.FC<recreditModalProps> = ({ onClose, recredit }) => {
  const [user] = useUser();
  return (
    <div className={`${classes.container} shadow`}>
      <button
        style={{ zIndex: 3 }}
        onClick={onClose}
        className='position-absolute mt-2'>
        <Icon className='d-flex' custom='Cross' fill={LIGHT_GRAY} size={14} />
      </button>
      <div
        className={`${classes.modal} py-5 px-4 rounded align-items-center text-center`}>
        <div className='mb-4'>
          <p className='mb-2'>Presentá este código y se te descontarán</p>
          <div
            style={{ width: "max-content" }}
            className='rounded m-auto bg-primary text-white px-2 py-1'>
            <h6>
              <small>{recredit?.cost_recredits} ReCreditos</small>
            </h6>
          </div>
        </div>
        <div className='text-secondary'>
          <h3>
            <b>{recredit?.name}</b>
          </h3>
          <h3>{recredit?.commerce.name}</h3>
        </div>
        <div className='my-2'>
          {" "}
          <b>{recredit?.commerce.city_hall?.name}</b>
        </div>
        <div className='my-4'>
          {recredit?.code_qr && (
            <QR value={`${recredit.code_qr}:${user?.id}`} />
          )}
        </div>
        <div>
          <h6>Código</h6>
          <h5 className='text-primary'>
            {" "}
            <b>{`${recredit?.code_qr}:${user?.id}`}</b>
          </h5>
        </div>

        <div></div>
      </div>
    </div>
  );
};

export default ReCreditModal;
