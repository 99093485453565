import useMultipleRecess from "./useMultipleRecess";
import TextualHero from "components/TextualHero/index";
import classes from "../SimpleRecess/style/index.module.scss";
import React, { useEffect, useState } from "react";
import RecreoFeatures from "components/RecreoFeatures/index";
import useScrollToTop from "hooks/useScrollToTop";
import useMultipleRecessFirebaseEvent from "./useMultipleRecessFirebaseEvent";
import useAgenda from "./useAgenda";
import dayjs from "dayjs";
import "dayjs/locale/es";
import AgendaSvg from "assets/images/blackAgenda.svg";
import IconLeft from "assets/images/arrow-left-circle.svg";
import IconRight from "assets/images/arrow-right-circle.svg";
import RecessCard from "components/RecessCard";
import Map from "components/Map";
import useLangContext from "hooks/useLangContext";
import { useParams } from "react-router-dom";

const MultipleRecess = () => {
  const { lang } = useLangContext();
  const param = useParams<{ id: string }>();
  const mapa = React.useRef<HTMLDivElement>(null);
  const recess = useMultipleRecess(param.id);
  const [offset, setOffset] = useState(0);
  const [selectedDate, setSelectedDate] = useState({});
  const [choosenDate, setChoosenDate] = useState("");
  const [agenda] = useAgenda(offset, recess.recess || [], choosenDate);
  const [more, setMore] = useState(false);
  const [dayRecesses, setDayRecesses] = useState<any[]>([]);

  function setSelectedDay(date: any, recesses: any) {
    setDayRecesses(recesses);
    setSelectedDate(date);
  }

  useEffect(() => {
    let futureEvent = recess?.recess?.filter(
      hasEvent => dayjs(hasEvent.date_from) >= dayjs(new Date())
    );
    let pastEvent = recess?.recess?.filter(
      hasPastEvent => dayjs(hasPastEvent.date_to) < dayjs(new Date())
    );

    if (futureEvent) {
      if (choosenDate == futureEvent[0]?.date_from || !choosenDate) {
        setChoosenDate(futureEvent[0]?.date_from);
        setSelectedDay(agenda[0].date, agenda?.[0].recesses);
      } else {
        setSelectedDate("");
      }
    } else if (pastEvent) {
      setChoosenDate(pastEvent[0].date_from);
      setSelectedDay(agenda[0].date, agenda?.[0].recesses);
    }
  }, [recess, choosenDate]);

  useMultipleRecessFirebaseEvent(param.id);
  useScrollToTop();

  return (
    <div className='container-fluid p-0'>
      <TextualHero children={recess}></TextualHero>
      <div className='container-fluid py-4'>
        <div className='container'>
          <div className='col-12 col-md-7 col-lg-9 mx-auto my-5 '>
            <div className='row align-items-center mb-4'>
              <div className='row m-0'>
                {recess.description_text &&
                recess.description_text.length > 400 ? (
                  <>
                    {!more ? (
                      <>
                        <p className={classes.description}>
                          {recess.description_text?.slice(0, 320)}...
                        </p>
                        <span
                          className='text-magenta more clickable'
                          onClick={() => setMore(true)}>
                          VER MAS
                        </span>
                      </>
                    ) : (
                      <>
                        <span>
                          {recess.description_text
                            ?.split("\n")
                            .map(descriptionParagraph => (
                              <p
                                key={descriptionParagraph}
                                className={classes.description}>
                                {descriptionParagraph}
                              </p>
                            ))}
                        </span>
                        <span
                          className='text-magenta less clickable'
                          onClick={() => setMore(false)}>
                          Ver menos
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  recess.description_text
                    ?.split("\n")
                    .map(descriptionParagraph => (
                      <p
                        key={descriptionParagraph}
                        className={classes.description}>
                        {descriptionParagraph}
                      </p>
                    ))
                )}
              </div>
              <div className={classes.separador}></div>
              <RecreoFeatures
                features={[
                  {
                    icon: "Recycle",
                    available: recess.is_sustainability
                  },
                  {
                    icon: "Accesibility",
                    available: recess.is_accessibility
                  },
                  {
                    icon: "HandSanitizer",
                    available: recess.is_preventive_measures
                  },
                  {
                    icon: "GenderFluid",
                    available: recess.is_gender_diversity
                  }
                ]}
              />
            </div>
          </div>
        </div>
      </div>

      {/* the agenda block */}
      <div className={`container bg-white p-0 ${classes.agendaContainer}`}>
        <div className={`${classes.agendaRecesses} container`}>
          <div className='dates col-lg-9 col-md-7 col-12 p-0 mx-auto d-flex justify-content-between align-items-center flex-nowrap'>
            <div
              className={classes.controlAgenda}
              onClick={() => setOffset(prev => prev - 5)}>
              <img src={IconLeft} alt='' />
            </div>

            {agenda.map(({ date, recesses }, index) => (
              <div
                key={index}
                className={`${classes.buttonDay} ${
                  selectedDate === date ? classes.active : ""
                }`}
                onClick={() => setSelectedDay(date, recesses)}>
                <p className={`secondary secondary--purple`}>
                  {dayjs(date)
                    .locale("es")
                    .format("dd")
                    .slice(0, 1)
                    .toUpperCase()}
                </p>
                {dayjs(date) > dayjs(new Date()) ? (
                  <p
                    className={`${classes.numericDate} ${
                      recesses?.length ? classes.hasEvent : ""
                    } `}>
                    {dayjs(date).format("DD")}
                  </p>
                ) : (
                  <p
                    className={`${classes.numericDate} ${classes.past} ${
                      recesses?.length ? classes.hasEvent : ""
                    } `}>
                    {dayjs(date).format("DD")}
                  </p>
                )}
              </div>
            ))}

            <div
              className={classes.controlAgenda}
              onClick={() => setOffset(prev => prev + 5)}>
              <img src={IconRight} alt='' />
            </div>
          </div>

          <button className='button primary m-auto d-flex position-relative'>
            <label className='date-picker placeholder'>
              <img src={AgendaSvg} />
              {agenda
                ? dayjs(agenda[0]?.date).locale("es").format("MMMM")
                : "Elegir fecha"}
            </label>

            <input
              className='d-block date-picker'
              type='date'
              onChange={e => {
                setChoosenDate(e.currentTarget.value);
                setOffset(0);
              }}></input>
          </button>
        </div>
        {/* the agenda block */}
      </div>

      <div className='container row mt-5 px-0 mx-auto'>
        <div className='col-lg-9 col-md-7 col-12 px-0 '>
          {dayRecesses?.map(individual => (
            <RecessCard recess={individual} />
          ))}
        </div>
      </div>
      <div className={`${classes.map}`} ref={mapa} id='mapa'>
        {recess.recess && recess.recess?.length !== 0 && (
          <Map ubications={recess.recess} />
        )}
      </div>
    </div>
  );
};

export default MultipleRecess;
