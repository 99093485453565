import Navbar from "./Navbar";
import Footer from "./Footer";
import Agendas from "./screens/Agendas";
import Beneficios from "./screens/Benefits";
import Benefit from "./screens/Benefit";
import Circuits from "./screens/Circuits";
import CityHall from "./screens/CityHall";
import CityHalls from "./screens/CityHalls";
import Configurations from "./screens/Configurations";
import Convocatorias from "./screens/Convocatorias";
import CreateAccount from "./screens/CreateAccount";
import Home from "./screens/Home";
import Login from "./screens/Login";
import MyFavs from "./screens/MyFavs";
import MySchedules from "./screens/MySchedules";
import MyBenefits from "./screens/MyBenefits";
import MyTickets from "./screens/MyTickets";
import Polls from "./screens/Polls";
import Profile from "./screens/Profile";
import Recreos from "./screens/Recreos";
import RegisterProfile from "./screens/RegisterProfile";
import SafeDestiny from "./screens/SafeDestiny";
import ToBePart from "./screens/ToBePart";
import Elements from "./Elements";

export default {
  current: "en",
  Elements,
  Navbar,
  Footer,
  Agendas,
  Beneficios,
  Benefit,
  Circuits,
  CityHall,
  CityHalls,
  Configurations,
  Convocatorias,
  CreateAccount,
  Home,
  Login,
  MyFavs,
  MySchedules,
  MyBenefits,
  MyTickets,
  Polls,
  Profile,
  Recreos,
  RegisterProfile,
  SafeDestiny,
  ToBePart
};
