import { gql, useMutation } from "@apollo/client";
import React, { useMemo } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import useTHXContactModal from "./THXContact";

const useSendMail = () => {
  const THXContact = useTHXContactModal();
  const [saveForm, { loading: loadingAction }] = useMutation(sendForm);

  async function save(checking_account_id: number, message: string) {
    try {
      await saveForm({
        variables: {
          checking_account_id: checking_account_id,
          claim: message
        }
      });
      THXContact();
    } catch (error) {
      console.error(
        `Parece que tuvimos un problema agregando la informacion., error:${error}`
      );
      alert(
        "Parece que tuvimos un problema agregando la informacion. intentelo mas tarde"
      );
    }
  }

  return useMemo(
    () => ({
      save,
      loadingAction
    }),
    [save, loadingAction]
  );
};
const sendForm = gql`
  mutation sendMail($checking_account_id: Int!, $claim: String!) {
    claim(checking_account_id: $checking_account_id, claim: $claim)
  }
`;

export default useSendMail;
