import { VIOLET } from "assets/colors";
import Icon, { IconName } from "assets/icons";
import React from "react";
import Arrow from "assets/images/next-arrow.svg";
import { Link } from "react-router-dom";
import useLangContext from "hooks/useLangContext";

export type SubtitleProps = {
  icon: IconName;
  link: string;
  linkLabel: string;
  more: string;
};

const Subtitle: React.FC<SubtitleProps> = ({
  icon,
  linkLabel,
  link,
  more,
  children
}) => {
  const { lang } = useLangContext();
  return (
    <div className='d-flex flex-row section-title align-items-center justify-content-between mb-3 mt-5'>
      <div className='title-content d-flex right align-items-end flex-wrap'>
        <Icon
          custom={icon}
          size={25}
          fill={VIOLET}
          className='mr-3 align-self-center subtitle-home'
        />
        <Link to={more}>
          <h5 className='text-secondary text-nowrap'>{children}</h5>
        </Link>
        <Link className='text-muted ml-2 small d-none d-sm-block' to={link}>
          {linkLabel}
        </Link>
      </div>
      <Link to={more}>
        <button className='secondary secondary--magenta d-none d-sm-block'>
          {lang.Elements.seeMore} <img src={Arrow} />
        </button>
      </Link>
    </div>
  );
};

export default Subtitle;
