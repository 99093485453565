import { Credentials } from "./useForm";
import validatePassword from "./validatePassword";

function validate(credentials: Credentials, noPassword: boolean) {
  if (!credentials.email) return false;
  if (!credentials.password && !noPassword) return false;
  if (!credentials.passwordConfirmation && !noPassword) return false;
  if (!validatePassword(credentials.password) && !noPassword) return false;
  if (credentials.password !== credentials.passwordConfirmation && !noPassword)
    return false;
  if (!credentials.email.includes("@")) return false;
  return true;
}

export default validate;
