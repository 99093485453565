import React from "react";
import { formatExternalAssetUrl } from "helpers";
import { LIGHT_BLUE, MAGENTA } from "assets/colors";
import Stars from "components/Stars/Stars";
import Icon from "assets/icons";
import { useMemo } from "react";
import ShareIcon from "assets/images/share.svg";
import useLangContext from "hooks/useLangContext";

export interface CardProps extends React.HTMLProps<HTMLDivElement> {
  score?: 1 | 2 | 3 | 4 | 5 | number;
  containerClassName?: string;
  onShare?: () => void;
  onFav?: () => void;
  onSurvays?: () => void;
  haveQRLogo?: boolean;
  faved?: boolean | undefined;
  imgTitle?: string;
  imgIcon?: string;
  categoryName?: string;
  categoryIcon?: string;
  img: {
    uri: string;
  };
}

const Card: React.FC<CardProps> = ({
  className,
  containerClassName,
  score,
  img,
  faved,
  imgTitle,
  imgIcon,
  onFav,
  haveQRLogo,
  onShare,
  children,
  categoryName,
  categoryIcon,
  onSurvays,
  ...divProps
}) => {
  const { lang } = useLangContext();

  return useMemo(
    () => (
      <div className={`${containerClassName}`}>
        <div
          {...divProps}
          style={{ maxHeight: 150 }}
          className={`bg-white p-0 d-flex flex-row overflow-hidden clickable rounded ${className}`}>
          <div
            className='d-flex flex-column justify-content-end p-2'
            style={{
              minWidth: 150,
              minHeight: 150,
              maxWidth: 150,
              maxHeight: 150,
              background:
                "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 82.67%), url(" +
                `${formatExternalAssetUrl(img)}` +
                ") center / cover no-repeat"
            }}>
            <div className='d-flex flex-row align-items-center'>
              {imgIcon && (
                <img
                  style={{
                    filter:
                      "brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7460%) hue-rotate(125deg) brightness(106%) contrast(100%)"
                  }}
                  height={25}
                  src={formatExternalAssetUrl(imgIcon)}
                />
              )}
              {imgTitle && <h6 className='text-white small'>{imgTitle}</h6>}
            </div>
            {categoryIcon && categoryName ? (
              <div className='d-flex flex-row'>
                <img
                  width={20}
                  height={20}
                  style={{
                    filter:
                      "brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7460%) hue-rotate(125deg) brightness(106%) contrast(100%)"
                  }}
                  src={formatExternalAssetUrl(categoryIcon)}
                />
                <h6 className='text-white ml-1 my-0 font-weight-500'>
                  {categoryName}
                </h6>
              </div>
            ) : null}
          </div>
          <div className='flex-fill d-flex flex-column'>
            <div className='flex-fill p-3 overflow-hidden'>{children}</div>
            <div className='d-flex'>
              {score !== undefined ? (
                <Stars size={12} className='d-flex m-3' value={score} />
              ) : null}
              {onSurvays && !score && (
                <button
                  onClick={event => {
                    event.stopPropagation();
                    onSurvays?.();
                  }}
                  className='secondary secondary--magenta pr-3 pl-3 py-1 ml-auto mt-auto mb-2 mr-2 d-flex'>
                  {lang.Polls.qualify}
                </button>
              )}
            </div>
          </div>
          <div className='d-flex flex-column justify-content-between'>
            {onShare !== undefined && (
              <button
                style={{ all: "unset" }}
                onClick={event => {
                  event.stopPropagation();
                  onShare?.();
                }}>
                <img className='m-3' src={ShareIcon} />
              </button>
            )}

            {haveQRLogo == true && (
              <button style={{ all: "unset" }}>
                <Icon
                  size={24}
                  className='m-3'
                  custom='QrSimple'
                  fill='transparent'
                  stroke={LIGHT_BLUE}
                />
              </button>
            )}
            {faved !== undefined ? (
              <button
                onClick={event => {
                  event.stopPropagation();
                  onFav?.();
                }}
                style={{ all: "unset" }}>
                <Icon
                  size={17}
                  className='m-3'
                  custom='Heart'
                  fill={faved ? MAGENTA : "transparent"}
                  stroke={MAGENTA}
                />
              </button>
            ) : null}
          </div>
        </div>
      </div>
    ),
    [score, className, img, faved, onShare]
  );
};

export default Card;
