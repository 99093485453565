import useMySchedule from "./useMySchedule";
import Loader from "assets/images/loader.svg";
import Tabs from "components/Tabs/Tabs";
import Tab from "components/Tabs/Tab";
import miAgendaImage from "assets/images/mi_agenda.svg";
import classes from "./classes.module.scss";
import ScheduleCard from "components/ScheduleCard";
import { useMemo } from "react";
import ErrorMSJ from "components/ErrorMSJ";
import useLangContext from "hooks/useLangContext";

const MySchedule = () => {
  const { schedules, loading } = useMySchedule();
  const { lang } = useLangContext();

  return useMemo(
    () => (
      <div className='container-full'>
        <div className='container'>
          <div className='section-title d-flex align-items-center mx-3 my-4'>
            <img
              src={miAgendaImage}
              className={`${classes.img} mr-2`}
              alt='mi agenda'
            />
            <h2 className='text-secondary font-weight-bold'>
              {lang.MySchedules.title}
            </h2>
          </div>
        </div>
        <div className='container'>
          <Tabs position='justify-content-start'>
            <Tab title={lang.MySchedules.mear}>
              {loading ? (
                <div
                  data-testid='loader'
                  className='d-flex justify-content-center align-items-center my-5'>
                  <img src={Loader} />
                </div>
              ) : schedules.nowShedule.length > 0 ? (
                schedules.nowShedule.map((schedule, i) => (
                  <div key={i} className='my-3'>
                    <ScheduleCard
                      scheduleId={schedule.id}
                      recess={schedule.recess}
                    />
                  </div>
                ))
              ) : (
                <div>
                  <div className='d-flex justify-content-center align-items-center my-3'>
                    <ErrorMSJ title={lang.MySchedules.noResult} />
                  </div>
                </div>
              )}
            </Tab>
            <Tab title={lang.MySchedules.past}>
              {loading ? (
                <div className='d-flex justify-content-center align-items-center my-5'>
                  <img src={Loader} />
                </div>
              ) : schedules.pastShedule.length > 0 ? (
                schedules.pastShedule.map((schedule, i) => (
                  <div key={i} className='my-3'>
                    <ScheduleCard
                      scheduleId={schedule.id}
                      recess={schedule.recess}
                    />
                  </div>
                ))
              ) : (
                <div>
                  <div className='d-flex justify-content-center align-items-center my-3'>
                    <ErrorMSJ title={lang.MySchedules.noResult} />
                  </div>
                </div>
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
    ),
    [schedules]
  );
};

export default MySchedule;
