import * as React from "react";

function SvgLocation(props) {
  return (
    <svg
      width={30}
      viewBox='0 0 30 35'
      height={35}
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fill='none'
        d='M13.726 33L3.396 18.19 1.1 13.631l2.296-5.696L5.69 4.518 12.578 1.1h4.592l4.59 2.279 1.383 1.371.914.907 3.443 5.697-1.148 4.557-1.148 4.557-3.443 3.418-2.296 4.557z'
      />
      <path
        fill='none'
        d='M13.726 33L3.396 18.19 1.1 13.631l2.296-5.696L5.69 4.518 12.578 1.1h4.592l4.59 2.279 1.383 1.371.914.907 3.443 5.697-1.148 4.557-1.148 4.557-3.443 3.418-2.296 4.557z'
      />
      <path d='M14.414.308C6.84.498.744 6.594.554 14.168c.129 2.24.747 4.424 1.81 6.399l.108.192c8.377 13.429 10.88 13.429 11.942 13.429 1.664 0 3.458-1.17 7.7-7.138 2.226-3.08 4.135-6.16 4.212-6.299l.108-.184a15.354 15.354 0 001.84-6.399C28.084 6.594 21.99.498 14.414.308zm10.01 19.25s-7.7 12.32-10.01 12.32c-2.31 0-10.01-12.32-10.01-12.32a13.136 13.136 0 01-1.54-5.39c0-6.379 5.172-11.55 11.55-11.55 6.38 0 11.55 5.171 11.55 11.55a13.136 13.136 0 01-1.563 5.39h.023z' />
      <path d='M14.414 7.238a6.16 6.16 0 100 12.32 6.16 6.16 0 000-12.32zm0 10.01a3.85 3.85 0 110-7.7 3.85 3.85 0 010 7.7z' />
    </svg>
  );
}

export default SvgLocation;
