import { gql, useQuery } from "@apollo/client";
import useLangContext from "hooks/useLangContext";
import { useState } from "react";
import Benefit from "typedef/Benefit";

/**
 * Hook para obtener los beneficios de un municipio
 * @param city_hall_id id del municipio del que se quiere obtener los beneficios
 */
const useBenefits = (city_hall_id: string | null): [Benefit[], boolean] => {
  const [benefits, setBenefits] = useState([]);
  const { lang } = useLangContext();
  const { loading } = useQuery(query, {
    fetchPolicy: "no-cache",
    variables: {
      lang: lang.current
    },
    onCompleted: ({ benefit_list: { data: benefits } }) =>
      setBenefits(
        benefits?.filter(
          (benefit: Benefit) =>
            benefit?.commerce?.city_hall?.id === city_hall_id ||
            benefit.commerce.branch_offices[0].is_virtual
        )
      ),
    onError: error =>
      console.error(
        "Parece que tuvimos un problema cargando los beneficios",
        error
      )
  });

  return [benefits, loading];
};

const query = gql`
  query getBenefits($lang: String) {
    benefit_list(limit: 99999999, lang: $lang) {
      data {
        id
        name
        icon {
          uri
        }
        description
        type
        type_description
        type_value_x
        type_value_y
        commerce {
          name
          longitude
          latitude
          city_hall {
            id
            name
          }
          branch_offices {
            is_virtual
          }
        }
      }
    }
  }
`;

export default useBenefits;
