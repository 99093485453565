import React from "react";
import Plus from "./../../assets/images/plus.svg";
import Minus from "./../../assets/images/minus.svg";
import dayjs from "dayjs";
import Card from "../Card";
import { useHistory } from "react-router-dom";
import useFaved from "hooks/useFaved";
import RecessCard from "components/RecessCard";

const AccordionItem = ({
  showDescription,
  ariaExpanded,
  item,
  index,
  onClick
}) => {
  const history = useHistory();
  return (
    <div className='faq__question' key={item.question}>
      <div
        aria-expanded={ariaExpanded}
        aria-controls={`faq${index + 1}_desc`}
        data-qa='faq__question-button'
        className={`faq__question-button font-weight-bold text-capitalize`}
        onClick={onClick}>
        {showDescription ? (
          <img src={Minus} className='mr-2' />
        ) : (
          <img src={Plus} className='mr-2' />
        )}
        {item.title}
      </div>

      <div
        id={`faq${index + 1}_desc`}
        data-qa='faq__desc'
        className={`faq__desc ${showDescription}`}>
        <div className='row'>
          {item.data.map(it => {
            return (
              <RecessCard key={it.id} recess={it}>
                {it.date_from && it.date_to ? (
                  <h5 className='text-muted small'>
                    {dayjs(it.date_from).format("DD / MM")} -{" "}
                    {dayjs(it.date_to).format("DD / MM")}
                  </h5>
                ) : null}
                <p className='small mb-2'>{it.title}</p>
                <h6 className='small text-muted'>{it.city_hall?.name}</h6>
              </RecessCard>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AccordionItem;
