import * as React from "react";

function SvgExplore(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={33} height={32} {...props}>
      <path d='M32.662 6.348a5.856 5.856 0 01-2.116 3.661v18.106l-8.054-1.464h-1.464l-8.786 2.196h-1.464l-8.054-2.929V3.221l8.054 2.196h1.42l8.83-2.929h.805A5.867 5.867 0 0123.641.577h-.044a2.199 2.199 0 00-1.069-.278h-.066l-1.464.044a2.157 2.157 0 00-.622.11l-8.5 2.768h-.805L3.303 1.105A2.199 2.199 0 00.528 3.221v22.697a2.198 2.198 0 001.464 2.065l8.054 2.929c.235.085.482.13.732.132h1.464c.18 0 .36-.022.534-.066l8.522-2.131h.996l7.856 1.464a2.194 2.194 0 002.592-2.197V6.925a2.168 2.168 0 00-.081-.578z' />
      <path d='M22.939 7.351a4.4 4.4 0 001.816 1.904l-1.896 6.846a1.098 1.098 0 002.116.586l1.911-6.875a4.391 4.391 0 004.268-5.386 4.39 4.39 0 00-4.267-3.4 4.392 4.392 0 00-3.939 6.333l-.007-.007zm2.972-3.91a2.195 2.195 0 013.048 2.716A2.196 2.196 0 0126.957 7.6a1.085 1.085 0 00-1.179-.293 2.198 2.198 0 01.132-3.866z' />
    </svg>
  );
}

export default SvgExplore;
