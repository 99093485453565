import { NavLink } from "react-router-dom";
import classes from "./style/index.module.scss";
import LogOut from "assets/images/logout.svg";
import useAccessToken, { ACCESS_TOKEN_KEY } from "../../hooks/useAccessToken";
import useUserPoints from "../../screens/UserProfile/useUserPoints";
import BenefitsIcon from "../../assets/images/benefits-icon.svg";
import TicketIcon from "../../assets/images/ticket-icon.svg";
import FavoriteIcon from "../../assets/images/favorite.svg";
import BellIcon from "assets/images/bell-icon.svg";
import ConfigurationNot from "../../assets/images/config-notifications.svg";
import useNotificationsCount from "hooks/useNotificationsCount";
import Icon from "assets/icons";
import miAgendaImage from "assets/images/mi_agenda.svg";
import useLangContext from "hooks/useLangContext";

const MenuMobile = (user: any) => {
  const accessToken = useAccessToken();
  const points = useUserPoints();
  const { notificationsCount: notifications } = useNotificationsCount(); //traigo todas las notificaciones
  const { lang, dispatch } = useLangContext();

  function logOut() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    window.location.replace("/");
  }

  return (
    <div className={` ${classes.menuMobile}`}>
      <ul className='links'>
        <NavLink to='/cityhalls'>
          {lang.Navbar.header.main.knowCityHall}
        </NavLink>
        <NavLink to='/recess'>{lang.Navbar.header.main.recreos}</NavLink>
        <NavLink to='/agenda'>{lang.Navbar.header.main.schedule}</NavLink>
        <NavLink to='/benefits'>{lang.Navbar.header.main.benefit}</NavLink>
        {/* <NavLink to='/convocatorias'>
          {lang.Navbar.header.main.convocatorias}
        </NavLink> */}
      </ul>

      <div className={classes.separador}></div>

      {accessToken ? (
        <div className={classes.userMenu}>
          <h6 className='font-weight-bold'>
            {user.user?.first_name} {user.user?.last_name}
          </h6>
          <NavLink to='/edit-profile' className='my-1'>
            {lang.Navbar.header.login.editProfile}
          </NavLink>

          <div className='userProfile mt-4'>
            <NavLink to='/perfil' className='mb-0'>
              <h6 className='text-magenta font-weight-bold'>
                {" "}
                {lang.Navbar.header.login.level} {` `}
                {points < 1000 && `Virtual`}
                {points >= 1000 && points < 2000 && `Curioso`}
                {points >= 2000 && points < 3000 && `Explorador`}
                {points >= 3000 && points < 4000 && `Experto`}
              </h6>
              <span>
                {points} {lang.Navbar.header.login.points}
              </span>
            </NavLink>
          </div>
          <ul className='my-5'>
            <NavLink to='/Favs' className='d-flex align-items-center my-2'>
              <img src={FavoriteIcon} className='mr-2' />
              <span>{lang.Navbar.header.login.myFavs}</span>
            </NavLink>
            <NavLink
              to='/my-tickets'
              className='d-flex align-items-center my-2'>
              <img src={TicketIcon} className='mr-2' />
              <span>{lang.Navbar.header.login.myTickets}</span>
            </NavLink>
            <NavLink
              to='/my-benefits'
              className='d-flex align-items-center my-2'>
              <img src={BenefitsIcon} className='mr-2' />
              <span>{lang.Navbar.header.login.myBenefits}</span>
            </NavLink>
            <NavLink
              to='/my-schedules'
              className='d-flex align-items-center my-2'>
              <img src={miAgendaImage} className={`${classes.miAgenda} mr-2`} />
              <span>{lang.Navbar.header.login.mySchedule}</span>
            </NavLink>
            <NavLink to='/survays' className='d-flex align-items-center my-2'>
              <Icon
                size={15}
                custom='EmptyStar'
                fill={"rgb(235, 1, 137)"}
                className='mr-2'
              />
              <span>{lang.Navbar.header.login.polls}</span>
            </NavLink>

            <NavLink
              to='/notifications'
              className='d-flex align-items-center mt-4 mb-2'>
              <img src={BellIcon} className='mr-2' />
              {notifications >= 1 && (
                <span className={`${classes.hasNotifications}`}>
                  {" "}
                  {notifications}
                </span>
              )}
              <span>{lang.Navbar.header.login.notifications}</span>
            </NavLink>
            <NavLink
              to='/configuration-notificaciones'
              className='d-flex align-items-center my-2'>
              <img src={ConfigurationNot} className='mr-2' />
              <span>{lang.Navbar.header.login.configurate}</span>
            </NavLink>
          </ul>

          <div className='mt-4' onClick={() => logOut()}>
            <img className='mr-2' src={LogOut} alt='Cerrar Sesión' />
            <span>{lang.Navbar.header.login.logOut}</span>
          </div>
        </div>
      ) : (
        <div className={classes.userCta}>
          <NavLink to='/login'>
            <button className='secondary secondary--border mr-2'>
              {lang.Navbar.header.main.login}
            </button>
          </NavLink>
          <NavLink to='/register'>
            <button className='primary primary--blue'>
              {lang.Navbar.header.main.singIn}
            </button>
          </NavLink>
        </div>
      )}

      <div className={classes.separador}></div>

      <ul>
        <a
          href='https://portal-coronavirus.gba.gob.ar/'
          target='_blank'
          rel='noopener noreferrer'>
          {lang.Navbar.header.top.vaccination}
        </a>
        <NavLink to='/destinos-cuidados'>
          {lang.Navbar.header.top.destiny}
        </NavLink>
        <NavLink to='/terms-and-condition'>
          {lang.Navbar.header.top.termsAndCondi}
        </NavLink>
        <NavLink to='/FAQs'>{lang.Navbar.header.top.frequentQuestions}</NavLink>
      </ul>
    </div>
  );
};

export default MenuMobile;
