import Benefits from "../../assets/images/benefits.svg";
import Tabs from "components/Tabs/Tabs";
import Tab from "components/Tabs/Tab";
import useScrollToTop from "../../hooks/useScrollToTop";
import MyBenefitsList from "./MyBenefitList/MyBenefitsList";
import MyRecreditsList from "./MyRecreditList/MyRecreditsLists";
import useLangContext from "hooks/useLangContext";

const MyBenefits = () => {
  const { lang } = useLangContext();
  useScrollToTop();
  return (
    <div className='container-full'>
      <div className='container'>
        <div className='section-title d-flex align-items-center my-4'>
          <img src={Benefits} className='mr-4' />
          <h2 className='text-secondary font-weight-bold'>
            {lang.MyBenefits.title}
          </h2>
        </div>
      </div>

      <div className='container-full'>
        <Tabs isView={false} colorHasGray={true}>
          <Tab title={lang.MyBenefits.tabBenefit}>
            <MyBenefitsList />
          </Tab>
          <Tab title={lang.MyBenefits.tabReCredit}>
            <MyRecreditsList />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default MyBenefits;
