import React from "react";
import Ticket from "typedef/Ticket";
import Card from "components/Card";
import useTicketQrModal from "screens/MyTickets/useTicketQrModal";
import dayjs from "dayjs";

export type TicketCardProps = {
  ticket: Ticket;
  index?: number;
  userId: string;
  onClose?: () => void;
  onGoToRecess?: () => void;
};

const TicketCard: React.FC<TicketCardProps> = ({
  ticket,
  onGoToRecess,
  userId,
  onClose
}) => {
  const ticketQrModal = useTicketQrModal(ticket, userId, onGoToRecess);
  return (
    <Card
      onClick={ticketQrModal}
      key={ticket?.code_qr}
      containerClassName={`col-12 col-md-6 mb-3`}
      categoryName={ticket.recess.categories?.[0]?.name}
      categoryIcon={ticket.recess.categories?.[0]?.icon}
      img={ticket.recess.icon}>
      {ticket.recess.date_from && ticket.recess.date_to ? (
        <h5 className='text-muted small  text-capitalize'>
          {dayjs(ticket.recess?.date_from).locale("es").format("DD MMM")} ·{" "}
          {dayjs(ticket.recess?.date_to).locale("es").format("DD  MMM")}
        </h5>
      ) : null}
      <p className='small mb-2 font-weight-bold text-uppercase'>
        {ticket.recess?.title}
      </p>
      <h6 className='small text-muted'>{ticket.recess?.city_hall?.name}</h6>
    </Card>
  );
};

export default TicketCard;
