const useYoutubeIdVideos = (videosUrl: Array<{ url: string }>) => {
  let urlVideos: Array<{ url: string }> = [];
  try {
    let urlVideo: string = "";
    videosUrl?.map(video => {
      urlVideo = video.url.split("/")[3];
      urlVideo = urlVideo.split("=")[1];
      urlVideos.push({ url: urlVideo });
    });
  } catch (e) {
    console.log(e);
  }
  return urlVideos;
};

export default useYoutubeIdVideos;
