import Prefooter from "./../../assets/images/prefooter.png";
import FooterLogo from "./../../assets/images/footer-logo.png";

import AppStore from "./../../assets/images/app-store-button.png";
import PlayStore from "./../../assets/images/google-play-button.png";
import Facebook from "./../../assets/images/facebook-logo.svg";
import Instagram from "./../../assets/images/instagram-logo.svg";
import "./style/index.scss";
import { Link, useHistory } from "react-router-dom";
import useLangContext from "hooks/useLangContext";

const Footer = () => {
  const history = useHistory();
  const { lang } = useLangContext();

  return (
    <footer
      className='container-full'
      style={{ backgroundImage: "url(" + Prefooter + ")" }}>
      <div className='container d-flex flex-wrap justify-content-between align-items-start'>
        <div className='logo col-lg-2 col-md-3 col-sm-3 col-xs-12 hidden-sm'>
          <img src={FooterLogo} />
        </div>
        <div className='sumarte col-lg-3 col-md-2 col-sm-12 col-xs-12'>
          <h5>{lang.Footer.titleCompleteForm}</h5>
          <p>{lang.Footer.subTite}</p>
          <button
            onClick={() => {
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLSfPnleDoPC-t6kqV_4yQzq3b8ciaKPh96Qqlt_inQBbj5GpJQ/viewform"
              );
            }}
            className='secondary secondary--magenta'>
            {lang.Footer.addered}
          </button>
        </div>
        <div className='descargar col-lg-3 col-md-2 col-sm-12 col-xs-12'>
          <h5>{lang.Footer.appDownload}</h5>
          <p>{lang.Footer.appDownloadDescribe}</p>
          <div className='d-flex justify-content-between align-items-center'>
            <a
              target='_blank'
              href='https://play.google.com/store/apps/details?id=org.gba.recreo'>
              <img src={PlayStore} />
            </a>
            <a
              target='_blank'
              className='ml-3'
              href='https://apps.apple.com/us/app/recreo-en-la-provincia/id1542361713?app=itunes&ign-mpt=uo%3D4'>
              <img src={AppStore} />
            </a>
          </div>
        </div>
        <div className='more-info col-lg-2 col-md-2 col-sm-12 col-xs-12'>
          <h5>{lang.Footer.termsAndConditions}</h5>
          <Link to='/terms-and-condition'>
            {lang.Footer.termsAndConditions}
          </Link>
          <a
            href='https://www.gba.gob.ar/politicasdeprivacidad'
            target='_blank'>
            {lang.Footer.policity}
          </a>
          <a
            href='https://www.bancoprovincia.com.ar/cuentadni/contenidos/cdniIndividuos'
            target='_blank'>
            {lang.Footer.cuentaDNI}
          </a>
          <a href=' https://www.catalogotc.gba.gob.ar/' target='_blank'>
            {lang.Footer.turismCatalo}
          </a>
          <div className='social-media col-xl-8 col-lg-12 col-md-4 col-sm-4 d-flex justify-content-start  align-items-center'>
            <a
              className='mr-3'
              href='https://www.facebook.com/recreopba'
              target='_blank'>
              <img src={Facebook} />
            </a>
            <a href='https://www.instagram.com/recreopba/' target='_blank'>
              <img src={Instagram} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
