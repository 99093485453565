import usePrompt from "hooks/usePrompt";
import { useCallback } from "react";
import ShareSocial from "./ShareSocial";

const useSocialShare = (url: string, type: string, content: any) => {
  const baseUrl = window.location.origin.toString();

  let sharableUrl = "/";
  if (type === "recreo-simple") {
    sharableUrl = baseUrl + "/recess/simple/" + url;
  } else if (type === "recreo-multiple") {
    sharableUrl = baseUrl + "/recess/multiple/" + url;
  } else if (type === "circuito") {
    sharableUrl = baseUrl + "/circuito/" + url;
  } else if (type === "municipio") {
    sharableUrl = baseUrl + "/cityhall/" + url;
  } else if (type === "beneficio") {
    sharableUrl = baseUrl + "/benefit/" + url;
  } else if (type === "recredito") {
    sharableUrl = baseUrl + "/recredit/" + url;
  } else if (type === "convocatoria") {
    sharableUrl = baseUrl + "/convo/" + url;
  }

  const prompt = usePrompt();

  return useCallback(async () => {
    if (url !== undefined && type)
      prompt<any>(props => (
        <ShareSocial url={url} {...props} type={type} content={content} />
      ))
        .then(response => console.log(response))
        .catch(error => console.error(error));
  }, [url, type]);
};

export default useSocialShare;
