import { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import Recess from "typedef/Recess";
import CityHall from "typedef/CityHall";
import useLangContext from "hooks/useLangContext";

type QueryResponse = {
  city_hall_detail: CityHall;
};

export type InteresetByCategory = Array<{
  id: string;
  title: string;
  data: Array<Recess>;
}>;

/**
 *  Hook para obtener los puntos de interes de un municipio
 *  @param {string} cityHallId El id del municipio del que se quiere obtener los intereses
 */
const useInterests = (
  cityHallId: string | null
): [InteresetByCategory, boolean] => {
  const [interests, setInterests] = useState<InteresetByCategory>([]);
  const { lang } = useLangContext();
  const { loading } = useQuery(query, {
    fetchPolicy: "no-cache",
    onCompleted: ({ city_hall_detail }: QueryResponse) => {
      setInterests(
        [
          ...(city_hall_detail.point_of_interest_categories || []).map(
            category => ({
              id: category.id,
              title: category.name,
              data: category.point_of_interests.map(interest => ({
                ...interest
              }))
            })
          )
        ].sort((a, b) => (a.title > b.title ? 1 : -1))
      );
    },
    onError: error =>
      console.error(
        `Tuvimos un problema cargando los intereses del municipios ${error}`
      ),
    variables: {
      id: cityHallId,
      lang: lang.current
    }
  });

  return [interests, loading];
};

const query = gql`
  query getPointsOfInteres($id: String!, $lang: String) {
    city_hall_detail(id: $id, lang: $lang) {
      point_of_interest_categories(lang: $lang) {
        id
        name
        point_of_interests(lang: $lang) {
          id
          title
          circuit {
            id
          }
          city_hall {
            id
            name
          }
          is_favorite
          categories(lang: $lang) {
            id
            name
            icon {
              uri
            }
          }
          longitude
          latitude
          address
          icon {
            uri
          }
        }
      }
    }
  }
`;

export default useInterests;
