import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { store } from "store";
import RootNavigator from "../navigation";
import useCustomClient from "./useCustomClient";
import { RegisterContextProvider } from "hooks/useRegisterContext";
import { PromptProvider } from "hooks/usePrompt";
import { LangContextProvider } from "hooks/useLangContext";

const App = () => {
  const client = useCustomClient();

  return (
    <Provider store={store}>
      <RegisterContextProvider>
        <LangContextProvider>
          <ApolloProvider client={client}>
            <PromptProvider>
              <RootNavigator />
            </PromptProvider>
          </ApolloProvider>
        </LangContextProvider>
      </RegisterContextProvider>
    </Provider>
  );
};

export default App;
