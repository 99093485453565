import * as React from "react";

function SvgFacebook(props) {
  return (
    <svg width={35} height={35} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M17.5 35C7.835 35 0 27.165 0 17.5S7.835 0 17.5 0 35 7.835 35 17.5 27.165 35 17.5 35zm1.642-22.412c0-.854.084-1.311 1.362-1.311h1.705V8.004h-2.73c-3.279 0-4.433 1.588-4.433 4.257v1.965h-2.045v3.272h2.045v9.495h4.091v-9.495h2.73l.362-3.272h-3.092l.005-1.638z' />
    </svg>
  );
}

export default SvgFacebook;
