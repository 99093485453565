import useCityHalls from "./useCityHalls";
import useLocalities from "./useLocalities";
import countryList from "./countries";
import states from "./states";
import classes from "../Login/style/index.module.scss";
import useForm from "./useForm";
import useTags from "./useTags";
import "./style/style.scss";
import seleccionarTodo from "../../helpers/seleccionarTodo";
import useLangContext from "hooks/useLangContext";

const UserProfileRegister = () => {
  const form = useForm();
  const [tags, loadingTags] = useTags();
  const [cityHalls, loadingCityHalls] = useCityHalls();
  const [localities, loadingLocalities] = useLocalities(form.user.city_hall_id);
  const { lang } = useLangContext();

  let orderStates = states.sort((a, b) => a.value.localeCompare(b.value));

  return (
    <div className='container-fluid bg-white profileRegister'>
      <div className='container d-flex justify-content-center align-items-center px-0'>
        <div className={classes.profileRegister}>
          <h5 className='text-secondary text-center bold'>
            {lang.RegisterProfile.title}
          </h5>
          <form onSubmit={form.submit} className='container'>
            <div className='d-flex justify-content-between flex-wrap p-0'>
              <div className='personal-data col-lg-5 col-md-5 col-12 no-gutters flex-lg-nowrap d-flex flex-column justify-content-between'>
                <h5 className={classes.formTitle}>
                  {lang.RegisterProfile.personalDates}
                </h5>

                <label>
                  <input
                    type='text'
                    required
                    name='first_name'
                    defaultValue={form.user.first_name}
                    placeholder={lang.RegisterProfile.name}
                    onChange={form.set("first_name")}
                  />
                  <span>{lang.RegisterProfile.name}</span>
                </label>
                <label>
                  <input
                    type='text'
                    required
                    name='last_name'
                    defaultValue={form.user.last_name}
                    placeholder={lang.RegisterProfile.lastName}
                    onChange={form.set("last_name")}
                  />
                  <span>{lang.RegisterProfile.lastName}</span>
                </label>

                <div className='row flex-nowrap m-0'>
                  <input
                    required
                    type='phone'
                    name='phone_prefix'
                    className='prefix'
                    placeholder='+54'
                    defaultValue={form.user.phone_prefix}
                    maxLength={6}
                    onBlur={form.set("phone_prefix")}
                  />
                  <input
                    required
                    type='phone'
                    minLength={6}
                    className='phone'
                    name='phone'
                    defaultValue={form.user.phone}
                    placeholder={lang.RegisterProfile.codAreaTel}
                    onBlur={form.set("phone")}
                  />
                </div>

                <label>
                  <input
                    required
                    minLength={6}
                    type='text'
                    name='document_number'
                    placeholder={lang.RegisterProfile.document}
                    onChange={form.set("document_number")}
                    defaultValue={form.user.document_number}
                  />
                  <span>{lang.RegisterProfile.document}</span>
                </label>
                <label>
                  {lang.RegisterProfile.dateOfBirth}
                  <input
                    className='w-100'
                    required
                    type='date'
                    name='birth_day'
                    max={new Date().toISOString().split("T")[0]}
                    placeholder={lang.RegisterProfile.dateSelect}
                    onChange={form.set("birth_day")}
                    defaultValue={form.user.birth_day}
                  />
                </label>

                <label className='d-flex flex-column'>
                  <label>{lang.RegisterProfile.country}</label>

                  <select
                    required
                    name='country'
                    placeholder={lang.RegisterProfile.countrySelect}
                    onChange={form.set("country")}
                    defaultValue={form.user.country}>
                    <option value={undefined}>
                      {lang.RegisterProfile.countrySelect}
                    </option>
                    {countryList.map(country => (
                      <option key={country.value} value={country.value}>
                        {country.label}
                      </option>
                    ))}
                  </select>
                </label>

                {form.user.country === "Argentina" && (
                  <label>
                    <label>{lang.RegisterProfile.province}</label>
                    <br />
                    <select
                      required
                      name='state'
                      onChange={form.set("state")}
                      defaultValue={form.user.state}
                      placeholder={lang.RegisterProfile.provinceSelect}>
                      <option value={undefined}>
                        {lang.RegisterProfile.provinceSelect}
                      </option>
                      {orderStates.map(state => (
                        <option key={state.value} value={state.value}>
                          {state.label}
                        </option>
                      ))}
                    </select>
                  </label>
                )}

                {form.user.state === "Buenos Aires" && (
                  <label>
                    <label>{lang.ToBePart.location}</label> <br />
                    <select
                      required
                      name='city_hall_id'
                      onChange={form.set("city_hall_id")}
                      placeholder={lang.ToBePart.locationSelect}
                      defaultValue={form.user.city_hall_id}>
                      <option value={undefined}>
                        {lang.ToBePart.locationSelect}
                      </option>
                      {cityHalls.map(cityHall => (
                        <option key={cityHall.id} value={cityHall.id}>
                          {cityHall.name}
                        </option>
                      ))}
                    </select>
                  </label>
                )}

                {form.user.state === "Buenos Aires" && (
                  <label>
                    <label>Localidad</label> <br />
                    <select
                      required
                      name='locality_id'
                      onChange={form.set("locality_id")}
                      placeholder='Seleccionar Localidad'
                      defaultValue={form.user.locality_id}>
                      <option value={undefined}>Seleccionar localidad</option>
                      {localities.map(locality => (
                        <option key={locality.id} value={locality.id}>
                          {locality.name}
                        </option>
                      ))}
                    </select>
                  </label>
                )}

                <h5 className={classes.formTitle}>
                  {lang.RegisterProfile.familyGropu}
                </h5>
                <label className={classes.numberData}>
                  <p>{lang.RegisterProfile.cantFamily}</p>
                  <input
                    onFocus={seleccionarTodo}
                    required
                    type='number'
                    max={50}
                    min={0}
                    name='total_family_group'
                    defaultValue={form.user.total_family_group}
                    onBlur={form.set("total_family_group")}
                  />
                </label>
                <label className={classes.numberData}>
                  <p>{lang.RegisterProfile.hasChilldren}</p>
                  <input
                    onFocus={seleccionarTodo}
                    required
                    type='number'
                    max={50}
                    min={0}
                    name='total_children'
                    defaultValue={form.user.total_children}
                    onChange={form.set("total_children")}
                  />
                </label>
              </div>
              <div className='preferences col-lg-5 col-md-5 col-12'>
                <h5 className={classes.formTitle}>
                  {lang.RegisterProfile.preferences}
                </h5>
                <div className='tags'>
                  {tags.map(tag => (
                    <div key={tag.title} className='mb-5'>
                      <h6 className='tag-title my-4'>{tag.title}</h6>
                      {tag.data.map(item => (
                        <div
                          key={item.id}
                          className='d-flex align-items-center position-relative mb-2'>
                          <input
                            type='checkbox'
                            className='flex-0 w-auto my-auto mr-4'
                            id={item.id}
                            value={item.id}
                            onChange={form.set("tag_ids")}
                          />
                          <label className='img' />
                          <label className='flex-fill m-0' htmlFor={item.id}>
                            {item.tag}
                          </label>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <button
              disabled={form.loading}
              type='submit'
              className='mt-5 mx-auto secondary secondary--magenta'>
              {form.loading && (
                <span
                  className='spinner-border spinner-border-sm mr-2'
                  role='status'
                  aria-hidden='true'
                />
              )}
              {lang.RegisterProfile.createAccount}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserProfileRegister;
