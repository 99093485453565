import * as React from "react";

function SvgEar(props) {
  return (
    <svg width={19} height={16} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M16.672 8.932V7.76a7.252 7.252 0 00-4-6.4 1.2 1.2 0 00-1.2-1.2h-4a1.2 1.2 0 00-1.2 1.112 7.132 7.132 0 00-4 6.488V8.932a3.6 3.6 0 001.6 6.828h1.6a1.2 1.2 0 001.2-1.2v-4.8a1.2 1.2 0 00-1.2-1.2h-2v-.8a5.928 5.928 0 012.88-5.176 1.2 1.2 0 001.12.776h4a1.2 1.2 0 001.076-.68 6.032 6.032 0 012.924 5.08V8.584c-.132 0-1.38-.024-1.6-.024h-.4a1.2 1.2 0 00-1.2 1.2v4.8a1.2 1.2 0 001.2 1.2h1.6a3.6 3.6 0 001.6-6.828zm-11.2.828v4.8h-.4v-4.8h.4zm-1.6 4.8a2.4 2.4 0 110-4.8v4.8zm7.6-12.4h-4v-.8h4v.8zm2 12.4v-4.8h.4v4.8h-.4zm1.6 0v-4.8a2.4 2.4 0 110 4.8z'
        fill='#56BFCD'
        fillRule='evenodd'
      />
    </svg>
  );
}

export default SvgEar;
