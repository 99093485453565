import * as React from "react";

function SvgEye(props) {
  return (
    <svg width={18} height={13} xmlns='http://www.w3.org/2000/svg' {...props}>
      <g strokeWidth={0.11}>
        <path d='M17.708 5.932c-.144-.236-3.6-5.772-8.62-5.772S.612 5.696.468 5.932a1.2 1.2 0 000 1.26c.144.236 3.6 5.752 8.616 5.768h.024c5.004 0 8.46-5.536 8.604-5.772a1.2 1.2 0 00-.004-1.256zm-8.6 5.828c-4.4 0-7.6-5.2-7.6-5.2s3.2-5.2 7.6-5.2c4.4 0 7.6 5.2 7.6 5.2s-3.212 5.2-7.6 5.2z' />
        <path d='M9.088 3.36a3.2 3.2 0 100 6.4 3.2 3.2 0 000-6.4zm0 5.2a2 2 0 110-4 2 2 0 010 4z' />
      </g>
    </svg>
  );
}

export default SvgEye;
