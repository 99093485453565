import Stars from "components/Stars/Stars";
import dayjs from "dayjs";
import { formatExternalAssetUrl } from "helpers";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Recess from "typedef/Recess";
import useUpdateSchedule from "hooks/useUpdateSchedule";
import classes from "./classes.module.scss";
import useLangContext from "hooks/useLangContext";

type ScheduleCardProps = {
  recess: Recess;
  scheduleId: string;
};

const ScheduleCard: React.FC<ScheduleCardProps> = ({ recess, scheduleId }) => {
  const dentroDe = dayjs(recess.date_to).diff(dayjs(new Date()), "day");
  const history = useHistory();
  const [activeSpinner, setActiveSpinner] = useState(false);
  const { loading: lodingUpdate, update, isActiveShedule } = useUpdateSchedule(
    recess.id
  );

  const updateShedule = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    update();
    setActiveSpinner(true);
  };
  const { lang } = useLangContext();

  useEffect(() => {
    //cuando se termino de eliminar un recreo de la lista. se recara la pantalla
    if (lodingUpdate == false && activeSpinner == true) {
      window.location.reload();
    }
  }, [lodingUpdate]);

  return (
    <div className='w-100'>
      <div
        onClick={() => history.push(`/recess/simple/${recess.id}`)}
        className={`${
          dentroDe > 0 ? "bg-white" : "bg-light"
        } clickable p-0 d-flex flex-row overflow-hidden rounded ${
          classes.cardSchedule
        }`}>
        <div
          className='d-flex flex-column justify-content-end p-2'
          style={{
            minWidth: 150,
            minHeight: 150,
            maxWidth: 150,
            maxHeight: 150,
            background: `url(${formatExternalAssetUrl(
              recess?.icon
            )}) center / cover no-repeat`
          }}
        />
        <div className='p-3 d-flex flex-column overflow-hidden w-100'>
          {recess.date_from && recess.date_to && (
            <h5 className='text-muted small text-capitalize'>
              {dayjs(recess.date_from).locale("es").format("DD  MMM")} ·{" "}
              {dayjs(recess.date_to).locale("es").format("DD  MMM")}
            </h5>
          )}
          <div className={`${dentroDe < 0 && "text-muted"}`}>
            {recess.title}
          </div>
          <div className='text-muted'>
            <small>{recess?.city_hall?.name}</small>
          </div>
          {recess.score !== undefined && (
            <div className='mt-auto'>
              <Stars size={12} value={recess.score} />
            </div>
          )}
        </div>
        <div className={`d-flex flex-column p-3 ${classes.right}`}>
          {dentroDe >= 0 ? (
            <div className='text-right'>
              {lang.MySchedules.missing} {dentroDe} {lang.MySchedules.days}
            </div>
          ) : (
            <div className='text-right text-muted'>
              {lang.MySchedules.from} {-dentroDe} {lang.MySchedules.daysEnd}
            </div>
          )}
          <button className='primary--magenta' onClick={updateShedule}>
            {lodingUpdate && activeSpinner && (
              <span
                className='spinner-border spinner-border-sm mr-2'
                role='status'
                aria-hidden='true'
              />
            )}
            <small>{lang.Elements.btnDelete}</small>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ScheduleCard;
