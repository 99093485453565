import { Link } from "react-router-dom";
import classes from "./style/index.module.scss";
import GmailLogo from "assets/images/gmail.svg";
import EmailLogo from "assets/images/email.svg";
import useGoogleRegister from "./useGoogleRegister";
import useLangContext from "hooks/useLangContext";

const Register = () => {
  const googleRegister = useGoogleRegister();
  const { lang } = useLangContext();

  return (
    <div className='container-fluid bg-white'>
      <div className='container'>
        <div className={`col-lg-5 col-md-5 col-12 mx-auto ${classes.register}`}>
          <h5 className='text-center bold'>{lang.CreateAccount.title}</h5>
          <p className={` text-center ${classes.subtitle}`}>
            {lang.CreateAccount.subTitle}
          </p>

          <div className={classes.buttonGroup}>
            <button onClick={googleRegister} className={classes.red}>
              <img src={GmailLogo} />
              {lang.CreateAccount.loginGmail}
            </button>
            <Link to='/register-credential'>
              <button className={classes.purple} style={{ marginTop: "1rem" }}>
                <img src={EmailLogo} />
                {lang.CreateAccount.createwithEmail}
              </button>
            </Link>
          </div>

          <p className={`col-lg-10 m-auto text-center ${classes.terms}`}>
            {lang.CreateAccount.acepptTermAndCondi}
            <Link
              className='text-primary font-weight-bold'
              to='/terms-and-condition'>
              {lang.Navbar.header.top.termsAndCondi}
            </Link>
          </p>

          <div className={classes.separador}></div>

          <div className={classes.loginCta}>
            <p className={`text-center ${classes.terms}`}>
              {lang.CreateAccount.ifHaveAccount}
              <Link
                className='text center text-danger font-weight-bold'
                to='/login'>
                {lang.Login.title}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
