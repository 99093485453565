import MultimediaPropuesta from "typedef/MultimediaPropuesta";
import ConvoCardIMG from "assets/images/ReCreoDefaultImage.png";
import Icon from "assets/icons";
import { LIGHT_BLUE } from "assets/colors";
import { formatExternalAssetUrl } from "helpers";
import getYoutubeLinkId from "helpers/getYoutubeLinkId";

const PropuestaPreview: React.FC<{
  size: number;
  multimedia: MultimediaPropuesta;
}> = ({ multimedia, size }) => {
  return (
    <div className='d-flex m-0' style={{ width: size, height: size }}>
      {multimedia.type.includes("image") && multimedia?.path ? (
        <div
          className='d-flex flex-column justify-content-end p-2'
          style={{
            width: size,
            height: size,
            background:
              "url(" +
              `${formatExternalAssetUrl({ uri: multimedia.path })}` +
              ") center / cover no-repeat"
          }}
        />
      ) : multimedia.type.includes("video") && multimedia?.path ? (
        <video
          style={{ objectFit: "cover" }}
          width={size}
          height={size}
          controls>
          <source
            src={formatExternalAssetUrl({ uri: multimedia.path })}
            type='video/mp4'
          />
        </video>
      ) : multimedia.type.includes("audio") && multimedia?.path ? (
        <audio
          src={formatExternalAssetUrl({ uri: multimedia.path })}
          preload='metadata'
          controls
        />
      ) : multimedia.type.includes("link") && multimedia?.path ? (
        <iframe
          width={`${size}px`}
          src={`https://www.youtube.com/embed/${getYoutubeLinkId(
            multimedia.path
          )}?enablejsapi=1&playsinline=1&controls=1&enablejsapi=1&showinfo=0`}
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      ) : multimedia.type.includes("application") && multimedia?.path ? (
        <div className='d-flex justify-content-center'>
          <button type='button' style={{ all: "unset" }}>
            <a
              href={formatExternalAssetUrl({ uri: multimedia.path })}
              target='_blank'>
              <Icon
                custom='Document'
                style={{ alignSelf: "center", cursor: "pointer" }}
                size={size}
                stroke={LIGHT_BLUE}
                fill={LIGHT_BLUE}
                colorLine={"#fff"}
              />
            </a>
          </button>
        </div>
      ) : (
        <img width={size} height={size} src={ConvoCardIMG} alt='Pre view' />
      )}
    </div>
  );
};

export default PropuestaPreview;
