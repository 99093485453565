import RegisterData from "typedef/RegisterData";

function validate(registerData: RegisterData) {
  if (!registerData.first_name) return false;
  if (!registerData.last_name) return false;
  if (!registerData.birth_day) return false;
  if (registerData.country === "Seleccionar País") return false;
  if (registerData.country === "Argentina") {
    if (registerData.state === "Seleccionar provincia") return false;
  }
  if (registerData.country === "Argentina") {
    if (registerData.state === "Seleccionar provincia") return false;
  }
  if (!registerData.document_number) return false;
  if (!registerData.phone) return false;
  if (registerData.total_children === undefined) return false;
  if (registerData.total_family_group === undefined) return false;
  return true;
}

export default validate;
