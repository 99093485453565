import * as React from "react";

function SvgLogoRecreoColor(props) {
  return (
    <svg
      width={131}
      height={75}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}>
      <defs>
        <path id='logo-recreo-color_svg__a' d='M0 74.97h130.775V.418H0z' />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <path
          fill='#828281'
          d='M53.765 51.851h3.453v1h-4.681v-6.885h4.634v1h-3.406v1.861h2.94v1h-2.94zM62.754 45.966h1.168v6.886h-1.257l-3.27-5.02v5.02h-1.172v-6.886h1.255l3.276 5.025zM67.598 52.851v-6.885h1.242v5.848h3.247v1.037zM74.409 49.874h2.043l-1.024-3.126-1.02 3.126zm3.014 2.977l-.646-1.982h-2.69l-.646 1.982h-1.22l2.318-6.886h1.796l2.317 6.886h-1.229z'
        />
        <g transform='translate(0 .072)'>
          <path
            d='M85.051 49.388c.275-.235.413-.61.413-1.126 0-.52-.135-.901-.403-1.142-.268-.24-.685-.36-1.25-.36-.287 0-.566.021-.832.065v2.87c.21.03.465.047.763.047.599 0 1.035-.119 1.31-.354zm-3.315-3.378c.667-.13 1.324-.195 1.969-.195.995 0 1.74.198 2.23.594.492.395.738 1.009.738 1.844 0 .804-.245 1.41-.735 1.822-.49.41-1.227.616-2.21.616-.158 0-.408-.01-.749-.028v2.117h-1.243v-6.77zM88.948 49.16c.295.028.57.042.824.042.586 0 1.016-.098 1.289-.296.272-.196.409-.509.409-.937 0-.428-.127-.74-.384-.935-.255-.195-.658-.293-1.208-.293-.31 0-.62.028-.93.084v2.335zm2.89 3.62l-1.894-2.647a2.793 2.793 0 01-.195.004c-.187 0-.453-.009-.8-.028v2.671h-1.224v-6.746a9.665 9.665 0 012.038-.22c.965 0 1.685.176 2.16.527.477.35.714.891.714 1.624.004.502-.123.92-.378 1.256-.257.335-.626.578-1.11.73l2.052 2.83h-1.363zM97.912 51.261c.315-.396.472-1.028.472-1.896 0-.893-.158-1.541-.475-1.943-.316-.4-.769-.602-1.358-.602-.59 0-1.041.197-1.356.591-.315.394-.473 1.026-.473 1.898 0 .897.157 1.545.47 1.945.313.4.766.6 1.359.6.592 0 1.045-.197 1.36-.593m-3.014 1.226c-.465-.255-.823-.645-1.075-1.172-.253-.528-.38-1.187-.38-1.978 0-.79.127-1.449.38-1.975.252-.526.611-.917 1.078-1.173.465-.255 1.015-.383 1.65-.383.64 0 1.19.128 1.655.383.464.256.821.647 1.075 1.173.252.526.378 1.184.378 1.975 0 .788-.127 1.445-.38 1.973-.255.527-.614.919-1.078 1.175-.463.256-1.013.383-1.65.383-.638 0-1.19-.127-1.653-.38M105.144 45.894h1.219l-2.303 6.886h-1.824l-2.303-6.886h1.303l1.945 5.942z'
            fill='#828281'
          />
          <mask id='logo-recreo-color_svg__b' fill='#fff'>
            <use xlinkHref='#logo-recreo-color_svg__a' />
          </mask>
          <path
            fill='#828281'
            mask='url(#logo-recreo-color_svg__b)'
            d='M107.066 52.78h1.241v-6.886h-1.241zM114.198 45.894h1.168v6.886h-1.257l-3.27-5.02v5.02h-1.172v-6.886h1.255l3.276 5.025zM117.348 52.024c-.586-.563-.88-1.454-.88-2.673 0-1.185.3-2.072.901-2.661.6-.59 1.467-.884 2.599-.884.509 0 1.002.06 1.48.177v1.033a5.554 5.554 0 00-1.393-.172c-.514 0-.94.083-1.274.25a1.625 1.625 0 00-.756.798c-.169.365-.254.845-.254 1.44 0 .608.084 1.096.252 1.464.168.367.415.632.742.793.327.162.74.241 1.24.241a4.89 4.89 0 001.442-.227v1.033c-.23.074-.477.132-.742.172-.265.04-.534.06-.807.06-1.114 0-1.964-.282-2.55-.844M122.336 52.78h1.241v-6.886h-1.241zM126.533 49.803h2.042l-1.023-3.127-1.02 3.127zm3.013 2.977l-.645-1.982h-2.69l-.647 1.982h-1.218l2.317-6.887h1.796l2.316 6.887h-1.229z'
          />
          <path
            d='M54.186 35.985c-3.707 17.671-22.944 25.75-33.073 21.479-10.13-4.272-10.251 2.196-10.251 2.196v13.913c0 .887-.902 2.265-3.406.652-2.65-1.708-2.818-.408-5.137-1.994C0 70.643 0 66.739 0 66.739l.273-49.297S.178 16.13.178 12.989c0-3.14 4.124-3.365 4.124-3.365h8.39S23.553 1.448 25.995.471c1.144-.457 5.371 2.142 9.54 5.032 2.21 1.533-.56 3.372 1.15 4.657 1.945 1.461 7.465 5.058 7.465 5.058s8.01 5.208 7.155 7.526c-.854 2.319-3.71 3.356-.903 6.529L53.007 32s1.81.979 1.179 3.985'
            fill='#00B0C7'
            mask='url(#logo-recreo-color_svg__b)'
          />
          <path
            d='M18.805 31.717c.623.071 1.224.108 1.802.108 1.021 0 1.787-.194 2.297-.583.51-.388.766-1.03.766-1.924 0-1.689-1.03-2.534-3.09-2.534-.596 0-1.188.054-1.775.163v4.77zm5.732 11.138l-4.431-6.734-1.3-.04v6.774h-5.788V23.328a30.73 30.73 0 013.388-.583 29.75 29.75 0 013.497-.216c3.071 0 5.384.558 6.938 1.674 1.554 1.115 2.33 2.82 2.33 5.115 0 1.347-.302 2.503-.907 3.47-.605.966-1.468 1.73-2.588 2.289l5.23 7.778h-6.37zM38.142 38.208h9.54v4.647H32.33V22.8h15.217v4.648h-9.404v2.86h7.982v4.634h-7.982z'
            fill='#FFF'
            mask='url(#logo-recreo-color_svg__b)'
          />
          <path
            d='M55.467 32.882c0-2.214.447-4.093 1.342-5.637.894-1.545 2.206-2.718 3.936-3.517 1.73-.799 3.842-1.2 6.335-1.2 1.545 0 2.976.14 4.296.421v4.783a19.703 19.703 0 00-3.781-.379c-1.39 0-2.522.191-3.395.576-.871.384-1.517.979-1.937 1.782-.42.805-.63 1.843-.63 3.117 0 1.3.203 2.35.61 3.15.406.8 1.036 1.386 1.89 1.762.853.375 1.962.563 3.327.563 1.156 0 2.461-.185 3.916-.556v4.783c-.632.181-1.348.325-2.148.434-.8.108-1.587.162-2.364.162-7.598 0-11.397-3.414-11.397-10.244M79.358 31.717c.623.071 1.224.108 1.802.108 1.02 0 1.786-.194 2.297-.583.51-.388.766-1.03.766-1.924 0-1.689-1.03-2.534-3.09-2.534-.597 0-1.189.054-1.775.163v4.77zm5.731 11.138l-4.431-6.734-1.3-.04v6.774h-5.787V23.328a30.76 30.76 0 013.388-.583 29.75 29.75 0 013.497-.216c3.071 0 5.384.558 6.937 1.674 1.553 1.115 2.33 2.82 2.33 5.115 0 1.347-.302 2.503-.906 3.47-.606.966-1.469 1.73-2.589 2.289l5.23 7.778H85.09zM98.694 38.208h9.541v4.647H92.882V22.8h15.216v4.648h-9.404v2.86h7.982v4.634h-7.982zM122.98 37.083c.703-.894 1.056-2.277 1.056-4.147 0-1.969-.355-3.412-1.064-4.33-.71-.917-1.75-1.375-3.125-1.375-1.363 0-2.4.446-3.109 1.335-.71.89-1.063 2.275-1.063 4.154 0 1.987.35 3.434 1.05 4.343.7.908 1.741 1.361 3.122 1.361 1.384 0 2.427-.447 3.132-1.341m-8.55 4.878c-1.527-.776-2.707-1.935-3.538-3.476-.83-1.54-1.246-3.426-1.246-5.658 0-2.23.415-4.116 1.246-5.656.83-1.541 2.01-2.7 3.537-3.477 1.527-.777 3.334-1.165 5.42-1.165 2.087 0 3.896.39 5.428 1.171 1.531.783 2.712 1.944 3.543 3.484.83 1.54 1.247 3.421 1.247 5.643 0 2.223-.416 4.105-1.247 5.645-.831 1.54-2.012 2.701-3.543 3.482-1.532.782-3.34 1.172-5.429 1.172-2.085 0-3.892-.388-5.419-1.165'
            fill='#E21782'
            mask='url(#logo-recreo-color_svg__b)'
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgLogoRecreoColor;
