export default {
  title: "Meus benefícios",
  tabBenefit: "Benefícios",
  tabReCredit: "Créditos",
  noResultCredit: "Não há créditos disponíveis",

  code: "Código",
  currency: "Vigência",
  qrLoadError: "não foi possível carregar o código QR"
};
