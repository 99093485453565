import React, { useReducer } from "react";
import Icon from "assets/icons";
import useCityHalls from "./useCityHalls";
import useCategories from "hooks/useRecessCategories";
import useBenefits from "./useBenefits";
import reducer from "./reducer";
import useConfigSaver from "./useConfigSaver";
import useConfigInitialState from "./useConfigInitialState";
import MainIcon from "assets/images/configuration-icon.svg";
import "./style.scss";
import useLangContext from "hooks/useLangContext";

const NotificationsSettings = () => {
  const [configs, dispatch] = useReducer(reducer, {
    recessCategories: [],
    benefitsCategories: [],
    cityHalls: [undefined]
  });
  const [cityHalls] = useCityHalls();
  const [recessCategories] = useCategories();
  const [benefitsCategories] = useBenefits();
  const [saveConfig] = useConfigSaver(configs);

  const { lang } = useLangContext();

  return (
    <div className='container settings'>
      <div className='container'>
        {configs ? (
          <>
            <div className='section-title d-flex align-items-center my-4'>
              <img src={MainIcon} className='mr-3 logo-sub-title' />
              <h2 className='text-secondary font-weight-bold'>
                {lang.Configurations.title}
              </h2>
            </div>
            <div className='d-flex flex-wrap mainContainer'>
              <div className='col-lg-4 col-sm-12 col-md-12 col-12 bg-white settingsBox'>
                <div className='d-flex justify-content-between align-items-center togglerTitle'>
                  <h6 className='formTitle font-weight-bold'>
                    {lang.Recreos.title}
                  </h6>
                  <input
                    type='checkbox'
                    name='recess'
                    checked={configs?.recessCategoriesEnabled}
                    className='toggler m-0'
                    onChange={e =>
                      dispatch({
                        type: "enableRecessCategories",
                        payload: e.currentTarget.checked
                      })
                    }
                  />
                </div>
                <div
                  className={`list checkboxes ${
                    configs?.recessCategoriesEnabled ? "active" : "inactive"
                  }`}>
                  {recessCategories.map((item, i) => {
                    return (
                      <div
                        key={item.id}
                        className='d-flex align-items-center position-relative mb-3'>
                        <input
                          type='checkbox'
                          className='flex-0 w-auto my-auto mr-4'
                          id={item.id}
                          value={item.id}
                          defaultChecked={configs?.recessCategories?.includes(
                            item.id
                          )}
                          onChange={seted =>
                            dispatch({
                              type: "setRecessCategories",
                              payload: {
                                categoryId: item.id,
                                seted
                              }
                            })
                          }
                        />
                        <label className='img' />

                        <label className='flex-fill mb-0' htmlFor={item.id}>
                          {item.name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className='col-lg-4 col-sm-12 col-md-12 col-12 bg-white settingsBox'>
                <div className='d-flex justify-content-between align-items-center togglerTitle'>
                  <h6 className='formTitle font-weight-bold'>
                    {lang.Beneficios.title}
                  </h6>
                  <input
                    type='checkbox'
                    name='benefit'
                    checked={configs.benefitsCategoriesEnabled}
                    className='toggler m-0'
                    onChange={e =>
                      dispatch({
                        type: "enableBenefitsCategories",
                        payload: e.currentTarget.checked
                      })
                    }
                  />
                </div>
                <div
                  className={`checkboxes list ${
                    configs?.benefitsCategoriesEnabled ? "active" : "inactive"
                  }`}>
                  {benefitsCategories?.map(
                    (
                      benefit: {
                        id: string | number | readonly string[] | undefined;
                        name: React.ReactNode;
                      },
                      i: string | number | null | undefined
                    ) => {
                      return (
                        <div
                          key={i}
                          className={`d-flex align-items-center position-relative mb-2 `}>
                          <input
                            type='checkbox'
                            className='flex-0 w-auto my-auto mr-4'
                            id={`benefit ${benefit.id}`}
                            value={benefit.id}
                            defaultChecked={configs?.benefitsCategories.includes(
                              benefit.id
                            )}
                            onClick={seted =>
                              dispatch({
                                type: "setBenefitsCategories",
                                payload: {
                                  categoryId: benefit.id,
                                  seted
                                }
                              })
                            }
                          />
                          <label className='img' />

                          <label
                            className='flex-fill'
                            htmlFor={`benefit ${benefit.id}`}>
                            {benefit.name}
                          </label>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              <div className='col-lg-4 col-sm-12 col-md-12 col-12 justify-content-between settingsBox p-0'>
                <div className='bg-white m-0 togglerTitle flex-nowrap row w-100 justify-content-between align-items-center py-4 px-3'>
                  <h6 className='formTitle font-weight-bold'>
                    {lang.Configurations.geoSelect}
                  </h6>
                  <input
                    type='checkbox'
                    checked={configs?.geoNotificationsEnabled}
                    className='toggler m-0 w-auto'
                    onChange={e =>
                      dispatch({
                        type: "enableGeoNotifications",
                        payload: e.target.checked
                      })
                    }
                  />
                </div>
                <div className='bg-white m-0 row justify-content-between align-items-center my-4 w-100 py-4 px-3'>
                  <div className='d-flex justify-content-between align-items-center togglerTitle w-100 pt-0'>
                    <h6 className='formTitle font-weight-bold'>
                      {lang.CityHalls.tabMinicipio}
                    </h6>
                    <input
                      type='checkbox'
                      checked={configs?.cityHallsEnabled}
                      className='toggler m-0 w-auto'
                      onChange={ev =>
                        dispatch({
                          type: "enableCityHalls",
                          payload: ev.currentTarget.checked
                        })
                      }
                    />
                  </div>
                  {configs?.cityHalls?.map(
                    (
                      cityHallId:
                        | string
                        | number
                        | readonly string[]
                        | undefined,
                      index: string | number | null | undefined
                    ) => {
                      return (
                        <div
                          className={`d-flex justify-content-between align-items-center w-100 ${
                            configs?.cityHallsEnabled ? "active" : "inactive"
                          }`}
                          key={index}>
                          <select
                            name='city_hall_id'
                            placeholder={lang.Configurations.localitiesSelect}
                            defaultValue={cityHallId}
                            onChangeCapture={e =>
                              dispatch({
                                type: "updateCityHall",
                                payload: {
                                  index,
                                  newId: e.currentTarget.value
                                }
                              })
                            }>
                            <option value={cityHallId}>
                              {cityHallId !== undefined
                                ? cityHalls?.map(
                                    (CityHall: { id: any; name: any }) => {
                                      if (CityHall.id === cityHallId) {
                                        return CityHall.name;
                                      }
                                    }
                                  )
                                : lang.Configurations.localitiesSelect}
                            </option>
                            {cityHalls?.map(
                              (item: {
                                id:
                                  | string
                                  | number
                                  | readonly string[]
                                  | null
                                  | undefined;
                                name: React.ReactNode;
                              }) => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              )
                            )}
                          </select>
                          <div
                            className='removeCityHall mb-3'
                            onClick={() =>
                              dispatch({
                                type: "deleteCityHall",
                                payload: index
                              })
                            }>
                            <Icon custom='Cross' color='black' size={16} />
                          </div>
                        </div>
                      );
                    }
                  )}

                  <div
                    className={
                      configs?.cityHallsEnabled ? "active" : "inactive"
                    }
                    onClick={() =>
                      dispatch({
                        type: "addCityHall",
                        payload: undefined
                      })
                    }>
                    <h6 className='text-primary font-weight-bold'>
                      + Agregar otro
                    </h6>
                  </div>
                </div>
              </div>

              <div className='w-100 text-align-center'>
                <button
                  className='secondary secondary--magenta mx-auto my-4'
                  onClick={saveConfig}>
                  Guardar
                </button>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default NotificationsSettings;
