import ConvoCardIMG from "assets/images/ReCreoDefaultImage.png";
import { formatExternalAssetUrl } from "helpers";
import Convocatoria from "typedef/Convocatoria";
import "./index.scss";
import useDateConvo from "hooks/useDateConvo";
import { useHistory } from "react-router";
import ShareButton from "hooks/useSocialShare/ShareButton";
export type ConvoCardProps = {
  convo: Convocatoria;
};

const ConvoCard: React.FC<ConvoCardProps> = ({ convo }) => {
  const history = useHistory();
  const date = useDateConvo(
    convo?.state,
    convo?.close_date,
    convo?.close_date_vote,
    convo?.open_date_vote
  );
  var maxLength = 350;
  var description = convo?.description.substring(0, maxLength);
  if (convo?.description.length > maxLength) description = description + "...";

  return (
    <div className='card mt-5 mb-4'>
      <div
        className='row g-0 clickable convo-card'
        onClick={() =>
          history.push(`${convo.id ? `convo/${convo.id}` : "/convocatorias"}`)
        }>
        <div className='col-md-3'>
          <img
            className='convo-img rounded-left'
            src={
              convo.image ? formatExternalAssetUrl(convo.image) : ConvoCardIMG
            }
            alt='...'
          />
        </div>

        <div className='col-md-9'>
          <div className='my-3'>
            <h5 className='card-title text-capitalize titleOwn'>
              {convo.title}
            </h5>
            <p className='card-text'>{description}</p>
            <br />
            <div className='d-flex align-items-center justify-content-between'>
              {date}
              <div>
                <ShareButton
                  idEntity={convo.id}
                  entity={"convocatoria"}
                  object={convo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConvoCard;
