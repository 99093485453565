export default {
  viewMoreRecessRecommend: "ReCreos recomendados",
  whatsSearch: "O que você procura?",

  nearRecess: "Proximos ReCreos",
  viewRecessSchedule: "Ver Agenda de ReCreos",
  knowCityHall: "Conheça a Provincia",
  viewRecommendRecess: "Conheça os Municipios e seus Circuitos",
  whatsMind: "Qual ReCreo você procura?",
  recessForYou: "RecCreo para você",
  whatsMindSearch: "O que você procura?"
};
