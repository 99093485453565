export const LIGHT_BLUE = "#12B6CA";
export const LIGHTER_BLUE = "rgba(89,193,223,0.17)";
export const WHITE = "#ffffff";
export const MAGENTA = "#EB0189";
export const DARK_GRAY = "#333333";
export const GRAY = "#626262";
export const LIGHT_GRAY = "#9FA3A7";
export const LIGHTER_GRAY = "#D8D8D8";
export const VIOLET = "#8C4091";
export const VIOLETDARK = "#8b2c75";

export const PRIMARY_COLOR = "#12B6CA";
export const VIOLET_ACCENT_COLOR = "#8C4091";
export const LIGHT_VIOLET = "rgba(140, 64, 145, 0.1)";
export const MAGENTA_ACTIONS = "#ED339A";
export const MAGENTA_BUTTONS = "#EB0189";
export const MAGENTA_DISABLED = "rgba(230, 1, 126, 0.47)";
export const LIGHT_GRAY_COLOR = "#B8B7B7";

export const GRAY_BACKGROUND_COLOR = "#F6F6F6";
export const CR_BLACK_COLOR = "#000";
export const CR_WHITE_COLOR = "#FFF";
