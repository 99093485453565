import { useCallback } from "react";
import { useHistory } from "react-router";
import Recess from "typedef/Recess";

const useOnClick = (amenity: Recess) => {
  const history = useHistory();
  console.log(JSON.stringify(amenity));
  return useCallback(() => {
    if (Boolean(amenity.is_multiple === true))
      history.push(`/recess/multiple/${amenity.id}`);
    else if (Boolean(amenity.circuit?.length))
      history.push(`/recess/circuit/${amenity.id}`);
    else history.push(`/recess/simple/${amenity.id}`);
  }, [history, amenity]);
};

export default useOnClick;
