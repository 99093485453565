export default {
  title: "Minha agenda",
  mear: "Próximos",
  from: "",
  daysEnd: " dias atrás",
  past: "Passados",
  missing: "Faltam",
  days: "dias",
  noResult: "Você ainda não adicionou nenhum ReCreo em sua agenda"
};
