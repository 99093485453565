import { useCallback, useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/analytics";
import FirebaseEvents from "typedef/FirebaseEvents";
import NullableProps from "typedef/NullableProps";

export type FirebaseOptions<T extends keyof FirebaseEvents> = {
  eventName: T;
  eventData?: NullableProps<FirebaseEvents[T]>;
  onMount?: boolean;
};

const useFirebaseEvent = <T extends keyof FirebaseEvents>({
  eventName,
  eventData = {},
  onMount
}: FirebaseOptions<T>) => {
  const [logued, setLogued] = useState(false);

  useEffect(() => {
    for (let key of Object.keys(eventData)) {
      if (eventData[key as keyof FirebaseEvents[T]] === undefined) {
        return () => undefined;
      }
    }
    if (onMount && eventName && eventData && !logued) {
      firebase.analytics().logEvent(eventName as string, eventData);
      setLogued(true);
    }
  }, [onMount, eventData, eventName]);

  return useCallback(
    (newData?: FirebaseEvents[T]) => {
      firebase
        .analytics()
        .logEvent(eventName as string, newData ? newData : eventData);
    },
    [onMount, eventData, eventName]
  );
};

export default useFirebaseEvent;
