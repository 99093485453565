import { gql, useQuery } from "@apollo/client";
import useLangContext from "hooks/useLangContext";
import { useRef } from "react";
import { useState } from "react";
import Recess from "typedef/Recess";

/**
 * Hook to get user's favorites recesses
 *
 */
const useFavRecesses = () => {
  const [favs, setFavs] = useState<Recess[]>([]);
  const [bottomReached, setBottomReached] = useState(false);
  const page = useRef(1);
  const { lang } = useLangContext();
  const { loading, refetch, fetchMore } = useQuery(query, {
    fetchPolicy: "no-cache",
    variables: {
      lang: lang.current
    },
    onError: error =>
      console.error(
        "Tuvimos un problema al cargas los recreos favoritos",
        error
      ),
    onCompleted: ({ favorite_recess: { data: recesses, last_page } }) => {
      if (last_page === 1) setBottomReached(true);
      setFavs(recesses);
    }
  });

  async function _refetch() {
    page.current = 1;
    setBottomReached(false);
    const {
      data: {
        favorite_recess: { data: recesses, last_page }
      }
    } = await refetch({
      variables: {
        page: 1,
        lang: lang.current
      }
    });

    if (last_page === 1) setBottomReached(true);
    setFavs(recesses);
  }

  async function _fetchMore() {
    if (!bottomReached) {
      const {
        data: {
          favorite_recess: { data: recesses, last_page }
        }
      } = await fetchMore({
        variables: {
          page: ++page.current,
          lang: lang.current
        }
      });
      setFavs(prev => [...prev, ...recesses]);
      if (last_page <= page.current) setBottomReached(true);
    }
  }

  return [favs, loading, _refetch, _fetchMore, bottomReached] as const;
};

const query = gql`
  query getFavs($page: Int, $lang: String) {
    favorite_recess(limit: 9999, page: $page, lang: $lang) {
      last_page
      data {
        id
        title
        icon {
          uri
        }
        date_from
        is_favorite
        agenda {
          id
        }
        circuit {
          id
        }
        categories {
          name
          icon {
            uri
          }
        }
        city_hall {
          name
        }
      }
    }
  }
`;

export default useFavRecesses;
