const useGalleryTransform = (images: Array<object>, videos: Array<object>) => {
  try {
    let gallery: Array<object> = [];
    let arr: Array<object> = { ...images, ...videos }; //seteo los valores de la imagen y los videos en un solo lugar (para luego recorrer todo de una)

    //creo un carrouselvalues en donde voy a tener los valores de las imagenes y de los videos (ya que si no es un array no se puede recorrer)
    Object.keys(arr).map(function (key) {
      gallery?.push({ [key]: arr[key] });
    });
    return gallery;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export default useGalleryTransform;
