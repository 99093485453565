import { useEffect, useState, useMemo } from "react";
import useVotar from "../../screens/Convocatorias/Votacion/useVotar";
import useUser from "hooks/useUser";
import { Link } from "react-router-dom";
import ConvoCardIMG from "assets/images/ReCreoDefaultImage.png";
import { LIGHT_BLUE } from "assets/colors";
import Propuesta from "typedef/Propuesta";
import ModalProposal from "./ModalProposal";
import PropuestaPreview from "components/PropuestaPreview/Index";

const PropuestaCard: React.FC<{
  estadoConvocatoria: string;
  evaluation_metodology: string;
  propuesta: Propuesta;
}> = ({ propuesta, estadoConvocatoria, evaluation_metodology }) => {
  const [show, setShow] = useState(false);
  const [votes, setVotes] = useState<number>(propuesta?.votes);
  const [estadoVotacion, setEstadoVotacion] = useState<boolean>(
    propuesta.hasVotedByUser || estadoConvocatoria === "finished"
  );
  const votar = useVotar(propuesta?.id);

  return (
    <div className='d-flex bg-white my-3 p-3 col-md-5 col-sm-12 rounded'>
      {propuesta.multimedia[0] ? (
        <PropuestaPreview size={128} multimedia={propuesta.multimedia[0]} />
      ) : (
        <img width={120} height={120} src={ConvoCardIMG} alt='Pre view' />
      )}
      <div className='d-flex flex-column px-3'>
        <div className='d-flex'>
          <p>
            {propuesta?.user?.first_name} {propuesta?.user?.last_name}
          </p>
          {((estadoConvocatoria === "in_voting" ||
            estadoConvocatoria === "closed_vote" ||
            estadoConvocatoria === "winners_selected" ||
            estadoConvocatoria === "finished") &&
            (evaluation_metodology === "massive_evaluation" ||
              evaluation_metodology === "jury_massive_evaluation")) ||
          (estadoConvocatoria === "in_voting" &&
            evaluation_metodology === "massive_jury_evaluation") ? (
            <h6
              className='ml-3'
              style={{ color: LIGHT_BLUE }}>{`votos: ${votes}`}</h6>
          ) : null}
        </div>
        <h6 className='mt-2'>
          <strong>{propuesta.about}</strong>
        </h6>
        <div className='d-flex flex-row mt-3'>
          <button
            className={
              estadoVotacion
                ? "secondary secondary--gray disabled mr-2"
                : `secondary secondary--magenta mr-2`
            }
            onClick={() => (estadoVotacion ? null : setShow(true))}>
            <strong>Votar</strong>
          </button>
          {/**on click vote , open modal */}
          <ModalProposal
            propuesta={propuesta}
            show={show}
            votar={votar}
            setEstadoVotacion={setEstadoVotacion}
            setVotes={setVotes}
            setShow={setShow}
          />

          <Link to={`/propuesta/${propuesta.id}`}>
            <button className='secondary secondary--ligthMagenta mr-2'>
              <strong>Ver detalles</strong>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PropuestaCard;
