import { gql, useLazyQuery } from "@apollo/client";
import Pinpoint from "assets/images/pinpoint.svg";
import Agenda from "assets/images/blackAgenda.svg";
import CityHall from "typedef/CityHall";
import Recess from "typedef/Recess";
import { useState } from "react";

export type SearchResult = {
  title: "Municipios" | "ReCreos";
  icon: string;
  data: any;
};

const useSearch = () => {
  const [results, setResults] = useState<Array<SearchResult>>([]);
  const [noResults, setNoResults] = useState(false);
  const [search, { error, loading }] = useLazyQuery(query, {
    fetchPolicy: "no-cache",
    onCompleted: ({ search }) => {
      if (
        !search.city_halls.filter((c: CityHall) => c.icon?.uri).length &&
        !search.recesses.filter((r: Recess) => r.icon?.uri).length &&
        !search.recesses_multiple.filter((r: Recess) => r.icon?.uri).length
      ) {
        setNoResults(true);
        setResults([]);
        return;
      } else {
        setNoResults(false);
      }

      setResults([
        {
          title: "Municipios",
          icon: Pinpoint,
          data: search.city_halls
        },
        {
          title: "ReCreos",
          icon: Agenda,
          data: [...search.recesses, ...search.recesses_multiple]
        }
      ]);
    },
    onError: error => console.error(`Error de ${JSON.stringify(error)}`)
  });

  return { results, noResults, loading, search, error };
};

const query = gql`
  query search($key: String) {
    search(search: $key) {
      city_halls {
        id
        name
        longitude
        is_favorite
        latitude
        review
        icon {
          uri
        }
      }
      recesses {
        id
        title
        longitude
        latitude
        date_from
        is_favorite
        is_multiple
        date_to
        city_hall {
          name
        }
        categories {
          id
          name
          icon {
            uri
          }
        }
        icon {
          uri
        }
      }
      recesses_multiple {
        id
        title
        date_from
        date_to
        is_favorite
        is_multiple
        icon {
          uri
        }
      }
    }
  }
`;

export default useSearch;
