import * as React from "react";

function SvgArrowRightCircle(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={32} height={32} {...props}>
      <path d='M15.805.278C7.353.278.501 7.13.501 15.582s6.852 15.304 15.304 15.304 15.304-6.852 15.304-15.304S24.257.278 15.805.278zm0 28.522c-7.3 0-13.217-5.918-13.217-13.217S8.506 2.366 15.805 2.366c7.3 0 13.217 5.918 13.217 13.217A13.215 13.215 0 0115.805 28.8z' />
      <path d='M14.525 9.238a1.043 1.043 0 00-1.475 1.475l4.675 4.675-4.675 4.675a1.043 1.043 0 001.475 1.475l6.15-6.15-6.15-6.15z' />
    </svg>
  );
}

export default SvgArrowRightCircle;
