import Modal from "components/VotacionModal";
import Propuesta from "typedef/Propuesta";

const ModalProposal: React.FC<{
  propuesta: Propuesta;
  show: boolean;
  setShow: (value: boolean) => void;
  votar: () => void;
  setEstadoVotacion: (value: boolean) => void;
  setVotes: (value: number) => void;
}> = ({ propuesta, show, setShow, votar, setEstadoVotacion, setVotes }) => (
  <Modal show={show} onClose={() => setShow(false)}>
    <div className='container'>
      <p className='text-center mt-1'>Estás por votar a esta propuesta</p>
      <h5 className='text-secondary bold text-center mt-2'>
        {propuesta?.description}
      </h5>
      <p className='text-center mt-2'>Presentado por</p>
      <h5 className='text-center mt-2'>
        {" "}
        {propuesta?.user?.first_name} {propuesta?.user?.last_name}
      </h5>
      <div className='d-flex justify-content-center mt-3'>
        <button
          className='secondary secondary--magenta mr-3'
          onClick={() => {
            votar();
            setEstadoVotacion(true);
            setShow(false);
            setVotes(currentValue => currentValue + 1);
          }}>
          Confirmar
        </button>
      </div>
    </div>
  </Modal>
);

export default ModalProposal;
