import React from "react";
import Icon from "assets/icons";
import { VIOLET } from "assets/colors";

/**
 *
 * @param {{
 *  value?: number
 *  className?: string
 *  onChange?: (score: number) => void
 *  style?: import('react-native').ViewStyle
 *  disabled?: boolean
 *  spacing?: number
 *  color?: string
 *  size?: number
 * }} StarsProps
 */
const Stars = ({
  value = 0,
  onChange,
  style,
  disabled,
  spacing,
  color,
  size,
  className
}) => {
  return (
    <div className={`${className} stars d-flex flex-row`}>
      <div
        className='d-flex flex-column justify-content-center'
        onClick={() => !disabled && onChange?.(1)}>
        <Icon
          className='mr-1'
          custom={`${value < 1 ? "EmptyStar" : "Star"}`}
          size={size || 15}
          fill={color || VIOLET}
          style={value < 1 ? { opacity: 0.6 } : { opacity: 1 }}
        />
      </div>
      <div
        className='d-flex flex-column justify-content-center'
        onClick={() => !disabled && onChange?.(2)}>
        <Icon
          className='mr-1'
          custom={`${value < 2 ? "EmptyStar" : "Star"}`}
          size={size || 15}
          fill={color || VIOLET}
          style={value < 2 ? { opacity: 0.6 } : { opacity: 1 }}
        />
      </div>
      <div
        className='d-flex flex-column justify-content-center'
        onClick={() => !disabled && onChange?.(3)}>
        <Icon
          className='mr-1'
          custom={`${value < 3 ? "EmptyStar" : "Star"}`}
          size={size || 15}
          fill={color || VIOLET}
          style={value < 3 ? { opacity: 0.6 } : { opacity: 1 }}
        />
      </div>
      <div
        className='d-flex flex-column justify-content-center'
        onClick={() => !disabled && onChange?.(4)}>
        <Icon
          className='mr-1'
          custom={`${value < 4 ? "EmptyStar" : "Star"}`}
          size={size || 15}
          fill={color || VIOLET}
          style={value < 4 ? { opacity: 0.6 } : { opacity: 1 }}
        />
      </div>
      <div
        className='d-flex flex-column justify-content-center'
        onClick={() => !disabled && onChange?.(5)}>
        <Icon
          className='mr-1'
          custom={`${value < 5 ? "EmptyStar" : "Star"}`}
          size={size || 15}
          fill={color || VIOLET}
          style={value < 5 ? { opacity: 0.6 } : { opacity: 1 }}
        />
      </div>
    </div>
  );
};

export default Stars;
