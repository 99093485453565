import * as React from "react";

function SvgFaceMask(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={44} height={32} {...props}>
      <path d='M12.561 18.775a30.929 30.929 0 009.219 1.409 30.924 30.924 0 009.219-1.409.694.694 0 00-.412-1.324 29.47 29.47 0 01-17.611 0 .693.693 0 10-.412 1.324h-.004zM12.662 14.639c3.017.467 6.065.7 9.118.697a59.474 59.474 0 009.118-.697.694.694 0 10-.213-1.371 58.556 58.556 0 01-17.809 0 .694.694 0 00-.214 1.369zM30.492 22.341a19.848 19.848 0 01-17.425 0 .694.694 0 10-.598 1.248 21.21 21.21 0 0018.62 0 .694.694 0 10-.598-1.248z' />
      <path d='M39.802 8.409h-3.593a2.079 2.079 0 00-1.952-1.386H33.21a8.553 8.553 0 01-6.594-3.244 6.235 6.235 0 00-9.672 0 8.552 8.552 0 01-6.596 3.244H9.301a2.08 2.08 0 00-1.952 1.386H3.756a2.773 2.773 0 00-2.773 2.773v3.466c0 3.643 3.262 6.996 7.221 7.542a14.942 14.942 0 0012.431 9.722c.76.086 1.527.086 2.287 0a14.943 14.943 0 0012.429-9.722c3.959-.546 7.221-3.899 7.221-7.542v-3.466a2.773 2.773 0 00-2.773-2.773zm-30.499 0h1.047a9.935 9.935 0 007.671-3.757 4.845 4.845 0 017.52 0 9.935 9.935 0 007.669 3.757h1.047c.383 0 .693.31.693.693v.693l-2.093-.003a10.976 10.976 0 01-8.394-4.266 3.456 3.456 0 00-5.369.006 10.97 10.97 0 01-8.387 4.259h-.016l-2.079.005v-.693c0-.383.31-.693.693-.693zm-6.932 6.238v-3.466c0-.766.621-1.386 1.386-1.386h3.466v6.932c.002 1.339.176 2.672.516 3.967-2.754-.663-5.369-3.016-5.369-6.046zm20.408 15.886a8.908 8.908 0 01-1.999 0 13.563 13.563 0 01-11.394-9.189v-.007a14.182 14.182 0 01-.776-4.61v-5.545l2.079-.003a12.28 12.28 0 009.479-4.77 2.074 2.074 0 013.22-.006 12.355 12.355 0 009.467 4.776h.016l2.08.003v5.545a14.228 14.228 0 01-.774 4.609v.007a13.564 13.564 0 01-11.396 9.191zm18.409-15.886c0 3.031-2.615 5.383-5.369 6.048.341-1.295.514-2.629.516-3.968V9.795h3.466c.766 0 1.386.621 1.386 1.386v3.466z' />
    </svg>
  );
}

export default SvgFaceMask;
