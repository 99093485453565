import usePrompt from "hooks/usePrompt";
import { useCallback } from "react";
import ReCredit from "typedef/ReCredits";
import ReCreditModal from "./ReCreditModal";

const useRecreditsModal = (recredit: any) => {
  const prompt = usePrompt();
  return useCallback(async () => {
    if (recredit) {
      return prompt<any>(props => (
        <ReCreditModal recredit={recredit} {...props} />
      ))
        .then(response => console.log())
        .catch(error => console.error(error));
    }
  }, [recredit]);
};

export default useRecreditsModal;
