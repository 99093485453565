import { useState } from "react";
import classes from "../Login/style/index.module.scss";
import CrossedEye from "assets/images/crossed-eye.svg";
import Eye from "assets/images/eye.svg";
import { Link } from "react-router-dom";
import useForm from "./useForm";
import useLangContext from "hooks/useLangContext";

const UserEmailRegister = () => {
  const form = useForm();
  const [showPass, setShowPass] = useState(false);
  const [showConfirmationPass, setShowConfirmationPass] = useState(false);
  const { lang } = useLangContext();

  return (
    <div className='container-fluid bg-white'>
      <div className='container d-flex justify-content-center align-items-center'>
        <div className={classes.registerEmail}>
          <h5 className='text-secondary text-center bold'>
            {lang.CreateAccount.registerWithEmail}
          </h5>
          <form onSubmit={form.submit} className={classes.form}>
            <div className='email'>
              <input
                required
                type='email'
                name='email'
                className='email'
                value={form.credentials.email}
                placeholder={lang.CreateAccount.registerWithEmailPlaceholder}
                onChange={form.set("email")}
                onBlur={form.check("emailError")}
              />
              <p className='text-danger'>{form.credentials.emailError}</p>
            </div>
            <div className='password'>
              <label>
                <img
                  onClick={() => setShowPass(prev => !prev)}
                  src={showPass ? CrossedEye : Eye}
                />
                <input
                  required
                  minLength={8}
                  className={`password ${classes.password}`}
                  name='password'
                  value={form.credentials.password}
                  type={showPass ? "text" : "password"}
                  pattern='^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$'
                  placeholder={lang.CreateAccount.createAPass}
                  onChange={form.set("password")}
                  onBlur={form.check("passwordError")}
                />
              </label>
              <p className='info-text'>{lang.CreateAccount.passwordHelper}</p>
              <p className='text-danger'>{form.credentials.passwordError}</p>
            </div>
            <div className='check-password password'>
              <label>
                <img
                  onClick={() => setShowConfirmationPass(prev => !prev)}
                  src={showConfirmationPass ? CrossedEye : Eye}
                />
                <input
                  required
                  className='password'
                  name='passwordConfirmation'
                  type={showConfirmationPass ? "text" : "password"}
                  placeholder={lang.CreateAccount.passworRepeat}
                  onChange={form.set("passwordConfirmation")}
                  onBlur={form.check("passwordConfirmationError")}
                  value={form.credentials.passwordConfirmation}
                />
              </label>
              <p className='info-text'> {lang.CreateAccount.passwordRule}</p>
              <p className='text-danger'>
                {form.credentials.passwordConfirmationError}
              </p>
            </div>

            <button
              className='secondary secondary--magenta disabled'
              type='submit'>
              {form.loading && (
                <span
                  className='spinner-border spinner-border-sm mr-2'
                  role='status'
                  aria-hidden='true'
                />
              )}
              {lang.Elements.btnContinue}
            </button>

            <div className={classes.loginCta}>
              <p className='text-center'>
                {lang.CreateAccount.ifHaveAccount}
                <Link
                  className='text center text-danger font-weight-bold'
                  to='/login'>
                  {lang.Login.title}
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserEmailRegister;
