import * as React from "react";

function SvgGmail(props) {
  return (
    <svg width={39} height={39} xmlns='http://www.w3.org/2000/svg' {...props}>
      <defs>
        <path id='gmail_svg__a' d='M0 0h39.15v39.15H0z' />
      </defs>
      <path d='M19.665 9.758c-3.13 0-6.26-.004-9.391.001-1.977.004-2.953.966-2.955 2.92-.006 4.61-.007 9.218 0 13.827.003 1.906.984 2.882 2.903 2.884 6.232.006 12.464.006 18.696 0 1.95-.002 2.912-.986 2.914-2.963.004-4.58.005-9.16 0-13.74-.003-1.956-.979-2.924-2.95-2.928-3.072-.005-6.144-.001-9.217-.001m-.133-9.03c10.347-.056 18.861 8.43 18.89 18.826.028 10.362-8.47 18.869-18.848 18.868C9.222 38.422.814 30.041.728 19.64.644 9.307 9.118.785 19.532.728' />
      <path
        d='M12.209 16.12c2.32 1.472 4.486 2.81 6.608 4.216.582.385.978.352 1.537-.017 1.931-1.276 3.899-2.498 5.856-3.736.206-.13.433-.23.753-.398v10.63h2.33V12.05c-.817.254-1.57.346-2.168.699-2.539 1.5-5.032 3.077-7.551 4.632-2.585-1.597-5.153-3.166-7.701-4.767-.67-.42-1.325-.451-2.011-.248V26.84h2.347V16.121zm7.456-6.362c3.073 0 6.145-.005 9.218.001 1.97.004 2.946.972 2.949 2.929.005 4.58.004 9.16 0 13.739-.002 1.977-.965 2.961-2.914 2.963-6.232.006-12.464.006-18.696 0-1.92-.002-2.9-.978-2.903-2.884a5157.96 5157.96 0 010-13.826c.002-1.955.978-2.917 2.955-2.92 3.13-.006 6.26-.002 9.391-.002z'
        fill='#FFF'
      />
      <path d='M12.209 16.12V26.84H9.862V12.367c.686-.203 1.341-.173 2.01.248 2.549 1.601 5.117 3.17 7.702 4.767 2.519-1.555 5.012-3.132 7.551-4.632.598-.353 1.35-.445 2.167-.7v14.765h-2.329v-10.63c-.32.168-.547.268-.753.398-1.957 1.238-3.925 2.46-5.856 3.736-.56.37-.955.402-1.537.017-2.122-1.405-4.288-2.744-6.608-4.215' />
    </svg>
  );
}

export default SvgGmail;
