import React from "react";
import useSurvays from "./useSurvays";
import Star from "./../../assets/images/Star.svg";
import useSearchQuery from "hooks/useSearchQuery";
import useScrollToTop from "hooks/useScrollToTop";
import BenefitCardSurvays from "./../../components/BenefitCardSurvay/index";
import RecessCardSurvay from "components/RecessCardSurvay";
import useLangContext from "hooks/useLangContext";

const Survays = () => {
  const survays = useSurvays();
  const searchQuery = useSearchQuery();
  const { lang } = useLangContext();
  useScrollToTop();

  return (
    <div className='container'>
      <div className='section-title d-flex align-items-center my-4'>
        <img
          width={40}
          height={40}
          src={Star}
          className='mr-4 logo-sub-title'
        />
        <h2 className='text-secondary font-weight-bold'>{lang.Polls.title}</h2>
      </div>

      <form>
        <div className='d-flex flex-row bg-white p-3 rounded scrollable'>
          <button
            name='filtro'
            value={""}
            className={`secondary secondary--purple purpleHover mr-2 ${
              (searchQuery.get("filtro") === null ||
                searchQuery.get("filtro") === "") &&
              "selected"
            } `}>
            <p className='text-nowrap' style={{ fontSize: "16px" }}>
              {lang.Elements.all}
            </p>
          </button>
          <button
            name='filtro'
            value={"recreos"}
            className={`secondary secondary--purple purpleHover mr-2 ${
              searchQuery.get("filtro") === "recreos" && "selected"
            } `}>
            <p className='text-nowrap' style={{ fontSize: "16px" }}>
              ReCreos
            </p>
          </button>
          <button
            name='filtro'
            value={"beneficios"}
            className={`secondary secondary--purple purpleHover mr-2 ${
              searchQuery.get("filtro") === "beneficios" && "selected"
            } `}>
            <p className='text-nowrap' style={{ fontSize: "16px" }}>
              {lang.Beneficios.title}
            </p>
          </button>
        </div>
      </form>

      <div className='row my-5'>
        {survays?.data?.length > 0 ? (
          survays.data?.map((survay, i) => {
            return survay?.benefit &&
              (searchQuery.get("filtro") === "beneficios" ||
                searchQuery.get("filtro") === "" ||
                searchQuery.get("filtro") === null) ? (
              <div key={i} className='col-12 col-md-6 mb-3'>
                <BenefitCardSurvays
                  stars={survay.qualification}
                  idEncuesta={survay?.id}
                  benefit={survay?.benefit}
                />
              </div>
            ) : survay?.recess &&
              (searchQuery.get("filtro") === "recreos" ||
                searchQuery.get("filtro") === "" ||
                searchQuery.get("filtro") === null) ? (
              <div key={i} className='col-12 col-md-6 mb-3'>
                <RecessCardSurvay
                  stars={survay.qualification}
                  idEncuesta={survay?.id}
                  recess={survay?.recess}
                />
              </div>
            ) : null;
          })
        ) : (
          <div className='p-3 mb-5 w-100 d-flex justify-content-center'>
            <h5 className='text-center'>{lang.Polls.errorLoad}</h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default Survays;
