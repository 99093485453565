export default {
  title: "Agenda",
  search: "¿Qué actividades estás buscando?",
  today: "Hoy",
  tomorrow: "Mañana",
  thisWeek: "Esta Semana",
  nextWeek: "Próxima Semana",
  thisMonth: "Este Mes",
  nextMonth: "Próximo Mes",
  selectDate: "Seleccionar Fecha",
  loadMore: "Cargar más agendas"
};
