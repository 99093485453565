export default {
  titleHero: "Join ReCreo",
  subTitleHero: "It's easy to join",

  title: "I wanna be join",
  subTitle:
    "Complete the form with the requested data about your cultural event or business and we will contact you",
  name: "Name",
  namePlaceholder: "Enter whith your name",
  lastName: "Last name",
  lastNamePlaceholder: "Enter whith your last name",
  location: "Location",
  locationSelect: "Select location",
  kindOfActivity: "Kind of activity",
  kindOfActivityPlaceholder: "Kind of activity",
  category: "Category",
  categorySelect: "Select category",
  email: "E-mail",
  emailPlaceholder: "example@mail.com",
  tel: "Phone number",
  web: "Site",
  webPlaceholder: "www.example.com",
  instagram: "Instagram",
  instPlaceholder: "@example",
  facebook: "FaceBook",
  facebookPlaceHolder: "@example",
  description: "Description",
  descriptionPlaceholder: "Write a text of up to 300 characters"
};
