import useRecesses from "./useRecesses";
import RecessBox from "./RecessBox";

export interface RecessesProps extends React.HTMLProps<HTMLDivElement> {}

const Recesses: React.FC<RecessesProps> = ({ className, ...props }) => {
  const [recesses] = useRecesses(4);
  return (
    <div
      className={`container-full d-flex block flex-row overflow-auto align-items-center justify-content-between scrollable mt-3 mb-5`}
      {...props}>
      {recesses.map((recess, i) => (
        <RecessBox key={i} recess={recess} />
      ))}
    </div>
  );
};

export default Recesses;
