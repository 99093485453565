export default {
  title: "Complete your profile",
  personalDates: "Personal data",
  name: "Name",
  lastName: "Last name",
  codAreaTel: "Area code and phone number",
  document: "Document number",
  dateOfBirth: "Date of birth",
  province: "Province",
  provinceSelect: "Select Province",
  country: "Country",
  countrySelect: "Select country",
  familyGropu: "Family group",
  cantFamily: "How many are in your group?",
  hasChilldren: "Children?",
  preferences: "Preferences",
  activities: "What activities do you like to do?",
  scapeQuestion: "What do you prefer when you are traveling?",
  createAccount: "Creat an account",

  finishRegister: "¡Registration completed!",
  logWith: "Access your email to activate your account."
};
