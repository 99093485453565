import { useMemo, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useCallback } from "react";
import Ticket from "typedef/Ticket";

const useTickets = () => {
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const { loading, refetch } = useQuery(query, {
    fetchPolicy: "no-cache",
    onCompleted: ({
      me: {
        profile_app: { tickets }
      }
    }) => setTickets(tickets),
    onError: error =>
      console.error(
        `No pudimos cargar los tickets del usuario ${JSON.stringify(error)}`
      )
  });

  const _refetch = useCallback(() => {
    refetch()
      .then(({ data: { me: { profile_app: { tickets } } } }) =>
        setTickets(tickets)
      )
      .catch(error => {
        console.error(
          `Tuvimos un problema cargando las entradas: ${JSON.stringify(error)}`
        );
        alert(
          "Tuvimos un problema ,Parece que tuvimos un problema cargando las entradas."
        );
      });
  }, []);

  return useMemo(
    () => ({
      list: tickets,
      loading,
      refetch: _refetch
    }),
    [tickets, refetch]
  );
};

const query = gql`
  query {
    me {
      profile_app {
        tickets {
          reclamed
          recess {
            id
            title
            address
            date_from
            date_to
            score
            is_free
            categories {
              icon {
                uri
              }
              name
            }
            city_hall {
              name
            }
            icon {
              uri
            }
            images {
              uri
            }
          }
          code_qr
        }
      }
    }
  }
`;

export default useTickets;
