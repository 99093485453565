export default {
  titleCompleteForm: "Want to join ReCreo?",
  subTite:
    "If you have a tourist, cultural or commercial establishment and you want to promote your activities through our program, please fill out our form.",
  addered: "I want to join",
  appDownload: "Download the app",
  appDownloadDescribe:
    "ReCreo is available for iOS and Android mobile devices.",
  moreInfo: "More information",
  termsAndConditions: "Terms and Conditions",
  policity: "Privacie Policies",
  cuentaDNI: "Cuenta DNI",
  turismCatalo: "Touristic Catalog"
};
