import { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import Recess from "typedef/Recess";

const useSearch = (
  search?: string
): [Recess[], boolean, () => void, boolean] => {
  const [recesses, setRecesses] = useState<Recess[]>([]);
  const [noResults, setNoResults] = useState(false);
  const { loading } = useQuery(query, {
    fetchPolicy: "network-only",
    variables: {
      search
    },
    onCompleted: ({ search }) => {
      if (!search.recesses.length) setNoResults(true);
      else setNoResults(false);
      setRecesses([...search.recesses, ...search.recesses_multiple]);
    },
    onError: error =>
      console.error(
        "Tuvimos un problema al buscar recreos: ",
        JSON.stringify(error)
      )
  });

  function clearSearch() {
    setRecesses([]);
    setNoResults(false);
  }

  return [recesses, loading, clearSearch, noResults];
};

const query = gql`
  query searchRecess($search: String) {
    search(search: $search) {
      recesses {
        id
        title
        is_favorite
        is_multiple
        score
        date_from
        date_to
        icon {
          uri
        }
        city_hall {
          name
        }
        categories {
          name
        }
      }
      recesses_multiple {
        id
        is_favorite
        is_multiple
        score
        title
        icon {
          uri
        }
        circuit {
          id
        }
        agenda {
          id
        }
      }
    }
  }
`;

export default useSearch;
