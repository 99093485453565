export default {
  titleHero: "Sumate a ReCreo",
  subTitleHero: "Formar parte es muy sencillo",

  title: "Quiero ser parte",
  subTitle:
    "Completá el formulario con datos solicitados sobre tu evento cultural, emprendimiento o negocio y nos contactaremos con vos para avanzar con el proceso.",
  name: "Nombre",
  namePlaceholder: "Escribí tu nombre",
  lastName: "Apellido",
  lastNamePlaceholder: "Escribí tu apellido",
  location: "Municipio",
  locationSelect: "Seleccionar municipio",
  kindOfActivity: "Tipo de Actividad",
  kindOfActivityPlaceholder: "Tipo de Actividad",
  category: "Categoría",
  categorySelect: "Seleccionar categoría",
  email: "E-mail",
  emailPlaceholder: "ejemplo@mail.com",
  tel: "Teléfono",
  web: "Página web",
  webPlaceholder: "www.ejemplo.com",
  instagram: "Perfil de Instagram",
  instPlaceholder: "@ejemplo",
  facebook: "Perfil de Facebook",
  facebookPlaceHolder: "@ejemplo",
  description: "Descripción",
  descriptionPlaceholder: "Escribe un texto de hasta 300 caracteres"
};
