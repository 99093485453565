import * as React from "react";

function SvgEdit(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={30} height={32} {...props}>
      <path d='M24.739 29.146H3.619V2.394h11.968V.282H3.619a2.113 2.113 0 00-2.112 2.112v26.752c0 1.166.946 2.112 2.112 2.112h21.12a2.113 2.113 0 002.112-2.112V15.77h-2.112v13.376z' />
      <path d='M28.343 2.915l-1.492-1.492a2.111 2.111 0 00-2.985 0S12.13 13.145 11.954 13.856l-.528 2.112a2.11 2.11 0 002.563 2.562l2.112-.528c.704-.183 12.243-12.095 12.243-12.095a2.111 2.111 0 000-2.985v-.007zM16.939 14.319l-1.443-1.443-.049-.042 9.912-9.912 1.492 1.492-9.912 9.905z' />
    </svg>
  );
}

export default SvgEdit;
