import { gql, useMutation } from "@apollo/client";

const useActualizarPropuesta = (
  propuestaID: string | undefined,
  titulo: string | undefined,
  sobreMI: string | undefined,
  archivo: File | undefined | object,
  mostrarImagen: Boolean,
  setStep: Function
) => {
  //si es un link o un file.
  const link = typeof archivo?.path == "string" ? archivo?.path : undefined;
  archivo = link ? undefined : archivo;
  const [mutate] = useMutation(query, {
    variables: {
      proposal_id: propuestaID ? parseInt(propuestaID) : null,
      about: sobreMI,
      description: titulo,
      file: archivo,
      link: link
    }
  });
  const [mutateSinFoto] = useMutation(querySinFoto, {
    variables: {
      proposal_id: propuestaID ? parseInt(propuestaID) : null,
      about: sobreMI,
      description: titulo
    }
  });
  async function participar() {
    try {
      if (mostrarImagen) {
        await mutate();
        setStep();
      } else {
        await mutateSinFoto();
        setStep();
      }
      // alert("Participacion Exitosa.");
      // navigation.push("ConvocatoriaSimpleConfirmarDatosEditar");
    } catch (error) {
      console.log(
        `Parece que tuvimos un problema al subir la Convocatoria, ${JSON.stringify(
          error
        )}`
      );
      alert("No pudimos dar de alta la Modificación de Propuesta.");
    }
  }
  return participar;
};
const query = gql`
  mutation(
    $proposal_id: Int!
    $about: String!
    $description: String!
    $file: Upload
    $link: String
  ) {
    update_proposal(
      proposal_id: $proposal_id
      about: $about
      description: $description
      file: $file
      link: $link
    ) {
      about
      description
    }
  }
`;
const querySinFoto = gql`
  mutation($proposal_id: Int!, $about: String!, $description: String!) {
    update_proposal(
      proposal_id: $proposal_id
      about: $about
      description: $description
    ) {
      about
      description
    }
  }
`;

export default useActualizarPropuesta;
