import Card from "components/Card";
import dayjs from "dayjs";
import { useHistory } from "react-router";

export type RecessCardSurvayProps = {
  recess: any;
  idEncuesta?: string;
  stars?: number;
};

const RecessCardSurvay: React.FC<RecessCardSurvayProps> = ({
  recess,
  idEncuesta,
  stars
}) => {
  const history = useHistory();
  return (
    <Card
      img={recess.icon}
      containerClassName={`w-100`}
      onSurvays={() => history.push(`survay-recess/${idEncuesta}`)}
      score={stars}>
      <p className='small mb-2 font-weight-bold text-uppercase'>
        {recess?.title}
      </p>
      <h6 className='small text-muted'>{recess?.city_hall?.name}</h6>
      {recess?.date_from !== null && recess?.date_to !== null && (
        <h5 className='text-muted small text-capitalize'>
          {dayjs(recess?.date_from).locale("es").format("DD  MMM")} ·{" "}
          {dayjs(recess?.date_to).locale("es").format("DD  MMM")}
        </h5>
      )}
    </Card>
  );
};

export default RecessCardSurvay;
