import { gql, useQuery } from "@apollo/client";
import useLangContext from "hooks/useLangContext";
import { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import { Alert } from "react-native";
import ReCredit from "typedef/ReCredits";

const useMyRecredits = () => {
  const [recredits, setRecredits] = useState<Array<ReCredit>>();
  const { lang } = useLangContext();
  const { loading } = useQuery(query, {
    fetchPolicy: "no-cache",
    onCompleted: ({ my_recredits_list: { data: recredits } }) => {
      setRecredits(recredits);
    },
    variables: {
      page: 1,
      limit: 99999,
      lang: lang.current
    },
    onError: error =>
      console.error(
        "Parece que tuvimos un problema cargando los recreditos",
        error
      )
  });

  return useMemo(
    () => ({
      recredits,
      loading
    }),
    [recredits, loading]
  );
};

const query = gql`
  query myRecreditsList($page: Int, $limit: Int, $lang: String) {
    my_recredits_list(limit: $limit, page: $page) {
      data {
        id
        date_use
        benefit(lang: $lang) {
          id
          name
          icon {
            uri
          }
          category(lang: $lang) {
            id
            name
            icon {
              uri
            }
          }
          current_stock
          cost_recredits
          commerce {
            name
            longitude
            latitude
            city_hall {
              address
              name
            }
          }
        }
      }
    }
  }
`;

export default useMyRecredits;
