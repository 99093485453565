import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useCallback } from "react";

/**
 * Hook para pasar una notificacion como leida
 *
 * @param id id de la notificacion a pasar a leidate
 */
const useNotificationToRead = (id: string) => {
  const [notification] = useMutation(queryPutNotification);

  return useCallback(async () => {
    await notification({
      variables: {
        id: id,
        read: true
      }
    });
  }, [id]);
};

const queryPutNotification = gql`
  mutation setNotification($id: String!, $read: Boolean!) {
    notification(notification_id: $id, read: $read) {
      id
    }
  }
`;

export default useNotificationToRead;
