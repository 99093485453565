export default {
  viewMoreRecessRecommend: "See more recommended ReCreos",
  whatsSearch: "What do yo looking for?",

  nearRecess: "Next ReCreos",
  viewRecessSchedule: "Recreation Schedule",
  knowCityHall: "Know the Province",
  viewRecommendRecess: "Know the villages and their circuits",
  whatsMind: "Which ReCreo are you looking for?",
  recessForYou: "ReCreo for you",
  whatsMindSearch: "What do yo looking for?"
};
