import BTNViewInTheMap from "components/BTNViewInTheMap";
import { distance } from "helpers";
import classes from "./style/index.module.scss";
import QRIcon from "assets/images/qrcode.svg";
import { useHistory } from "react-router";
import useGPS from "hooks/useGPS";
import useLangContext from "hooks/useLangContext";

export type topTitleHeroProps = {
  children: any;
  onSaveBenefit?: () => void;
  loadingRecredit?: boolean;
  openModal?: () => void;
  loadingOnSaveBenefit?: boolean;
};

const CenterHero: React.FC<topTitleHeroProps> = ({
  children,
  onSaveBenefit,
  loadingOnSaveBenefit,
  loadingRecredit,
  openModal
}) => {
  const [coords] = useGPS();
  const history = useHistory();
  const myDistance = Math.floor(
    distance(coords, children.commerce || children)
  );
  const { lang } = useLangContext();
  function goToMapRecess() {
    document
      .getElementById("mapaRecess")
      ?.scrollIntoView({ block: "center", behavior: "smooth" });
  }

  return (
    <div
      className={`d-flex justify-content-between w-100 ${classes.bottomContent}`}>
      {children?.address ||
      children.commerce?.address ||
      (children.__typename &&
        children.__typename &&
        children.__typename.includes("Recess")) ? (
        <>
          <div className='right col-12 col-md-7 col-lg-7 p-0'>
            <div>
              {!Number.isNaN(myDistance) &&
                !children?.commerce?.branch_offices[0]["is_virtual"] && (
                  <p
                    className={`${classes.distance} font-weight-bold text-muted`}>
                    {" "}
                    A {myDistance} Km
                  </p>
                )}
            </div>
            {children.city_hall?.id || children.commerce ? (
              <div
                className={classes.cityHallRedirect}
                onClick={() =>
                  history.push(
                    `/cityhall/${
                      children?.__typename === "Recess"
                        ? children?.city_hall?.id
                        : children?.__typename === "BenefitType"
                        ? children?.commerce.city_hall?.id
                        : null
                    }`
                  )
                }>
                <h5>
                  {children?.commerce?.branch_offices[0]["is_virtual"]
                    ? "Virtual"
                    : children.city_hall?.name ||
                      children.commerce.city_hall?.name}
                </h5>
                {!children?.commerce?.branch_offices[0]["is_virtual"] && (
                  <p className='cityHall'>
                    {children?.address || children?.commerce.address}
                  </p>
                )}
              </div>
            ) : (
              <div>
                <h5>{children.city_hall?.name}</h5>
                <p className='cityHall'>{children.address}</p>
              </div>
            )}
          </div>
          <div className={classes.left}>
            {/* Entradas */}
            {children.stock && children.stock > children.stock_used ? (
              <>
                <div
                  className='my-3 '
                  onClick={() => children.getTicket(children.id)}>
                  <button className='primary--black mr-3'>
                    {lang.Elements.bookTickets}
                    <img className='ml-2' src={QRIcon} />
                  </button>
                </div>
              </>
            ) : null}

            {children.__typename && children.__typename.includes("Recess") ? (
              <BTNViewInTheMap
                goToMap={() => goToMapRecess()}
                difClass='primary primary--magenta mr-3'
                filter={
                  "brightness(0) saturate(100%) invert(16%) sepia(96%) saturate(5081%) hue-rotate( 315deg ) brightness(90%) contrast(107%)"
                }
                Text={lang.Elements.btnViewMap}
              />
            ) : children.__typename &&
              children.__typename == "BenefitType" &&
              !onSaveBenefit ? (
              <button
                onClick={openModal}
                className='secondary secondary--magenta d-block mr-3'>
                {loadingRecredit && (
                  <span
                    className='spinner-border spinner-border-sm mr-2'
                    role='status'
                    aria-hidden='true'
                  />
                )}
                Presentar mi QR
              </button>
            ) : children.__typename &&
              children.__typename == "BenefitType" &&
              children.type !== "pretrip" ? (
              <button
                onClick={onSaveBenefit}
                className='secondary secondary--magenta d-block mr-3'>
                {loadingOnSaveBenefit && (
                  <span
                    className='spinner-border spinner-border-sm mr-2'
                    role='status'
                    aria-hidden='true'
                  />
                )}
                {lang.Benefit.getBenefit}
              </button>
            ) : (
              ""
            )}
          </div>
        </>
      ) : null}
    </div>
  );
};
export default CenterHero;
