import useFirebaseEvent from "hooks/useFirebaseEvent";
import useSearchQuery from "hooks/useSearchQuery";
import { useCallback } from "react";
import { useHistory } from "react-router";
import Recess from "typedef/Recess";

const useOnClick = (amenity: Recess) => {
  const history = useHistory();
  const searchQuery = useSearchQuery();
  const reportFirebase = useFirebaseEvent<"se_busco_recreo">({
    eventName: "se_busco_recreo"
  });
  return useCallback(() => {
    if (Boolean(amenity.agenda?.length)) {
      if (searchQuery.get("busqueda"))
        //If there is an active search, assume the recess has been searched for
        reportFirebase({ id_recreo_multiple_buscado: amenity.id });
      history.push(`/recess/multiple/${amenity.id}`);
    } else if (Boolean(amenity.circuit?.length)) {
      if (searchQuery.get("busqueda"))
        //If there is an active search, assume the recess has been searched for
        reportFirebase({ id_recreo_multiple_buscado: amenity.id });
      history.push(`/recess/circuit/${amenity.id}`);
    } else {
      if (searchQuery.get("busqueda"))
        //If there is an active search, assume the recess has been searched for
        reportFirebase({ id_recreo_simple_buscado: amenity.id });
      history.push(`/recess/simple/${amenity.id}`);
    }
  }, [history]);
};

export default useOnClick;
