import { useCallback, useState } from "react";
import firebase from "firebase/app";
import { gql, useMutation } from "@apollo/client";
import getEnvVariable from "env";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import "firebase/auth";
import useFirebaseEvent from "hooks/useFirebaseEvent";
import { ACCESS_TOKEN_KEY } from "hooks/useAccessToken";

/**
 * Este Hook retorna una función que se encarga
 * de hacer el login con Google, carga el accessToken del servidor
 * en el store en caso de que sea exitoso, y te lleva a la pantalla Home.
 * En caso de que no tenga exito, muestra el mensaje correspondiente.
 */
const useLoginWithGoogle = (): [() => void, boolean, string] => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [error, setError] = useState(``);
  const [loginSocial, { loading }] = useMutation(mutation);
  const report = useFirebaseEvent({ eventName: "loginGoogle" });
  const login = useCallback(async () => {
    try {
      setError(``);
      const response = await firebase
        .auth()
        .signInWithPopup(new firebase.auth.GoogleAuthProvider());
      firebase.auth().signOut();
      const accessToken = (response.credential as any).accessToken;
      if (accessToken) {
        const {
          data: {
            login_social: { access_token }
          }
        } = await loginSocial({
          variables: {
            social_token: accessToken,
            client_id: getEnvVariable("CLIENT_ID"),
            client_secret: getEnvVariable("CLIENT_SECRET"),
            provider: "google"
          }
        });
        report();
        localStorage.setItem(ACCESS_TOKEN_KEY, access_token);
        window.location.reload();
      }
    } catch (error) {
      const graphqlErrors =
        error.graphQLErrors?.[0]?.extensions?.validation?.invalid_request;

      if (graphqlErrors?.includes("Authentication error, user not found")) {
        setError(`
            Parece que no estás registrado. 
            No tenemos ningún usuario vinculado con esa cuenta. 
            Si querés usarla, tenes que registrarte primero.
          `);
      } else if (error.code !== "auth/popup-closed-by-user") {
        setError(`
          Tuvimos un problema. 
          Parece que no pudimos comunicarnos correctamente con Google. 
          Por favor, intentalo nuevamente más tarde.
        `);
        console.error(
          `Tuvimos un problema al ingresar con Google ${JSON.stringify(error)}`
        );
      }
    }
  }, []);

  return [login, loading, error];
};

const mutation = gql`
  mutation loginWithSocial(
    $client_id: String!
    $client_secret: String!
    $provider: String!
    $social_token: String!
  ) {
    login_social(
      client_id: $client_id
      client_secret: $client_secret
      grant_type: "password"
      provider: $provider
      social_token: $social_token
    ) {
      access_token
    }
  }
`;

export default useLoginWithGoogle;
