import { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import Category from "typedef/Tag";

const useActivities = (): [Array<Category>, boolean] => {
  const [tags, setTags] = useState<Array<Category>>([]);
  const { loading } = useQuery(query, {
    onCompleted: ({ recess_category_list: { data: categories } }) => {
      setTags(categories);
    },
    onError: error => {
      console.error(
        `Tuvimos un problema cargando las categorías para los tags del registro. ${JSON.stringify(
          error
        )}`
      );
    }
  });

  return [tags, loading];
};

const query = gql`
  query {
    recess_category_list(limit: 99999) {
      data {
        id
        tag
        sub_category
      }
    }
  }
`;

export default useActivities;
