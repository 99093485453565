export default {
  title: "Complete seu perfil",
  personalDates: "Dados pessoais",
  name: "Nome",
  lastName: "Sobrenome",
  codAreaTel: "Código de área e telefone",
  document: "Documento de Identidade",
  dateOfBirth: "Data de nascimento",
  province: "província",
  provinceSelect: "selecionar província",
  country: "País",
  countrySelect: "Selecionar país",
  familyGropu: "Grupo familiar",
  cantFamily: "Quantos são no seu grupo familiar?",
  hasChilldren: "Filhos?",
  preferences: "Gostos e Preferências",
  activities: "Quais atividades você gostaria de realizar?",
  scapeQuestion: "Quais são suas preferencias de viagem?",
  createAccount: "Criar conta",

  finishRegister: "¡Registro concluído!",
  logWith: "Acesse seu e-mail para ativar sua conta."
};
