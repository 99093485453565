import * as React from "react";

const SvgQrSimple = props => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M8.72727 23.2727V26.1818H5.81818V23.2727H8.72727ZM8.72727 5.81818V8.72728H5.81818V5.81818H8.72727ZM26.1818 5.81818V8.72728H23.2727V5.81818H26.1818ZM2.90909 29.0682H11.6364V20.3636H2.90909V29.0682ZM2.90909 11.6364H11.6364V2.90909H2.90909V11.6364ZM20.3636 11.6364H29.0909V2.90909H20.3636V11.6364ZM14.5455 17.4545V32H0V17.4545H14.5455ZM26.1818 29.0909V32H23.2727V29.0909H26.1818ZM32 29.0909V32H29.0909V29.0909H32ZM32 17.4545V26.1818H23.2727V23.2727H20.3636V32H17.4545V17.4545H26.1818V20.3636H29.0909V17.4545H32ZM14.5455 0V14.5455H0V0H14.5455ZM32 0V14.5455H17.4545V0H32Z'
        fill='#00B2C8'
      />
    </svg>
  );
};

export default SvgQrSimple;
