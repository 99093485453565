import dayjs from "dayjs";
import Recess from "typedef/Recess";
import minMax from "dayjs/plugin/minMax";
dayjs.extend(minMax);

function getStartingDate(recesses: Array<Recess>, choosenDate?: "") {
  const firstMoment = dayjs(
    choosenDate ? choosenDate || undefined : recesses[0]?.date_from || undefined
  );

  const lastMoment = dayjs(
    choosenDate
      ? choosenDate
      : recesses[recesses?.length - 1]?.date_from || undefined
  );

  const startingDate = dayjs
    .min(dayjs.max(firstMoment, dayjs()), lastMoment)
    .toDate();

  return startingDate;
}

export default getStartingDate;
