import * as React from "react";

function SvgRoutes(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={25} height={32} {...props}>
      <path d='M21.924 13.132h-9.278v-1.427h7.137a2.141 2.141 0 001.52-.621l2.141-2.141c.83-.835.83-2.184 0-3.019l-2.141-2.141a2.137 2.137 0 00-1.513-.628h-7.137V1.728a1.071 1.071 0 10-2.142 0v1.427H2.66A2.142 2.142 0 00.519 5.296v4.282c0 1.182.959 2.141 2.141 2.141h7.851v1.427h-5.71a2.142 2.142 0 00-1.52.614L1.14 15.901a2.141 2.141 0 000 3.005l2.141 2.141a2.14 2.14 0 001.52.635h5.71v7.851H7.656a1.071 1.071 0 100 2.142h7.851a1.071 1.071 0 100-2.142h-2.855v-7.851h9.278a2.142 2.142 0 002.141-2.141v-4.282a2.14 2.14 0 00-2.148-2.127zM2.655 5.281h17.128l2.141 2.141-2.141 2.141H2.655V5.281zm19.269 14.274H4.796l-2.141-2.141 2.141-2.141h17.128v4.282z' />
    </svg>
  );
}

export default SvgRoutes;
