import * as React from "react";

function SvgFacemaskCircle(props) {
  return (
    <svg
      width={100}
      height={100}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 30 30'
      {...props}>
      <g fill='none' fillRule='evenodd'>
        <circle
          cx={12.65}
          cy={12.65}
          r={12.65}
          stroke='#666'
          strokeWidth={0.55}
          transform='translate(1 .6)'
        />
        <g fill='#666' fillRule='nonzero'>
          <path d='M10.103 14.627c1.212.38 2.475.572 3.745.573 1.27 0 2.533-.194 3.745-.573a.282.282 0 10-.167-.538 11.97 11.97 0 01-7.154 0 .282.282 0 00-.168.538h-.001zM10.144 12.947c1.225.19 2.464.284 3.704.283 1.24.001 2.479-.093 3.704-.283a.282.282 0 10-.087-.556 23.787 23.787 0 01-7.234 0 .282.282 0 00-.087.556zM17.387 16.076a8.063 8.063 0 01-7.078 0 .282.282 0 10-.243.507 8.617 8.617 0 007.564 0 .282.282 0 10-.243-.507z' />
          <path d='M21.17 10.416h-1.46a.845.845 0 00-.793-.563h-.425a3.474 3.474 0 01-2.68-1.318 2.532 2.532 0 00-3.928 0 3.474 3.474 0 01-2.68 1.318H8.78a.845.845 0 00-.793.563h-1.46c-.622 0-1.126.504-1.126 1.126v1.408c0 1.48 1.325 2.843 2.934 3.064a6.07 6.07 0 005.05 3.95c.308.035.62.035.929 0a6.07 6.07 0 005.05-3.95c1.608-.221 2.933-1.584 2.933-3.064v-1.408c0-.622-.504-1.126-1.126-1.126zm-12.39 0h.424a4.037 4.037 0 003.117-1.526 1.968 1.968 0 013.055 0 4.036 4.036 0 003.116 1.526h.425c.155 0 .281.126.281.282v.281l-.844-.001h-.006a4.459 4.459 0 01-3.41-1.733 1.405 1.405 0 00-2.182.002 4.455 4.455 0 01-3.407 1.73h-.007l-.844.002v-.281c0-.156.126-.282.281-.282zM5.962 12.95v-1.408c0-.31.252-.563.563-.563h1.408v2.816c.001.544.072 1.086.21 1.612-1.119-.27-2.18-1.225-2.18-2.457zm8.291 6.454c-.27.03-.542.03-.812 0a5.51 5.51 0 01-4.629-3.733v-.003a5.773 5.773 0 01-.315-1.873v-2.253l.844-.001a4.99 4.99 0 003.851-1.938.842.842 0 011.308-.002 5.02 5.02 0 003.846 1.94h.007l.844.001v2.253c0 .637-.107 1.27-.314 1.873v.002a5.51 5.51 0 01-4.63 3.734zm7.479-6.454c0 1.232-1.062 2.187-2.181 2.457a6.375 6.375 0 00.21-1.612V10.98h1.408c.31 0 .563.252.563.563v1.408z' />
        </g>
      </g>
    </svg>
  );
}

export default SvgFacemaskCircle;
