import Logo from "../../assets/images/logo-recreo-blanco.svg";
import Banner from "assets/images/banner.png";
import BannerMobile from "assets/images/banner-mobile.png";
import BannerText from "assets/images/banner-text.png";
import AppStore from "../../assets/images/app-store-button.png";
import PlayStore from "../../assets/images/google-play-button.png";
import Claim from "./../../assets/images/claim.png";
import FooterLogo from "../../assets/images/landing-footer-logo.png";
import Facebook from "./../../assets/images/facebook-logo.svg";
import Instagram from "./../../assets/images/instagram-logo.svg";
import "./style.scss";
import { useRedirectBySistemOperative } from "./useRedirectBySistemOperative";

const Landing = () => {
  useRedirectBySistemOperative();

  return (
    <div className='landing-container container-full'>
      <div className='p-0 landing'>
        <div className='contactForm'>
          <header className='landing-header'>
            <div className='container d-flex align-items-center justify-content-between position-relative'>
              <div className='logo'>
                <a href='/'>
                  <img src={Logo} />
                </a>
              </div>
            </div>
          </header>

          <div
            className='row banner-landing m-0'
            style={{ backgroundImage: "url(" + Banner + ")" }}>
            <div className='col-12 img-container'>
              <div>
                <img className='claim' src={Claim} />
              </div>
              <div className='text-side col-lg-6  landingText'>
                <img className='banner-text' src={BannerText} />

                <div className='downloadLinks'>
                  <a
                    target='_blank'
                    href='https://play.google.com/store/apps/details?id=org.gba.recreo'>
                    <img className='playstore' src={PlayStore} />
                  </a>

                  <a
                    target='_blank'
                    href='https://apps.apple.com/us/app/recreo-en-la-provincia/id1542361713?app=itunes&ign-mpt=uo%3D4'>
                    <img className='appstore' src={AppStore} />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className='footer-landing mt-0'>
            <div className='container d-flex justify-content-between align-items-center w-100'>
              <img className='landing-footer-logo' src={FooterLogo} />

              <div className='social-links'>
                <a target='_blank' href='https://www.facebook.com/recreopba'>
                  <img src={Facebook} />
                </a>

                <a target='_blank' href='https://www.instagram.com/recreopba/'>
                  <img src={Instagram} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
