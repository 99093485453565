import { useState } from "react";
import useDay from "./hooks/useDay";
import dayjs from "dayjs";
import "dayjs/plugin/calendar";
import MainIcon from "assets/images/agenda-title.svg";
import useDatePicker from "./modal/useDatePicker";
import CategoriesFilter from "./CategoriesFilter";
import AgendaList from "./AgendaList";
import useLangContext from "hooks/useLangContext";
import Destacado from "./DestacadoAgenda";

const Agenda = () => {
  const [selectedDay, setSelectedDay] = useState(dayjs(new Date()));
  const allAgenda = useDay(selectedDay.format("YYYY-MM-DD"));
  const { lang } = useLangContext();

  const showDatePicker = useDatePicker(
    date => setSelectedDay(date),
    selectedDay
  );

  function openModal(e: any) {
    e.preventDefault();
    showDatePicker();
  }

  return (
    <div className='container-full position-relative'>
      <Destacado />

      <div className='container'>
        <div className='section-title d-flex align-items-center my-4'>
          <img src={MainIcon} className='mr-4 logo-sub-title' />
          <h2 className='text-secondary font-weight-bold'>
            {lang.Agendas.title}
          </h2>
        </div>
        <CategoriesFilter
          setSelectedDay={setSelectedDay}
          openModal={openModal}
        />
        <AgendaList day={allAgenda} />
      </div>
    </div>
  );
};

export default Agenda;
