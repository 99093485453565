function validatePassword(password?: string) {
  return !(
    (password?.length || 0) < 8 ||
    !password
      ?.split("")
      .some(char => char === char.toUpperCase() && isNaN(parseInt(char))) ||
    !password?.split("").some(char => !isNaN(parseInt(char)))
  );
}

export default validatePassword;
