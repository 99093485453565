import useSocialShare from "hooks/useSocialShare";
import ShareIcon from "assets/images/share.svg";

const ShareButton: React.FC<{
  idEntity: string;
  entity: string;
  object: any;
}> = ({ idEntity, entity, object }) => {
  const useShareSocial = useSocialShare(idEntity, entity, object);
  const useShare = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    useShareSocial();
  };
  return (
    useShare && (
      <>
        <button onClick={useShare} style={{ all: "unset", cursor: "pointer" }}>
          <img className='m-3' src={ShareIcon} />
        </button>
        <div className='h-100'></div>
      </>
    )
  );
};

export default ShareButton;
