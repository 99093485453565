import { gql, useQuery } from "@apollo/client";
import getRecreditsExample from "helpers/getReCreditsExample";
import useLangContext from "hooks/useLangContext";
import useSearchQuery from "hooks/useSearchQuery";
import { useEffect, useMemo, useState } from "react";
import BenefitCategory from "typedef/BenefitCategory";
import ReCredit from "typedef/ReCredits";

const useReCredits = () => {
  const [reCredits, setRecredits] = useState<Array<ReCredit>>([]);
  const [locations, setLocations] = useState();
  const searchQuery = useSearchQuery();
  const { lang } = useLangContext();
  const search = searchQuery.get("busqueda") || "";
  const categoryId = searchQuery.get("filtro") || "";
  const { loading } = useQuery(queryRecredit, {
    fetchPolicy: "no-cache",
    variables: {
      lang: lang.current
    },
    onCompleted: ({ benefit_recredit_list: { data: recreditsReturned } }) => {
      let resultSearch: Array<ReCredit> = [];
      let resultCategory: Array<ReCredit> = [];
      if (search !== "" || categoryId != "") {
        if (search !== "") {
          resultSearch = recreditsReturned.filter((recredit: any) => {
            return recredit.name.includes(search);
          });
        } else {
          resultCategory = recreditsReturned.filter((recredit: any) => {
            return recredit.category.id === categoryId;
          });
        }
        setRecredits(resultSearch.concat(resultCategory));
      } else {
        setRecredits(recreditsReturned);
      }
    },
    onError: error => {
      console.error(
        "Parece que tuvimos un problema al traer los recreditos",
        error
      );
    }
  });

  useEffect(() => {
    //si se hizo una busqueda filtro los recreditos por esa busqueda.

    //traigo todas las locations
    if (reCredits.length > 0) {
      let location: any = reCredits.map(item => {
        return {
          name: item.commerce.name,
          longitude: item.commerce.longitude,
          latitude: item.commerce.latitude
        };
      });
      setLocations(location);
    }
  }, [loading, search, categoryId]);

  return useMemo(
    () => ({
      reCredits,
      loading,
      locations
    }),
    [reCredits, loading]
  );
};

const queryRecredit = gql`
  query getReCredits($lang: String) {
    benefit_recredit_list(lang: $lang) {
      data {
        id
        name
        current_stock
        weekly_stock
        cost_recredits
        category(lang: $lang) {
          id
          name
          icon {
            uri
          }
        }
        commerce {
          id
          name
          images {
            uri
          }
          address
          latitude
          longitude
          city_hall {
            id
            name
          }
        }
        icon {
          uri
        }
      }
    }
  }
`;
export default useReCredits;
