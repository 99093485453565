import * as React from "react";

function SvgLocked(props) {
  return (
    <svg
      viewBox='0 0 14 17'
      width={14}
      height={17}
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g
        color='currentColor'
        fill='currentColor'
        stroke='currentColor'
        fill='#000'
        fillRule='evenodd'>
        <path
          color='currentColor'
          fill='currentColor'
          stroke='currentColor'
          d='M12.688 6.16h-1.2v-1.6a4.4 4.4 0 10-8.8 0v1.6h-1.2a1.2 1.2 0 00-1.2 1.2v8a1.2 1.2 0 001.2 1.2h11.2a1.2 1.2 0 001.2-1.2v-8a1.2 1.2 0 00-1.2-1.2zm-8.8-1.6a3.2 3.2 0 116.4 0v1.6h-6.4v-1.6zm8.8 10.8h-11.2v-8h11.2v8z'
        />
        <path
          color='currentColor'
          fill='currentColor'
          stroke='currentColor'
          d='M7.288 13.36a1.8 1.8 0 100-3.6 1.8 1.8 0 000 3.6zm0-2.4a.6.6 0 110 1.2.6.6 0 010-1.2z'
        />
      </g>
    </svg>
  );
}

export default SvgLocked;
