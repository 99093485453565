import * as React from "react";

function SvgUser(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={34} height={32} {...props}>
      <path d='M17.001 20.02c4.521 0 8.205-4.414 8.205-9.846S21.522.328 17.001.328s-8.205 4.414-8.205 9.846 3.692 9.846 8.205 9.846zm0-17.23c3.175 0 5.744 3.282 5.744 7.385s-2.568 7.385-5.744 7.385c-3.175 0-5.744-3.282-5.744-7.385s2.568-7.385 5.744-7.385z' />
      <path d='M24.509 19.2a1.237 1.237 0 10-.246 2.462 7.992 7.992 0 017.04 7.385H2.7a8.058 8.058 0 017.04-7.385 1.237 1.237 0 10-.246-2.462c-.09 0-9.313 1.05-9.313 11.069 0 .68.551 1.231 1.231 1.231h31.18a1.23 1.23 0 001.231-1.231c0-10.018-9.223-11.069-9.313-11.069z' />
    </svg>
  );
}

export default SvgUser;
