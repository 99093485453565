const getYoutubeLinkId = (urlVideo: string) => {
  if (urlVideo) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = urlVideo.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  }
  return urlVideo;
};

export default getYoutubeLinkId;
