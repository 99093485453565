import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import useAccessToken, { ACCESS_TOKEN_KEY } from "hooks/useAccessToken";
import Logo from "assets/images/logo-recreo-blanco.svg";
import PlayStore from "assets/images/play-store-round.svg";
import AppStore from "assets/images/app-store-round.svg";
import Burger from "assets/images/burger.svg";
import CloseBurger from "assets/images/closeBurger.svg";
import classes from "./style/index.module.scss";
import MenuMobile from "../MenuMobile/index";
import useUser from "../../hooks/useUser";
import UserIcon from "assets/images/userIcon.svg";
import DropDownIcon from "assets/images/dropDown.svg";
import LogOut from "assets/images/logout.svg";
import useUserPoints from "../../screens/UserProfile/useUserPoints";
import BenefitsIcon from "../../assets/images/benefits-icon.svg";
import TicketIcon from "../../assets/images/ticket-icon.svg";
import BellIcon from "../../assets/images/bell-icon.svg";
import FavoriteIcon from "../../assets/images/favorite.svg";
import ConfigurationNot from "../../assets/images/config-notifications.svg";
import miAgendaImage from "assets/images/mi_agenda.svg";
import Icon from "assets/icons";
import useLangContext from "hooks/useLangContext";
import useNotificationsCount from "hooks/useNotificationsCount";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [openUser, setOpenUser] = useState(false);
  const accessToken = useAccessToken();
  const [user, loading] = useUser();
  const points = useUserPoints();
  const location = useLocation();
  const { notificationsCount: notifications } = useNotificationsCount(); //traigo todas las notificaciones a leer
  const { lang, dispatch } = useLangContext();

  function logOut() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    window.location.replace("/");
  }
  useEffect(() => {
    setOpen(false);
    setOpenUser(false);
  }, [location.pathname, location]);

  return (
    <>
      <div className={classes.desktop}>
        <div
          className={` ${classes.snackBar} ${scroll ? classes.scrolled : ""}`}>
          <div className='navbar-global-primary container d-flex flex-row align-items-center justify-content-between py-2'>
            <div className={classes.languages}>
              {/*
              <div
                className={`${lang.current == "es" && "text-white"}`}
                onClick={() => dispatch({ type: "es" })}
                id='spanish'>
                Español
              </div>
              <div
                className={`${lang.current == "en" && "text-white"}`}
                onClick={() => dispatch({ type: "en" })}
                id='english'>
                English
              </div>
              <div
                className={`${lang.current == "pt" && "text-white"}`}
                onClick={() => dispatch({ type: "pt" })}
                id='portugues'>
                Português
              </div>
              */}
            </div>
            <div className={classes.navigation}>
              <ul>
                {/* <a
                  href='https://portal-coronavirus.gba.gob.ar/'
                  target='_blank'
                  rel='noopener noreferrer'>
                  {lang.Navbar.header.top.vaccination}
                </a> */}
                {/* <Link to='/destinos-cuidados'>
                  {lang.Navbar.header.top.destiny}
                </Link> */}
                <Link to='/terms-and-condition'>
                  {lang.Navbar.header.top.termsAndCondi}
                </Link>
                <Link to='/FAQs'>
                  {lang.Navbar.header.top.frequentQuestions}
                </Link>
              </ul>
              <div className={classes.appDownload}>
                <p className='bold'>{lang.Navbar.header.top.downloadRecreo}</p>
                <a
                  href='https://play.google.com/store/apps/details?id=org.gba.recreo'
                  target='_blank'>
                  <img src={PlayStore} />
                </a>
                <a
                  href='https://apps.apple.com/us/app/recreo-en-la-provincia/id1542361713?app=itunes&ign-mpt=uo%3D4'
                  target='_blank'>
                  <img src={AppStore} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <header
          className={`navbar-global-primary bg-primary d-flex flex-row align-items-center sticky-top ${
            classes.sticky
          } ${scroll ? classes.scrolled : ""}`}>
          <div className='container d-flex align-items-center justify-content-between position-relative'>
            <div className={classes.logo}>
              <a href='/'>
                <img src={Logo} />
              </a>
            </div>
            <div className={classes.navigation}>
              <ul className='links'>
                <NavLink to='/cityhalls'>
                  {lang.Navbar.header.main.knowCityHall}
                </NavLink>
                <NavLink to='/recess'>
                  {lang.Navbar.header.main.recreos}
                </NavLink>
                <NavLink to='/agenda'>
                  {lang.Navbar.header.main.schedule}
                </NavLink>
                <NavLink to='/benefits'>
                  {lang.Navbar.header.main.benefit}
                </NavLink>
                {/* <NavLink to='/convocatorias'>
                  {lang.Navbar.header.main.convocatorias}
                </NavLink> */}
              </ul>
              <div className={classes.userCta}>
                {accessToken ? (
                  <button
                    className='primary d-flex justify-content-between align-items-center'
                    onClick={() => setOpenUser(!openUser)}>
                    <img
                      className={`mr-2`}
                      src={UserIcon}
                      alt='icono de usuario'
                    />
                    {notifications >= 1 && (
                      <span className={`${classes.hasNotifications}`} />
                    )}
                    {user?.first_name}
                    <img
                      className='ml-2'
                      src={DropDownIcon}
                      alt='dropdown menu'
                    />
                  </button>
                ) : (
                  <>
                    <Link to='/login'>
                      <button className='primary'>
                        {lang.Navbar.header.main.login}
                      </button>
                    </Link>
                    <Link to='/register'>
                      <button className='secondary'>
                        {lang.Navbar.header.main.singIn}
                      </button>
                    </Link>
                  </>
                )}
              </div>
            </div>

            {/*the user menu */}

            {openUser ? (
              <div
                className={`col-lg-3 col-md-3 position-absolute ${classes.userMenu}`}>
                <h6 className='font-weight-bold'>
                  {user?.first_name} {user?.last_name}
                </h6>
                <Link to='/edit-profile' className='my-1'>
                  {lang.Navbar.header.login.editProfile}
                </Link>

                <div className='userProfile my-4'>
                  <Link to='/perfil'>
                    <h6 className='text-magenta font-weight-bold'>
                      {" "}
                      {lang.Navbar.header.login.level} {` `}
                      {points < 1000
                        ? `${lang.Navbar.header.login.virtual}`
                        : points >= 1000 && points < 2000
                        ? `${lang.Navbar.header.login.curious}`
                        : points >= 2000 && points < 3000
                        ? `${lang.Navbar.header.login.explorer}`
                        : points >= 3000 && points <= 4000
                        ? `${lang.Navbar.header.login.expert}`
                        : ""}
                    </h6>
                    <span>
                      {points} {lang.Navbar.header.login.points}
                    </span>
                  </Link>
                </div>

                <div className='separador'></div>

                <ul>
                  <Link to='/Favs' className='d-flex align-items-center my-2'>
                    <img src={FavoriteIcon} className='mr-2' />
                    <span>{lang.Navbar.header.login.myFavs}</span>
                  </Link>

                  <Link
                    to='/my-benefits'
                    className='d-flex align-items-center my-2'>
                    <img src={BenefitsIcon} className='mr-2' />
                    <span>{lang.Navbar.header.login.myBenefits}</span>
                  </Link>

                  <Link
                    to='/my-tickets'
                    className='d-flex align-items-center my-2'>
                    <img src={TicketIcon} className='mr-2' />
                    <span>{lang.Navbar.header.login.myTickets}</span>
                  </Link>

                  <Link
                    to='/my-schedules'
                    className='d-flex align-items-center my-2'>
                    <img
                      src={miAgendaImage}
                      className={`${classes.miAgenda} mr-2`}
                    />
                    <span>{lang.Navbar.header.login.mySchedule}</span>
                  </Link>

                  <Link
                    to='/survays'
                    className='d-flex align-items-center my-2'>
                    <Icon
                      size={15}
                      custom='EmptyStar'
                      fill={"rgb(235, 1, 137)"}
                      className='mr-2'
                    />
                    <span>{lang.Navbar.header.login.polls}</span>
                  </Link>
                </ul>

                <ul className='mt-4'>
                  <Link
                    to='/notifications'
                    className='d-flex align-items-center mt-2'>
                    <img src={BellIcon} className={`mr-2`} />
                    {notifications >= 1 && (
                      <span className={`${classes.hasNotifications}`}>
                        {" "}
                        {notifications}
                      </span>
                    )}
                    <span>{lang.Navbar.header.login.notifications}</span>
                  </Link>
                  <Link
                    to='/notifications-settings'
                    className='d-flex align-items-center mt-2'>
                    <img src={ConfigurationNot} className={`mr-2`} />
                    <span>{lang.Navbar.header.login.configurate}</span>
                  </Link>
                </ul>

                <div className='mt-4' onClick={() => logOut()}>
                  <img className='mr-2' src={LogOut} alt='Cerrar Sesión' />
                  <span>{lang.Navbar.header.login.logOut}</span>
                </div>
              </div>
            ) : null}
          </div>
        </header>
      </div>
      <div className={classes.mobile}>
        <div
          className={`${classes.snackBar} ${scroll ? classes.scrolled : ""}`}>
          <div className='navbar-global-primary container d-flex flex-row align-items-center justify-content-center py-2'>
            <div className={classes.languages}>
              {/* <div onClick={() => dispatch({ type: "es" })} id='spanish'>
                  Español
                </div>
                <div onClick={() => dispatch({ type: "en" })} id='english'>
                  English
                </div>
                <div onClick={() => dispatch({ type: "pt" })} id='portugues'>
                  Português
                </div> */}
            </div>
            <div className={classes.navigation}>
              <div className={classes.appDownload}>
                <p className='bold'>{lang.Navbar.header.top.downloadRecreo}</p>
                <a href='#' target='_blank'>
                  <img src={PlayStore} />
                </a>
                <a href='#' target='_blank'>
                  <img src={AppStore} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <header
          className={`navbar-global-primary bg-primary align-items-center position-sticky sticky-top ${
            classes.sticky
          } ${scroll ? classes.scrolled : ""}`}>
          <div className='container py-3 d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center'>
              <div className={classes.logo}>
                <a href='/'>
                  <img src={Logo} />
                </a>
              </div>
            </div>
            <div
              className={`burger button mr-3 ${classes.burgerIcon}`}
              onClick={() => setOpen(!open)}>
              {open ? <img src={CloseBurger} /> : <img src={Burger} />}
            </div>
          </div>

          {open ? <MenuMobile user={user} /> : null}
        </header>
      </div>
    </>
  );
};

export default Header;
