import usePrompt from "hooks/usePrompt";
import { useCallback } from "react";
import Reward from "typedef/Reward";
import ModalReward from "./ModalReward";

const useModalReward = (reward: Reward) => {
  const prompt = usePrompt();

  return useCallback(async (userId: string) => {
    prompt<any>(props => (
      <ModalReward userId={userId} reward={reward} {...props} />
    ))
      .then(response => console.log())
      .catch(error => console.error(error));
  }, []);
};

export default useModalReward;
