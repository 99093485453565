/**
 * Redireccionador segun sistema operativo
 */
export const useRedirectBySistemOperative = () => {
  let userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
    window.location.href =
      "https://apps.apple.com/us/app/recreo-en-la-provincia/id1542361713?app=itunes&ign-mpt=uo%3D4";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
    window.location.href =
      "https://play.google.com/store/apps/details?id=org.gba.recreo";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
};
