import dayjs from "dayjs";
import { Reducer } from "redux";
import User from "typedef/User";

export type ReducerAction = {
  type: keyof User | "tag_ids" | "set_user";
  payload: string | number | User;
};

export type RegisterProfileReducer = Reducer<
  Partial<User & { tag_ids: Array<string> }>,
  ReducerAction
>;

const reducer: RegisterProfileReducer = (user, { type, payload }) => {
  switch (type) {
    case "first_name":
      return {
        ...user,
        first_name: payload as string
      };
    case "phone":
      if ((payload as string).length > 3 && (payload as string).length < 7)
        return { ...user, phone: payload as string, phone_error: "Muy corto" };
      return { ...user, phone: payload as string, phone_error: undefined };
    case "phone_prefix":
      if ((payload as string)[0] !== "+")
        return {
          ...user,
          phone_prefix: payload as string,
          phone_prefix_error: "Falta +"
        };
      return {
        ...user,
        phone_prefix: payload as string,
        phone_prefix_error: undefined
      };
    case "document_number":
      return {
        ...user,
        document_number: payload as string,
        error_document_number:
          (payload as string).length < 7 && "Debe ser un dni válido"
      };
    case "birth_day":
      return {
        ...user,
        birth_day: dayjs(payload as string).format("YYYY-MM-DD")
      };
    case "city_hall_id":
      return {
        ...user,
        city_hall_id: payload as number,
        locality_id: undefined
      };
    case "tag_ids":
      if (user?.tag_ids?.includes(payload as string)) {
        return {
          ...user,
          gustos: user?.tag_ids?.filter(tag_id => tag_id !== payload)
        };
      } else {
        return {
          ...user,
          tag_ids: [...(user?.tag_ids || []), payload as string]
        };
      }
    case "set_user":
      return {
        ...user,
        ...(payload as User)
      };
    default:
      return {
        ...user,
        [type]: payload
      };
  }
};

export default reducer;
