const useHowUseRecredits = () => {
  let msj =
    "Conocé cómo funcionan los ReCréditos de nuestra provincia y aprovechá nuestros beneficios. \n 1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce in consequat lectus. Sed eu consectetur sapien. \n 2. Etiam finibus, justo sed finibus tristique, mi libero blandit turpis, sed fermentum leo dolor sit amet augue. \n 3. In finibus felis vitae tempus vestibulum. Maecenas vulputate quam non erat aliquet tristique. Quisque ipsum diam, posuere.";

  let preItems = msj.split("\n")[0];
  let items = msj.split("\n").splice(1);

  let itemsStructured = items.map(item => {
    let blod = item.slice(0, 3);
    let normal = item.slice(3);

    return [blod, normal];
  });

  return { itemsStructured, preItems };
};

export default useHowUseRecredits;
