import { useState, useEffect } from "react";
import usePropuestaSimple from "./usePropuestaSimple";
import useVotar from "../Votacion/useVotar";
import useSearchQuery from "hooks/useSearchQuery";
import getEnvVariable from "env";
import { LIGHT_BLUE, GRAY, VIOLET } from "assets/colors/index";
import Icon from "assets/icons";
import Loader from "components/Loader";
import usePropuestasVotadas from "../Propuesta/usePropuestasVotadas";
import BackArrow from "assets/images/back-arrow.svg";
import { useHistory } from "react-router-dom";
import MultimediaPropuesta from "typedef/MultimediaPropuesta";

function Propuesta() {
  const [preview, setPreview] = useState("");
  const history = useHistory();
  const [estadoVotacion, setEstadoVotacion] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const url = getEnvVariable("SERVER_URL");
  const param = useParam<{ id: string }>();
  var propuestaId = param.id || undefined;
  const { propuesta, loading } = usePropuestaSimple(propuestaId);
  useEffect(() => {
    setPreview(url + propuesta?.multimedia[0]?.path);
  }, [propuesta]);
  const propuestasVotadas = usePropuestasVotadas(propuesta?.annoucement?.id);
  const [listadoIdPropuestas, setListadoIdPropuesta] = useState<number[]>([]);
  useEffect(() => {
    const vectorIds: Array<number> = [];
    propuestasVotadas.propuestas.map(propuesta => {
      vectorIds.push(propuesta.id);
    });
    setListadoIdPropuesta(vectorIds);
  }, [propuestasVotadas]);

  useEffect(() => {
    listadoIdPropuestas.map((idListado: any) => {
      if (idListado == propuesta.id) {
        setEstadoVotacion(true);
      }
    });
  }, [listadoIdPropuestas, propuesta]);
  const votar = useVotar(propuestaId);
  return (
    <>
      {loading ? (
        <div className='d-flex m-auto text-center p-5'>
          <Loader />
        </div>
      ) : (
        <>
          <div className='bg-white'>
            <div className='container p-5'>
              <button className='backButton' onClick={() => history.goBack()}>
                <img src={BackArrow} />
                Volver
              </button>
              {/* <div className='d-flex justify-content-between'> */}
              <p className='font-weight-bolder mt-3' style={{ color: GRAY }}>
                {propuesta.annoucement?.title}
              </p>
              <hr />
              <h5 className='mt-3 mb-3' style={{ color: VIOLET }}>
                <strong>{propuesta?.description}</strong>
              </h5>
              <p>Propuesta presentada por: </p>
              <div className='d-flex justify-content-between'>
                <h5>
                  {propuesta.user?.first_name} {propuesta.user?.last_name}
                </h5>
                {propuesta.annoucement?.state === "in_voting" ? (
                  <button
                    className={
                      estadoVotacion
                        ? "secondary secondary--gray disabled d-none d-sm-block"
                        : "secondary secondary--magenta d-none d-sm-block"
                    }
                    onClick={() =>
                      estadoVotacion
                        ? null
                        : () => {
                            votar();
                            setEstadoVotacion(true);
                          }
                    }>
                    Votar
                  </button>
                ) : null}
              </div>
            </div>
          </div>
          <div className='container bg-white mt-5 mb-5 d-flex p-5 justify-content-center rounded'>
            {propuesta?.annoucement?.annoucement_type === "complex" ? (
              <div className='container'>
                <Field multimedias={propuesta?.multimedia} />
              </div>
            ) : propuesta?.multimedia[0]?.type?.includes("image") ? (
              <img className='rounded img-fluid' src={preview} />
            ) : propuesta?.multimedia[0]?.type?.includes("video") ? (
              <video style={{ maxWidth: "100%" }} controls>
                <source src={preview} type='video/mp4' />
              </video>
            ) : propuesta?.multimedia[0]?.type?.includes("application") ? (
              <div className='d-flex flex-column justify-content-center'>
                <p className='mb-3' style={{ color: LIGHT_BLUE }}>
                  <strong>Descargar Documento</strong>
                </p>
                <button
                  type='button'
                  onMouseEnter={() => setIsHover(true)}
                  onMouseLeave={() => setIsHover(false)}
                  style={{ all: "unset" }}>
                  <a href={preview} target='_blank'>
                    <Icon
                      custom='Document'
                      style={{ alignSelf: "center", cursor: "pointer" }}
                      size={150}
                      stroke={LIGHT_BLUE}
                      fill={isHover ? "#fff" : LIGHT_BLUE}
                      colorLine={isHover ? LIGHT_BLUE : "#fff"}
                    />
                  </a>
                </button>
              </div>
            ) : propuesta?.multimedia[0]?.type?.includes("audio") ? (
              <audio src={preview} preload='metadata' controls></audio>
            ) : null}
          </div>
        </>
      )}
    </>
  );
}

const Field: React.FC<{ multimedias: Array<MultimediaPropuesta> }> = ({
  multimedias
}) => {
  const url = getEnvVariable("SERVER_URL");
  var fields = multimedias.map(multimedia => {
    if (multimedia.type?.includes("image")) {
      return (
        <div>
          <img
            style={{ maxWidth: "100%", maxHeight: "600px" }}
            src={url + multimedia.path}
          />
          <hr className='mb-5 mt-5' />
        </div>
      );
    } else if (multimedia.type?.includes("audio")) {
      return (
        <div className='mt-5 mb-5'>
          <audio
            style={{ minWidth: "100%" }}
            src={url + multimedia.path}
            preload='metadata'
            controls></audio>{" "}
          <hr />
        </div>
      );
    } else if (multimedia.type?.includes("application")) {
      return (
        <>
          <div className='d-flex flex-column'>
            <p className='mb-3 text-center' style={{ color: LIGHT_BLUE }}>
              <strong>Descargar Documento</strong>
            </p>
            <div className='d-flex justify-content-center'>
              <button type='button' style={{ all: "unset" }}>
                <a href={url + multimedia.path} target='_blank'>
                  <Icon
                    custom='Document'
                    style={{ alignSelf: "center", cursor: "pointer" }}
                    size={150}
                    stroke={LIGHT_BLUE}
                    fill={LIGHT_BLUE}
                    colorLine={"#fff"}
                  />
                </a>
              </button>
            </div>
          </div>
          <div>
            <hr className='' />
          </div>
        </>
      );
    } else if (multimedia.type?.includes("video")) {
      return (
        <div className='mb-5'>
          <video style={{ maxWidth: "100%", maxHeight: "600px" }} controls>
            <source src={url + multimedia.path} type='video/mp4' />
          </video>
          <hr className='mb-2 mt-5' />
        </div>
      );
    }
  });

  return <div className='d-flex flex-column'>{fields}</div>;
};

export default Propuesta;
