import * as React from "react";

function SvgLogoRecreoNegro(props) {
  return (
    <svg
      data-name='Capa 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 138 138'
      {...props}>
      <path
        className='logo-recreo-negro_svg__cls-1'
        d='M69 0a69 69 0 1069 69A69 69 0 0069 0zm42.62 65.26c-5.45 26-33.71 37.84-48.6 31.56S48 100.05 48 100.05v20.44c0 1.31-1.33 3.33-5 1-3.9-2.51-4.15-.6-7.55-2.93S32 110.45 32 110.45L32.4 38s-.14-1.92-.14-6.54 6.06-4.94 6.06-4.94h12.33s16-12 19.55-13.45c1.68-.68 7.89 3.14 14 7.39 3.25 2.25-.82 5 1.69 6.84 2.86 2.15 11 7.44 11 7.44s11.77 7.65 10.52 11-5.45 4.94-1.33 9.6l3.83 4s2.64 1.5 1.71 5.92z'
      />
      <path
        className='logo-recreo-negro_svg__cls-2'
        d='M53.69 51.93a19.73 19.73 0 002.39.14 5.12 5.12 0 003.05-.75 2.92 2.92 0 001-2.5c0-2.2-1.37-3.29-4.11-3.29a13.25 13.25 0 00-2.35.21zM61.3 66.4l-5.89-8.75h-1.72v8.8H46V41a41.27 41.27 0 014.5-.76 39.88 39.88 0 014.64-.24c4.08 0 7.16.73 9.22 2.17a7.59 7.59 0 013.1 6.65 7.87 7.87 0 01-4.65 7.48l7 10.1zM78.58 60.28h12.3v6.12h-19.8V40H90.7v6.12H78.58v3.76h10.29v6.1H78.58v4.3zM46.44 77.64a6.67 6.67 0 01.8-3.37 5.06 5.06 0 012.35-2.09 8.91 8.91 0 013.78-.72 12.15 12.15 0 012.56.25v2.85a11.77 11.77 0 00-2.26-.22 5 5 0 00-2 .34 2.31 2.31 0 00-1.16 1.06 4 4 0 00-.37 1.86 4.17 4.17 0 00.36 1.88 2.19 2.19 0 001.13 1 4.86 4.86 0 002 .34 9.65 9.65 0 002.34-.33v2.85a9.61 9.61 0 01-1.28.26 11.41 11.41 0 01-1.41.1q-6.8 0-6.8-6.11M60.69 76.94a10.11 10.11 0 001.08.07 2.29 2.29 0 001.37-.35 1.37 1.37 0 00.45-1.15c0-1-.61-1.51-1.84-1.51a5.36 5.36 0 00-1.06.1zm3.42 6.64l-2.64-4h-.78v4h-3.45V71.94a18 18 0 012-.35 17 17 0 012.09-.13 7 7 0 014.13 1 3.49 3.49 0 011.39 3.05 3.85 3.85 0 01-.54 2.07 3.66 3.66 0 01-1.54 1.36l3.12 4.64zM72.22 80.81h5.7v2.77h-9.16V71.62h9.07v2.77h-5.61v1.71h4.77v2.76h-4.77v1.95zM86.71 80.14a4 4 0 00.63-2.47 4.22 4.22 0 00-.63-2.58 2.55 2.55 0 00-3.72 0 3.92 3.92 0 00-.64 2.48 4.25 4.25 0 00.65 2.56 2.21 2.21 0 001.86.81 2.24 2.24 0 001.87-.8m-5.1 2.91A4.85 4.85 0 0179.5 81a8.07 8.07 0 010-6.75 4.85 4.85 0 012.11-2.07 7.83 7.83 0 016.47 0 4.87 4.87 0 012.11 2.08 7.92 7.92 0 010 6.73 4.87 4.87 0 01-2.11 2.08 7.1 7.1 0 01-3.24.7 7 7 0 01-3.23-.7'
      />
    </svg>
  );
}

export default SvgLogoRecreoNegro;
