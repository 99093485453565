import Recess from "typedef/Recess";
import ubicationIcon from "assets/images/conoce.png";
import ShareIcon from "assets/images/share.svg";
import { distance } from "helpers";
import { useHistory } from "react-router-dom";
import useSocialShare from "hooks/useSocialShare";
import getType from "helpers/getType";

type CardWayProps = {
  recess?: Recess;
  coords?: GeolocationCoordinates;
  openModal?: () => void;
};

const CardWay: React.FC<CardWayProps> = ({ recess, coords, openModal }) => {
  const history = useHistory();
  const socialShare = useSocialShare(recess.id, getType(recess), recess);

  return (
    <div
      onClick={() => history.push(`/recess/simple/${recess?.id}`)}
      style={{ minHeight: 90 }}
      className='bg-white rounded w-100 clickable d-flex p-2'>
      <div
        className='d-flex flex-column align-items-center mx-2 mr-4'
        style={{ opacity: 0.2 }}>
        <img height='32px' width='32px' src={ubicationIcon} />
        <div
          className='mt-3'
          style={{
            border: "2px dashed",
            borderRadius: "10px",
            transform: "rotateZ(90deg)",
            color: "#8b2c75",
            width: "100%"
          }}
        />
      </div>
      <div className='d-flex flex-column justify-content-between my-1'>
        <div className='font-weight-bold'>{recess?.title}</div>
        <p> A {Math.floor(distance(coords, recess)) || "--"} Km</p>
      </div>
      <div className='d-flex flex-column ml-auto my-1'>
        {socialShare !== undefined && (
          <img
            className='ml-auto'
            width='16px'
            height='16px'
            style={{ cursor: "pointer" }}
            onClick={event => {
              event.stopPropagation();
              socialShare?.();
            }}
            src={ShareIcon}
          />
        )}
        {recess?.visited === true ? (
          <div className='mt-auto'>
            <small>Visitado</small>
          </div>
        ) : (
          recess?.hasQR && (
            <div className='mt-auto'>
              <button
                onClick={event => {
                  event.stopPropagation();
                  openModal?.();
                }}
                style={{ width: "max-content" }}
                className='secondary secondary--magenta px-2 py-1'>
                <small>Registrar Visita</small>
              </button>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default CardWay;
