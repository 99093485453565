import { gql, useQuery } from "@apollo/client";
import { useMemo, useState } from "react";
import Propuesta from "typedef/Propuesta";
import { useHistory } from "react-router";
/**
 * @param {string} id
 * */
// export type usePropuestaSimpleReturnType = [Array<PropuestaSimple>, boolean];

const usePropuestaSimple = (id?: string) => {
  const history = useHistory();
  const [propuesta, setPropuesta] = useState<Partial<Propuesta>>({
    multimedia: []
  });
  const { loading } = useQuery(query, {
    fetchPolicy: "no-cache",
    onCompleted: ({ proposal_detail }) => {
      if (proposal_detail) {
        setPropuesta(proposal_detail);
      } else {
        alert("Parece que la Propuesta no existe.");
      }
    },
    onError: error => {
      console.log(
        "Parece que tuvimos un problema al cargar los datos de la Propuesta.",
        error
      );
      alert("Parece que no pudimos cargar la Propuesta.");
      history.goBack();
    },
    variables: {
      id: id?.toString()
    }
  });
  return useMemo(
    () => ({
      propuesta,
      loading
    }),
    [loading, propuesta]
  );
};
const query = gql`
  query($id: String!) {
    proposal_detail(id: $id) {
      id
      user {
        id
        first_name
        last_name
      }
      annoucement {
        id
        title
        state
        annoucement_type
      }
      about
      description
      votes
      multimedia {
        path
        type
      }
    }
  }
`;
export default usePropuestaSimple;
