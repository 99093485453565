import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CityHall from "screens/CityHall";
import CircuitRecess from "screens/CircuitRecess";
import Home from "screens/Home";
import MultipleRecess from "screens/MultipleRecess";
import SimpleRecess from "screens/SimpleRecess";
import Header from "components/Header";
import Footer from "components/Footer";
import Login from "screens/Login";
import Register from "screens/Register";
import RegisterCredentials from "screens/RegisterCredentials";
import RegisterProfile from "screens/RegisterProfile";
import Recesses from "screens/Recesses";
import Agenda from "screens/Agenda";
import Benefits from "screens/Benefits";
import CityHalls from "screens/CityHalls";
import FAQ from "screens/FAQ";
import TermsAndConditions from "screens/TermsAndConditions";
import SafeDestinations from "screens/SafeDestinations";
import Benefit from "screens/Benefit";
import UserEditProfile from "screens/UserEditProfile";
import UserProfile from "screens/UserProfile";
import PasswordRecoveryRequest from "../screens/PasswordRecoveryRequest";
import PasswordRecoveryCode from "../screens/PasswordRecoveryCode";
import RegisterSuccess from "screens/RegisterSuccess";
import MyBenefits from "../screens/MyBenefits/index";
import Ticket from "../screens/Ticket";
import MyTickets from "../screens/MyTickets/index";
import RecreoParaVos from "../screens/RecessForYou/index";
import Favs from "screens/Favs";
import NotificationsList from "screens/NotificationsList";
import ContactForm from "screens/ContactForm";
import Convocatorias from "screens/Convocatorias";
import ConvoVotacion from "screens/Convocatorias/Votacion";
import ConvoFinalizadas from "screens/Convocatorias/Finalizadas/index";
import Propuesta from "screens/Convocatorias/Propuesta/porpuesta";
import ConvoAbierta from "screens/Convocatorias/Abiertas";
import ConvoAbiertaDetail from "screens/Convocatorias/ConvoDetail";
import NotificationsSettings from "screens/NotificationsSettings/index";
import Survays from "screens/Survays/index";
import SurvayBenefit from "screens/SurvayBenefit";
import SurvayRecess from "screens/SurvayRecess";
import ContactForrm from "screens/ContactForm/index";
import ReCredit from "screens/ReCredit/index";
import ReCreditContact from "screens/ReCreditContact";
import MySchedules from "screens/MySchedule";
import WaysRoute from "screens/WaysRoute";
import Landing from "screens/Landing/index";
import Paradores from "screens/Paradores/index";
import AgendaCultural from "screens/AgendaCultural/index";
import NotFoundPage from "screens/404";
import DownloadWallpapers from "screens/Downloads/wallpapers";

export const PERSISTENCE_KEY = "NAVIGATION_STATE";

const RootNavigator: React.FC = () => {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path={"/"} component={Home} />
        <Route path={"/cityhall/:id"} component={CityHall} />
        <Route path={"/ways-route/:id"} component={WaysRoute} />z
        <Route path={"/recess/circuit/:id"} component={CircuitRecess} />
        <Route path={"/recess/simple/:id"} component={SimpleRecess} />
        <Route path={"/recess/multiple/:id"} component={MultipleRecess} />
        <Route path={"/recess/:id"} component={SimpleRecess} />
        <Route exact path={"/recreos-para-vos"} component={RecreoParaVos} />
        <Route path={"/recess"} component={Recesses} />
        <Route path={"/agenda"} component={Agenda} />
        <Route path={"/benefits"} component={Benefits} />
        <Route path={"/cityhalls"} component={CityHalls} />
        <Route path={"/FAQs"} component={FAQ} />
        <Route path={"/terms-and-condition"} component={TermsAndConditions} />
        <Route path={"/formar-parte"} component={ContactForrm} />
        <Route path={"/descargar"} component={Landing} />
        <Route path={"/paradores"} component={Paradores} />
        <Route path={"/agenda-cultural"} component={AgendaCultural} />
        <Route path={"/perfil"} component={UserProfile} />
        <Route path={"/destinos-cuidados"} component={SafeDestinations} />
        <Route path={"/benefit/:id/:map?"} component={Benefit} />
        <Route path={"/recredit"} component={ReCredit} />
        <Route path={"/contacto-recredito"} component={ReCreditContact} />
        <Route path={"/edit-profile"} component={UserEditProfile} />
        <Route path={"/register"} component={Register} />
        <Route path={"/edit-profile"} component={UserEditProfile} />
        <Route path={"/register"} component={Register} />
        <Route path={"ticket"} component={Ticket} />
        <Route path={"/survay-benefit"} component={SurvayBenefit} />
        <Route path={"/survay-recess"} component={SurvayRecess} />
        <Route path={"/Favs"} component={Favs} />
        <Route path={"/notifications"} component={NotificationsList} />
        <Route path={"/sumate"} component={ContactForm} />
        {/* <Route path={"/convocatorias"} component={Convocatorias} /> */}
        <Route path={"/convocatories-open"} component={ConvoAbierta} />
        <Route path={"/convo"} component={ConvoAbiertaDetail} />
        <Route path={"/propuesta"} component={Propuesta} />
        <Route path={"/convocatories-vote"} component={ConvoVotacion} />
        <Route path={"/convocatories-end"} component={ConvoFinalizadas} />
        <Route
          path={"/notifications-settings"}
          component={NotificationsSettings}
        />
        <Route
          exact
          path={"/register-credential"}
          component={RegisterCredentials}
        />
        <Route exact path={"/registro-perfil"} component={RegisterProfile} />
        <Route exact path={"/register-success"} component={RegisterSuccess} />
        <Route exact path={"/my-benefits"} component={MyBenefits} />
        <Route exact path={"/my-tickets"} component={MyTickets} />
        <Route exact path={"/my-schedules"} component={MySchedules} />
        <Route path={"/survays"} component={Survays} />
        <Route path={"/login"} component={Login} />
        <Route
          path={"/password-recovery-request"}
          component={PasswordRecoveryRequest}
        />
        <Route
          path={"/password-recovery-code"}
          component={PasswordRecoveryCode}
        />
        <Route
          path={"/memotest"}
          render={() => {
            window.location.replace("/descargas/MEMOTEST.pdf");
            return null;
          }}
        />
        <Route
          path={"/recreoparacolorear"}
          render={() => {
            window.location.replace("/descargas/PARA COLOREAR RECREO.pdf");
            return null;
          }}
        />
        <Route
          path={"/tateti"}
          render={() => {
            window.location.replace("/descargas/TATETI.pdf");
            return null;
          }}
        />
        <Route
          path={"/tuttifrutti"}
          render={() => {
            window.location.replace("/descargas/TUTTI FRUTTI.pdf");
            return null;
          }}
        />
        <Route path={"/fondosdepantalla"} component={DownloadWallpapers} />
        <Route component={NotFoundPage} />
      </Switch>
      <Footer />
    </Router>
  );
};

export default RootNavigator;
