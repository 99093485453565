import usePrompt from "hooks/usePrompt";
import { useCallback } from "react";
import BurnQR from "./BurnQR";

const useBurnQRModal = () => {
  const prompt = usePrompt();

  return useCallback(async (recessId?: string) => {
    prompt<any>(props => <BurnQR recessId={recessId} {...props} />)
      .then(response => console.log())
      .catch(error => console.error(error));
  }, []);
};

export default useBurnQRModal;
