import React from "react";
import classes from "./classes.module.scss";
import Icon from "assets/icons";
import { gql, useQuery } from "@apollo/client";
import { LIGHT_GRAY } from "assets/colors";
import QR from "components/QR";
import translateDayApply from "helpers/traslateApplyDays";
import BTNviewInTheMap from "./../../components/BTNViewInTheMap";
import useUser from "hooks/useUser";
import useLangContext from "hooks/useLangContext";

export type BenefitQRProps = {
  benefitId: string;
  onClose: (param: any) => any;
  onClickMap?: () => void;
};

const BenefitQR: React.FC<BenefitQRProps> = ({
  benefitId,
  onClose,
  onClickMap
}) => {
  const { loading, data } = useQuery(getQRQuery, {
    variables: { id: benefitId },
    fetchPolicy: "no-cache"
  });

  const [user] = useUser();
  const applyDay = translateDayApply(data?.benefit_detail?.apply_days);
  const { lang } = useLangContext();

  return (
    <div className={`${classes.container} shadow `}>
      <div className={`${classes.modal} p-4 rounded align-items-center`}>
        <div className='w-100 d-flex'>
          <button onClick={onClose} className='p-0 h-auto align-top d-flex'>
            <Icon
              className='d-flex'
              custom='Cross'
              fill={LIGHT_GRAY}
              size={14}
            />
          </button>
        </div>
        <div className='w-100 my-4'>
          <h6 className='text-secondary text-center text-uppercase font-weight-bold mt-2'>
            {data?.benefit_detail?.commerce.name}
          </h6>
          <h6 className='text-secondary text-center font-weight-bold'>
            {data?.benefit_detail?.name}
          </h6>
        </div>
        <div className='flex-fill d-flex align-items-center justify-content-center'>
          {loading ? (
            <div className='spinner-border' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            <div>
              {data?.benefit_detail?.code_qr &&
                data?.benefit_detail?.code_qr !== "" && (
                  <QR value={data.benefit_detail.code_qr + ":" + user?.id} />
                )}
            </div>
          )}
        </div>
        <div className='w-100 mb-2 text-center'>
          <br />
          {!loading &&
          data?.benefit_detail?.code_qr &&
          data?.benefit_detail?.code_qr !== "" ? (
            <>
              <small className='text-muted'>{lang.MyBenefits.code}</small>
              <br />
              <span className='text-primary'>
                {data?.benefit_detail?.code_qr.slice(
                  0,
                  data?.benefit_detail?.code_qr.indexOf(":")
                )}
              </span>
              <br />
              <small className='text-muted'>{lang.MyBenefits.currency}</small>
              <br />
              <div className='text-primary'>
                {applyDay?.map((day, i, days) => {
                  return (
                    <span className='text-capitalize'>
                      {/* logica para que muestre los dias en español y se agrege un guion despues de cada palabra (menos en la ultima)*/}
                      {`${day !== undefined ? day : ""} ${
                        day !== undefined &&
                        i !== days.length - 1 &&
                        days[i + 1] != undefined
                          ? "-"
                          : ""
                      }`}{" "}
                    </span>
                  );
                })}
              </div>
            </>
          ) : (
            <span className='text-primary'> No hay códigos disponibles</span>
          )}

          <div className='d-flex justify-content-center mt-4' onClick={onClose}>
            <BTNviewInTheMap goToMap={() => onClickMap?.()} />
          </div>
        </div>
      </div>
    </div>
  );
};

const getQRQuery = gql`
  query($id: String!) {
    benefit_detail(id: $id) {
      code_qr
      apply_days
      name
      commerce {
        name
      }
    }
  }
`;

export default BenefitQR;
