import * as React from "react";

function SvgBell(props) {
  return (
    <svg
      width={27}
      height={30}
      viewBox='0 0 20 24'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fill='currentColor'
        d='M16.8 13.416V9.6a7.2 7.2 0 00-6-7.092V1.2a1.2 1.2 0 10-2.4 0v1.308a7.2 7.2 0 00-6 7.092v3.816A3.6 3.6 0 000 16.8v2.4a1.2 1.2 0 001.2 1.2h3.768a4.8 4.8 0 009.264 0H18a1.2 1.2 0 001.2-1.2v-2.4a3.6 3.6 0 00-2.4-3.384zM4.8 9.6a4.8 4.8 0 119.6 0v3.6H4.8V9.6zm4.8 12a2.4 2.4 0 01-2.064-1.2h4.128A2.4 2.4 0 019.6 21.6zm7.2-3.6H2.4v-1.2a1.2 1.2 0 011.2-1.2h12a1.2 1.2 0 011.2 1.2V18z'
      />
    </svg>
  );
}

export default SvgBell;
