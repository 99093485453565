import React, { useEffect } from "react";
import { useMemo } from "react";
import classes from "./style/index.module.scss";
import "dayjs/locale/es";
import CarruselHero from "./../CarruselHero/CarruselHero";
import getType from "helpers/getType";
import useSocialShare from "hooks/useSocialShare";
import useFaved from "hooks/useFaved";
import FooterHero from "./FooterHero";
import TopTitleHero from "./TopTitleHero";
import CenterHero from "./CenterHero";

export interface TextualHeroProps extends React.HTMLProps<HTMLDivElement> {
  children: any;
  sizing?: string;
  loadingOnSaveBenefit?: boolean;
  onSaveBenefit?: () => void;
  onUpdateShedule?: () => void;
  isScheduleActive?: boolean;
  loadingShedule?: boolean;
}

const TextualHero = React.forwardRef<HTMLDivElement, TextualHeroProps>(
  ({
    children,
    sizing,
    onSaveBenefit,
    loadingOnSaveBenefit,
    onUpdateShedule,
    isScheduleActive,
    loadingShedule
  }) => {
    const [faved, fav] = useFaved(
      children.id,
      getType(children),
      children?.is_favorite
    );

    const useShareSocial = useSocialShare(
      children.id,
      getType(children),
      children
    );
    return useMemo(
      () => (
        <div className='container-fluid bg-white p-0 overflow-hidden'>
          <div className='row'>
            <CarruselHero
              uris={children.images}
              videos={children.videos}
              className={sizing}
            />
            <div
              className={`col-12 col-md-12 col-lg-6 col-xl-6 bg-white ${classes.textSide}`}>
              <div className='flex-wrap d-flex justify-content-between h-100 container'>
                <div className={classes.top}>
                  <TopTitleHero children={children} />
                </div>
                {/*Distancia y ubicacion con boton*/}
                <div className='bottom d-flex flex-wrap justify-content-between w-100'>
                  <CenterHero
                    children={children}
                    onSaveBenefit={onSaveBenefit}
                    loadingOnSaveBenefit={loadingOnSaveBenefit}
                  />
                  <div className={classes.separador}></div>
                  <FooterHero
                    onUpdateShedule={onUpdateShedule}
                    isScheduleActive={isScheduleActive}
                    loadingShedule={loadingShedule}
                    isFavorite={faved}
                    onFav={fav}
                    onShare={useShareSocial}
                    stars={children.score}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
      [children, faved, onUpdateShedule]
    );
  }
);
export default TextualHero;
