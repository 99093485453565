import { useMemo } from "react";

const useSpotifyLink = (url: string | undefined) => {
  const spotifyUrl = useMemo(() => {
    if (url && url.includes("spotify")) {
      return url.split("com/").join("com/embed/");
    }
  }, [url]);

  return spotifyUrl;
};

export default useSpotifyLink;
