import * as React from "react";

function SvgNavigate(props) {
  return (
    <svg width={15} height={15} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M14.092.292A1.029 1.029 0 0012.954.09L.608 5.61a1.028 1.028 0 00.175 1.937l4.914 1.21L7.24 13.68c.13.413.504.7.936.72h.048c.415 0 .79-.248.95-.63L14.32 1.426a1.028 1.028 0 00-.227-1.135zm-5.871 13.08L6.506 7.885 1.02 6.539l12.346-5.52L8.221 13.37z' />
    </svg>
  );
}

export default SvgNavigate;
