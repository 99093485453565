import "./style.scss";

const DaysList = daysList => {
  const daysSpanish = [
    "lunes",
    "martes",
    "miercoles",
    "jueves",
    "viernes",
    "sabado",
    "domingo"
  ];

  const apply_days = [];

  daysList?.days?.map((item, i) => {
    switch (item) {
      case "sunday":
        apply_days.push("domingo");
        break;
      case "monday":
        apply_days.push("lunes");
        break;
      case "tuesday":
        apply_days.push("martes");
        break;
      case "wednesday":
        apply_days.push("miercoles");
        break;
      case "thursday":
        apply_days.push("jueves");
        break;
      case "friday":
        apply_days.push("viernes");
        break;
      case "saturday":
        apply_days.push("sabado");
        break;
      default:
        break;
    }
  });

  return (
    <div className='days-list'>
      {daysSpanish?.map((item, i) => {
        return (
          <div
            key={i}
            className={`day opacity-${
              apply_days.indexOf(item) !== -1 ? "1" : "0"
            }`}>
            <p className='text-uppercase'>{item.charAt(0)}</p>
          </div>
        );
      })}
    </div>
  );
};

export default DaysList;
