import { gql, useQuery } from "@apollo/client";
import { parseNotification } from "helpers";
import { useCallback, useMemo } from "react";
import { useState } from "react";
import { Alert } from "react-native";

/**
 * Hook to get a list of the user's notifications
 * with a limit set by the server
 * @param {string | undefined} selectedCategoryId
 * @returns {[Array<RecreoNotification>, boolean, () => void]}
 */
const useNotifications = selectedCategoryId => {
  /**@type {[Array<RecreoNotification>, import('react').Dispatch<import('react').SetStateAction<any[]>>]} */
  const [notifications, setNotifications] = useState([]);
  const filteredNotifications = useMemo(
    () =>
      notifications.filter(n => {
        if (selectedCategoryId?.toString() === "-1") {
          return n.data.categories === undefined;
        } else if (selectedCategoryId) {
          return n.data.categories?.includes(selectedCategoryId.toString());
        } else {
          return true;
        }
      }),
    [selectedCategoryId, notifications]
  );

  const onCompleted = useCallback(async ({ notifications_list: { data } }) => {
    try {
      data.map(unparsedNotification => parseNotification(unparsedNotification));
      setNotifications(data);
    } catch (error) {
      Alert.alert(
        "Tuvimos un problema",
        "Las notificaciones no pudieron ser procesadas correctamente."
      );
    }
  }, []);

  const onError = useCallback(error => {
    Alert.alert(
      "Tuvimos un problema",
      "No pudimos recargar las notificaciones."
    );
    console.error(
      `Tuvimos un problema recargando las notificaciones: ${JSON.stringify(
        error
      )}`
    );
  }, []);

  const { loading, refetch: fetchNotifications } = useQuery(getNotifications, {
    fetchPolicy: "no-cache",
    onCompleted,
    onError
  });

  const refetch = useCallback(async () => {
    try {
      const { data } = await fetchNotifications();
      onCompleted(data);
    } catch (error) {
      onError(error);
    }
  }, []);

  return [filteredNotifications, loading, refetch];
};

const getNotifications = gql`
  {
    notifications_list {
      data {
        id
        name
        description
        created_at
        data
        read
      }
    }
  }
`;

const getCategory = gql`
  query getCategory($id: String!) {
    recess_detail(id: $id) {
      categories {
        id
        icon {
          uri
        }
      }
    }
  }
`;

export default useNotifications;
