import useCityHalls from "./useCityHalls";
import useLocalities from "./useLocalities";
import countryList from "./countries";
import states from "./states";
import classes from "../Login/style/index.module.scss";
import useForm from "./useForm";
import useTags from "./useTags";
import useScrollToTop from "hooks/useScrollToTop";
import seleccionarTodo from "helpers/seleccionarTodo";
import validate from "./validate";
import useLangContext from "hooks/useLangContext";

const UserEditProfile = () => {
  const form = useForm();
  const { lang } = useLangContext();
  const [tags, loadingTags] = useTags();
  const [cityHalls, loadingCityHalls] = useCityHalls();
  const [localities, loadingLocalities] = useLocalities(form.user.city_hall_id);

  useScrollToTop();

  let orderStates = states.sort((a, b) => a.value.localeCompare(b.value));

  return (
    <div className='container-fluid bg-white profileRegister'>
      <div className='container d-flex justify-content-center align-items-center'>
        <div className={classes.profileRegister}>
          <h5 className='text-secondary text-center bold'>Editar mis datos</h5>

          <form onSubmit={form.submit} className='container'>
            <div className='d-flex justify-content-between flex-wrap'>
              <div className='personal-data col-lg-5 no-gutters flex-lg-nowrap d-flex flex-column justify-content-between'>
                <h5 className={classes.formTitle}>Datos Personales</h5>
                <label>
                  <input
                    type='text'
                    required
                    name='first_name'
                    defaultValue={form.user.first_name}
                    onBlur={form.set("first_name")}
                  />
                  <span>Nombre</span>
                </label>
                <label>
                  <input
                    type='text'
                    required
                    name='last_name'
                    defaultValue={form.user.last_name}
                    placeholder='Apellido'
                    onBlur={form.set("last_name")}
                  />
                  <span>Apellido</span>
                </label>
                <div className='row flex-nowrap m-0'>
                  <input
                    required
                    type='phone'
                    name='phone_prefix'
                    className='prefix'
                    placeholder='Prefijo'
                    defaultValue={form.user.phone_prefix}
                    onBlur={form.set("phone_prefix")}
                  />
                  <input
                    required
                    type='phone'
                    minLength={6}
                    className='phone'
                    name='phone'
                    defaultValue={form.user.phone}
                    placeholder='Teléfono'
                    onBlur={form.set("phone")}
                  />
                </div>
                <label>
                  <input
                    required
                    minLength={6}
                    type='text'
                    name='document_number'
                    placeholder='DNI'
                    onChange={form.set("document_number")}
                    defaultValue={form.user.document_number}
                  />
                </label>
                <label>
                  Fecha de Nacimiento
                  <input
                    className='w-100'
                    required
                    type='date'
                    name='birth_day'
                    max={new Date().toISOString().split("T")[0]}
                    placeholder={lang.RegisterProfile.dateSelect}
                    onChange={form.set("birth_day")}
                    defaultValue={form.user.birth_day}
                  />
                </label>

                <label className='d-flex flex-column'>
                  <label>País</label>
                  <select
                    required
                    name='country'
                    placeholder='Seleccionar Pais'
                    value={form.user.country}
                    onChange={form.set("country")}>
                    <option value='Seleccionar País'>Seleccionar País</option>
                    {countryList.map(country => (
                      <option key={country.value} value={country.value}>
                        {country.label}
                      </option>
                    ))}
                  </select>
                </label>

                {form.user.country === "Argentina" && (
                  <label>
                    <label>Provincia</label> <br />
                    <select
                      required
                      name='state'
                      onChange={form.set("state")}
                      value={form.user.state}
                      placeholder='Seleccionar provincia'>
                      <option value={undefined}>Seleccionar provincia</option>
                      {orderStates.map(state => (
                        <option key={state.value} value={state.value}>
                          {state.label}
                        </option>
                      ))}
                    </select>
                  </label>
                )}

                {form.user.state === "Buenos Aires" &&
                  form.user.country === "Argentina" && (
                    <label>
                      <label>Municipio</label> <br />
                      <select
                        required
                        name='city_hall_id'
                        onChange={form.set("city_hall_id")}
                        placeholder='Seleccionar municipio'
                        value={form.user.city_hall_id}>
                        <option value={undefined}>Seleccionar municipio</option>
                        {cityHalls.map(cityHall => (
                          <option key={cityHall.id} value={cityHall.id}>
                            {cityHall.name}
                          </option>
                        ))}
                      </select>
                    </label>
                  )}

                {form.user.state === "Buenos Aires" &&
                  form.user.country === "Argentina" && (
                    <label>
                      <label>Localidad</label>
                      <br />

                      <select
                        required
                        name='locality_id'
                        onChange={form.set("locality_id")}
                        placeholder='Seleccionar Localidad'
                        value={form.user.locality_id}>
                        <option value={undefined}>Seleccionar localidad</option>
                        {!loadingLocalities &&
                          localities.map(locality => (
                            <option key={locality.id} value={locality.id}>
                              {locality.name}
                            </option>
                          ))}
                      </select>
                    </label>
                  )}

                <h5 className={classes.formTitle}>Grupo Familiar</h5>
                <label className={classes.numberData}>
                  <p>¿Cuántos son en tu grupo familiar?</p>
                  <input
                    required
                    onFocus={seleccionarTodo}
                    min={0}
                    type='number'
                    name='total_family_group'
                    defaultValue={form.user.total_family_group}
                    onBlur={form.set("total_family_group")}
                  />
                </label>
                <label className={classes.numberData}>
                  <p>¿Hijos?</p>
                  <input
                    onFocus={seleccionarTodo}
                    required
                    type='number'
                    min={0}
                    name='total_children'
                    defaultValue={form.user.total_children}
                    onChange={form.set("total_children")}
                  />
                </label>
              </div>
              <div className='preferences col-lg-5 col-md-5 col-12'>
                <h5 className={classes.formTitle}>Gustos y Preferencias</h5>
                <div className='tags'>
                  {tags.map(tag => (
                    <div key={tag.title} className='mb-5'>
                      <h6 className='tag-title my-4'>{tag.title}</h6>
                      {tag.data.map(item => (
                        <div
                          key={item.id}
                          className='d-flex align-items-center position-relative mb-2'>
                          <input
                            type='checkbox'
                            className='flex-0 w-auto my-auto mr-4'
                            id={item.id}
                            value={item.id}
                            defaultChecked={form.user.tag_ids?.includes(
                              item.id
                            )}
                            onChange={form.set("tag_ids")}
                          />
                          <label className='img' />

                          <label className='flex-fill' htmlFor={item.id}>
                            {item.tag}
                          </label>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <button
              type='submit'
              disabled={validate(form.user) ? false : true}
              className='mt-5 mx-auto secondary secondary--magenta'>
              {lang.Elements.save}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserEditProfile;
