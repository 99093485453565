import { gql, useQuery } from "@apollo/client";
import { useMemo, useState } from "react";

const UseTermsAndConditions = () => {
  const [text, setText] = useState("");
  const { loading } = useQuery(query, {
    onCompleted: ({ term_and_condition: { text: textgetting } }) => {
      setText(textgetting);
    },
    onError: error => {
      console.error(
        "Parece que tuvimos un problema al cargar los terminos y condiciones",
        error
      );
    }
  });

  return useMemo(
    () => ({
      text,
      loading
    }),
    [text, loading]
  );
};

const query = gql`
  {
    term_and_condition {
      text
    }
  }
`;

export default UseTermsAndConditions;
