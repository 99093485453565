import useOnClick from "./useOnClick";
import Recess from "typedef/Recess";
import dayjs from "dayjs";
import { formatExternalAssetUrl } from "helpers";
import { VIOLET, MAGENTA } from "assets/colors";
import React from "react";
import Stars from "components/Stars/Stars";
import Icon from "assets/icons";
import useFaved from "hooks/useFaved";
import getType from "helpers/getType";
import "dayjs/locale/es";

export type RecessBoxProps = {
  recess: Recess;
};

const RecessBox: React.FC<RecessBoxProps> = ({ recess }) => {
  const onClick = useOnClick(recess);
  const [faved, fav] = useFaved(recess.id, getType(recess), recess.is_favorite);

  return (
    <div key={recess.id} className={`col-11 col-md-3 individual`}>
      <div
        className='bg-white overflow-hidden p-0 clickable position-relative rounded'
        onClick={onClick}>
        <div className='ratio-3x2 container'>
          <div className='ratio-3x2 item'>
            <div
              className='w-100 h-100 d-flex flex-column justify-content-end p-2'
              style={{
                background:
                  "linear-gradient(360deg, rgba(0, 0, 0, 0.75) 27.78%, rgba(0, 0, 0, 0) 100%), url(" +
                  `${formatExternalAssetUrl(recess.icon)}` +
                  ") center / cover no-repeat "
              }}>
              <div className='d-flex flex-row'>
                <img
                  width={20}
                  height={20}
                  style={{ filter: "invert(1)" }}
                  src={formatExternalAssetUrl(recess.categories?.[0]?.icon)}
                />
                <h6 className='text-white ml-1 my-0 font-weight-500'>
                  {recess.categories?.[0]?.name}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ maxHeight: 150, minHeight: 150 }}
          className='d-flex flex-column p-3'>
          <h6 className='font-weight-normal text-capitalize small text-muted'>
            {recess.date_from && recess.date_to
              ? `${dayjs(recess.date_from)
                  .locale("es")
                  .format("DD  MMM")}  ${dayjs(recess.date_to)
                  .locale("es")
                  .format("DD  MMM")}`
              : "Todo el año"}
          </h6>

          <div className='flex-fill overflow-hidden'>
            <h6 className='m-0 flex-fill my-1 text-uppercase'>
              {recess.title}
            </h6>
            {recess.city_hall?.name ? (
              <p className='cityHall font-weight-medium'>
                {recess.city_hall?.name}
              </p>
            ) : null}
          </div>

          <div className='d-flex flex-row justify-content-between alig-items-center'></div>
          <div className='d-flex justify-content-between align-items-center'>
            <Stars
              value={recess.score}
              color={VIOLET}
              disabled
              size={15}
              spacing={1}
            />
            <button
              style={{ all: "unset" }}
              onClick={e => {
                e.stopPropagation();
                fav();
              }}>
              <Icon
                custom='Heart'
                style={{ alignSelf: "center" }}
                size={20}
                stroke={MAGENTA}
                fill={faved ? MAGENTA : "transparent"}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecessBox;
