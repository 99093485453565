import { useState } from "react";
import { Link } from "react-router-dom";
import GmailLogo from "assets/images/gmail.svg";
import Eye from "assets/images/eye.svg";
import CrossedEye from "assets/images/crossed-eye.svg";
import classes from "./style/index.module.scss";
import useLoginWithEmail from "./useLoginWithEmail";
import useLoginWithGoogle from "./useLoginWithGoogle";
import useRedirect from "./useRedirect";
import useLangContext from "hooks/useLangContext";

const Login = () => {
  useRedirect();
  const { lang } = useLangContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [
    loginWithGoogle,
    loadingGoogleLogin,
    googleLoginError
  ] = useLoginWithGoogle();

  const [
    loginWithEmail,
    loadingEmailSubmit,
    emailLoginError
  ] = useLoginWithEmail();

  const handleShowPass = () => {
    setShowPass(!showPass);
  };

  return (
    <div className='container-fluid bg-white'>
      <div className='container d-flex justify-content-center align-items-center px-0'>
        <div className={classes.mainlogin}>
          <h5 className='text-secondary text-center bold'>
            {lang.Login.title}
          </h5>

          <div className={classes.buttonGroup}>
            <button onClick={loginWithGoogle} className={classes.red}>
              <img src={GmailLogo} />
              {lang.Login.loginGmail}
            </button>
          </div>

          {googleLoginError && (
            <div className='alert alert-danger'>{googleLoginError}</div>
          )}
          {emailLoginError && (
            <div className='alert alert-danger'>{emailLoginError}</div>
          )}
          <form onSubmit={loginWithEmail} className={classes.form}>
            <div className='email'>
              <input
                required
                type='email'
                name='email'
                className='email'
                placeholder={lang.CreateAccount.registerWithEmailPlaceholder}
                onBlur={({ currentTarget }) => setEmail(currentTarget.value)}
              />
            </div>
            <div className='password'>
              <label>
                <img
                  onClick={handleShowPass}
                  src={showPass ? CrossedEye : Eye}
                />

                <input
                  required
                  type={showPass ? "text" : "password"}
                  className='email'
                  name='password'
                  placeholder={lang.Login.setPass}
                  onBlur={({ currentTarget }) =>
                    setPassword(currentTarget.value)
                  }
                />
              </label>
              <div className={classes.passRecovery}>
                {lang.Login.noRemind}
                <Link
                  className='text-primary font-weight-bold'
                  to='/password-recovery-request'>
                  {lang.Login.forgotPassword}
                </Link>
              </div>
            </div>

            <button
              disabled={loadingEmailSubmit || loadingGoogleLogin}
              className='secondary secondary--magenta'
              type='submit'>
              {(loadingEmailSubmit || loadingGoogleLogin) && (
                <span
                  className='spinner-border spinner-border-sm mr-2'
                  role='status'
                  aria-hidden='true'
                />
              )}
              {lang.Login.loginEmail}
            </button>
          </form>

          <div className={classes.registerCta}>
            <p>
              {lang.Login.notLogin}
              <Link className='text magenta bold' to='/register'>
                {lang.Login.createAccount}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
