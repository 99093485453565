import { useMemo, useState } from "react";
import { gql, useQuery } from "@apollo/client";
const usePropuestasVotadas = (convocatoria_id: string) => {
  const [propuestas, setPropuestas] = useState([]);
  const { loading } = useQuery(query, {
    fetchPolicy: "no-cache",
    onCompleted: proposal_voted_by_user => {
      setPropuestas(proposal_voted_by_user.proposal_voted_by_user);
    },
    onError: error => {
      console.log(
        "Parece que tuvimos un problema cargando las Propuestas Votadas",
        error
      );
    },
    variables: {
      annoucement_id: parseInt(convocatoria_id)
    }
  });

  return useMemo(
    () => ({
      propuestas,
      loading
    }),
    [loading, propuestas]
  );
};
const query = gql`
  query($annoucement_id: Int!) {
    proposal_voted_by_user(annoucement_id: $annoucement_id) {
      id
      annoucement {
        title
      }
      about
      description
      votes
      multimedia {
        path
        type
      }
      approved_by_admin
    }
  }
`;
export default usePropuestasVotadas;
