import { gql, useMutation } from "@apollo/client";
import { useMemo } from "react";
import { useHistory } from "react-router";

const SendMail = () => {
  const [saveForm, { loading: loadingAction }] = useMutation(sendForm, {
    onCompleted: ({ data }) => {
      console.log(data);
    }
  });
  const history = useHistory();

  async function save(formData: any) {
    try {
      //se agrega el municipio y la actividad al final del mensaje (ya que no estaba contemplado desde el backend)
      await saveForm({
        variables: {
          name: formData.name,
          lastname: formData.lastname,
          email: formData.email,
          phone: formData.phone,
          web: formData.web,
          municipio: formData.municipio,
          actividad: formData.actividad,
          categoria: formData.categoria,
          facebook_profile: formData.facebook_profile,
          instagram_profile: formData.instagram_profile,
          detail: formData.detail
        }
      });
      alert(
        "Tu solicitud se ha enviado con éxito. En breve nos contactaremos."
      );
      history.push("/");
    } catch (error) {
      console.error(
        `Parece que tuvimos un problema agregando la informacion., error:${error}`,
        formData
      );
      alert(
        "Parece que tuvimos un problema agregando la informacion. intentelo mas tarde"
      );
    }
  }

  return useMemo(
    () => ({
      save,
      loadingAction
    }),
    [save, loadingAction]
  );
};
const sendForm = gql`
  mutation addBePartOfRecreo(
    $name: String!
    $lastname: String!
    $email: String!
    $phone: String!
    $web: String
    $municipio: String
    $actividad: String
    $categoria: String
    $instagram_profile: String
    $facebook_profile: String
    $detail: String!
  ) {
    be_part_of_recreo(
      name: $name
      lastname: $lastname
      email: $email
      municipio: $municipio
      actividad: $actividad
      categoria: $categoria
      phone: $phone
      web: $web
      facebook_profile: $facebook_profile
      instagram_profile: $instagram_profile
      detail: $detail
    ) {
      message
    }
  }
`;

export default SendMail;
