export default {
  title: "You are currently in",
  level: "Level",
  virtual: "virtual",
  curious: "Ciropis",
  explorer: "Explorer",
  expert: "Expert",
  points: "points",

  descriptVirtual:
    "You like to browse the app but are only encouraged to take a Tour once in a while.",
  descriptCurius:
    "You are starting to like more and more to travel and see in person what you discovered in networks",
  descriptExplorer:
    "You have already visited and experienced a lot of places and events to recommend.",
  desciptExpert: "You became a specialist guide to show the Province."
};
