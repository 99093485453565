const states = [
  { label: "Misiones", value: "Misiones" },
  { label: "San Luis", value: "San Luis" },
  { label: "San Juan", value: "San Juan" },
  { label: "Entre Ríos", value: "Entre Ríos" },
  { label: "Santa Cruz", value: "Santa Cruz" },
  { label: "Río Negro", value: "Río Negro" },
  { label: "Chubut", value: "Chubut" },
  { label: "Córdoba", value: "Córdoba" },
  { label: "Mendoza", value: "Mendoza" },
  { label: "La Rioja", value: "La Rioja" },
  { label: "Catamarca", value: "Catamarca" },
  { label: "La Pampa", value: "La Pampa" },
  { label: "Santiago del Estero", value: "Santiago del Estero" },
  { label: "Corrientes", value: "Corrientes" },
  { label: "Santa Fe", value: "Santa Fe" },
  { label: "Tucumán", value: "Tucumán" },
  { label: "Neuquén", value: "Neuquén" },
  { label: "Salta", value: "Salta" },
  { label: "Chaco", value: "Chaco" },
  { label: "Formosa", value: "Formosa" },
  { label: "Jujuy", value: "Jujuy" },
  {
    label: "Ciudad Autónoma de Buenos Aires",
    value: "Ciudad Autónoma de Buenos Aires"
  },
  { label: "Buenos Aires", value: "Buenos Aires" },
  {
    label: "Tierra del Fuego, Antártida e Islas del Atlántico Sur",
    value: "Tierra del Fuego, Antártida e Islas del Atlántico Sur"
  }
];

export default states;
