import { useReducer } from "react";
import reducer from "./reducer";
import Stars from "components/Stars/Stars";
import CheckBoxGroup from "components/CheckBoxGroup";
import Range from "components/Range/Range";
import useSaveResponse from "./useSaveResponse";
import validate from "./validate";
import BTNBack from "components/BTNBack";
import { useParams } from "react-router-dom";

const SurvayBenefit = () => {
  const [data, dispatch] = useReducer(reducer, {});
  const param = useParams<{ id: string }>();
  const saveResponse = useSaveResponse({ ...data, id: param.id });

  return (
    <div className='container-fluid' style={{ marginBottom: 130 }}>
      <BTNBack />
      <h3 className='text-center text-secondary font-weight-bold my-5'>
        Completar Encuesta
      </h3>

      <div className='bg-white p-5 my-5 rounded container mr-auto ml-auto'>
        <h4 className='text-center mb-5'>
          <b>Beneficio</b>
        </h4>
        <hr />
        <div className='my-5'>
          <div className='mb-3'>
            <b>¿Pudiste utilizar el beneficio?</b>
          </div>
          <CheckBoxGroup
            selected={data.situation}
            onSelect={situation =>
              dispatch({
                type: "setSituation",
                payload: situation
              })
            }
            checkBoxes={[
              { label: "Si", value: 1 },
              { label: "No", value: 0 }
            ]}
          />
        </div>
        <div className='my-5'>
          <div className='mb-3'>
            <b>¿Cómo calificarías este beneficio?</b>
          </div>
          <Stars
            size={35}
            value={data.qualification}
            onChange={qualification =>
              dispatch({
                type: "setQualification",
                payload: qualification
              })
            }
          />
        </div>
        <div className='my-5'>
          <div className='mb-3'>
            <b>¿El lugar estaba identificado de alguna manera como ReCreo?</b>
          </div>
          <CheckBoxGroup
            selected={data.is_recess}
            onSelect={is_recess =>
              dispatch({
                type: "setIsRecess",
                payload: is_recess
              })
            }
            checkBoxes={[
              { value: 1, label: "Si" },
              { value: 2, label: "No" },
              { value: 3, label: "No sabe, no contesta" }
            ]}
          />
        </div>

        <div className='my-5'>
          <div className='mb-3'>
            <b>
              ¿Qué tan posible es que recomiendes este beneficio a un familiar o
              amigo/a?
            </b>
          </div>
          <div
            style={{
              display: "block",
              backgroundColor: "transparent",
              marginBottom: 20
            }}
            className='my-3'>
            <div className='my-4'>
              <Range
                scale={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                value={data.recommendation}
                onChange={recommendation =>
                  dispatch({
                    type: "setRecommendation",
                    payload: recommendation
                  })
                }
              />
            </div>
          </div>
        </div>
        <hr />
        <div className='mt-4 '>
          <button
            type='button'
            className='d-flex m-auto secondary px-4 py-2 secondary--magenta'
            onClick={saveResponse}
            disabled={!validate(data)}>
            Enviar
          </button>
        </div>
      </div>
    </div>
  );
};

export default SurvayBenefit;
