import React, { useMemo, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import Convocatoria from "typedef/Convocatoria";

const useConvocatorias = (limit?: number) => {
  const [convocatorias, setConvocatorias] = useState<Array<Convocatoria>>([]);
  const [filterIndex, setFilterIndex] = useState<number>(0);
  const page = React.useRef(1);
  const [limitReached, setLimitReached] = useState(false);
  const { refetch, fetchMore, loading } = useQuery(query, {
    variables: {
      limit: limit || 10,
      page: 1,
      orderBy: filterIndex
    },
    onCompleted: ({ annoucements_in_voting_list: { data: convocatorias } }) => {
      setConvocatorias(convocatorias);
      if (convocatorias.length < 10) setLimitReached(true);
    },
    onError: error =>
      console.error(
        `Parece que tuvimos un problema cargando las convocatorias, ${JSON.stringify(
          error
        )}`
      )
  });

  function _refetch() {
    refetch({
      page: 1
    })
      .then(
        ({
          data: {
            annoucements_in_voting_list: { data: convocatorias }
          }
        }) => {
          setConvocatorias(convocatorias);
          page.current = 1;
          setLimitReached(false);
        }
      )
      .catch(error =>
        console.error("Tuvimos un problema recargando las convocatorias", error)
      );
    page.current = 1;
  }

  function _fetchMore() {
    if (limitReached) return;
    fetchMore({
      variables: {
        page: ++page.current
      }
    })
      .then(
        ({
          data: {
            annoucements_in_voting_list: { data: convocatorias, last_page }
          }
        }) => {
          convocatorias &&
            setConvocatorias(prev => [...prev, ...convocatorias]);
          if (parseInt(last_page) <= page.current || last_page == undefined) {
            setLimitReached(true);
          }
        }
      )
      .catch(error =>
        console.error("Tuvimos un problema al cargar más Convocatorias", error)
      );
  }

  return useMemo(
    () => ({
      convocatorias,
      loading,
      filterIndex,
      setFilterIndex,
      limitReached,
      refetch: _refetch,
      fetchMore: _fetchMore
    }),
    [convocatorias, loading, limitReached, refetch, fetchMore]
  );
};

const query = gql`
  query getAnnouncementInVoting($limit: Int, $page: Int, $orderBy: Int) {
    annoucements_in_voting_list(limit: $limit, page: $page, orderBy: $orderBy) {
      data {
        id
        title
        annoucement_type
        open_date
        close_date
        open_date_vote
        close_date_vote
        terms_and_conditions
        requirements
        description
        evaluation_metodology
        has_prize
        prize
        state
        city_hall {
          id
          name
        }
        items {
          id
          type
          title
        }
        image {
          uri
        }
      }
      per_page
      last_page
      current_page
      has_more_pages
    }
  }
`;

export default useConvocatorias;
