import * as React from "react";

function SvgBoat(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={30} height={32} {...props}>
      <path d='M3.47 19.728h6.408a2.16 2.16 0 002.16-2.16V6.883a2.159 2.159 0 00-4.01-1.108L1.62 16.46a2.16 2.16 0 001.85 3.269zM9.878 6.826v10.742H3.47L9.878 6.826z' />
      <path d='M29.297 22.385a2.159 2.159 0 00-1.944-1.217H15.639v-1.44h9.36a2.161 2.161 0 001.822-3.319L17.439 1.728a2.157 2.157 0 00-3.6-.087 1.074 1.074 0 00-.396.828v18.72h-10.8a2.16 2.16 0 00-1.728 3.456l4.32 5.76a2.16 2.16 0 001.728.864h15.84a2.16 2.16 0 001.699-.828l4.514-5.76a2.16 2.16 0 00.281-2.297zm-4.32-4.817h-9.36V3.017l9.36 14.551zm-2.16 11.52H6.977l-4.32-5.76h24.581l-4.421 5.76z' />
    </svg>
  );
}

export default SvgBoat;
