import { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import useAccessToken from "hooks/useAccessToken";

const useUserPoints = () => {
  const [points, setPoints] = useState(0);
  const accessToken = useAccessToken();
  useQuery(query, {
    skip: !accessToken,
    onCompleted: response => {
      setPoints(response?.me?.profile_app?.my_points);
    },
    onError: error => {
      console.error(
        `Tuvimos un error cargando los puntos. ${JSON.stringify(error)}`
      );
    }
  });

  return points;
};

const query = gql`
  query userPoints {
    me {
      id
      profile_app {
        my_points
      }
    }
  }
`;

export default useUserPoints;
