import { gql, useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import RegisterData from "typedef/RegisterData";

const useRegister = (registerData: RegisterData) => {
  const history = useHistory();

  return useMutation(query, {
    onCompleted: data => {
      history.push({
        pathname: "register-success",
        state: { withSocial: Boolean(registerData.provider) }
      });
    },
    onError: error => {
      alert("Parece que tuvimos un problema al intentar registrarte.");
      console.error(JSON.stringify(error), registerData);
    },
    variables: registerData
  });
};

const query = gql`
  mutation register(
    $email: String!
    $password: String
    $password_confirmation: String
    $first_name: String!
    $last_name: String!
    $birth_day: String!
    $city_hall_id: Int!
    $locality_id: Int!
    $country: String!
    $state: String!
    $phone: String!
    $document_number: String!
    $total_family_group: Int!
    $total_children: Int!
    $provider: String
    $social_token: String
    $tag_ids: [String]
  ) {
    register(
      email: $email
      password: $password
      password_confirmation: $password_confirmation
      first_name: $first_name
      last_name: $last_name
      document_type: dni
      document_number: $document_number
      birth_day: $birth_day
      terms_and_conditions: true
      city_hall_id: $city_hall_id
      locality_id: $locality_id
      country: $country
      state: $state
      phone: $phone
      total_family_group: $total_family_group
      total_children: $total_children
      provider: $provider
      social_token: $social_token
      activities_tags: []
      travelling_tags: []
      tag_ids: $tag_ids
    ) {
      id
    }
  }
`;

export default useRegister;
