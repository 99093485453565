import { useState, useMemo } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Alert } from "react-native";
import Recess from "typedef/Recess";
import { useHistory } from "react-router";
import useAccessToken from "hooks/useAccessToken";
import useLangContext from "hooks/useLangContext";

export interface AsyncRecess extends Partial<Recess> {
  loading: boolean;
  getTicket: object;
}

const useSimpleRecess = (id: string | null): AsyncRecess => {
  const history = useHistory();
  const [claimTicketMutation] = useMutation(getQuery);
  const [recess, setRecess] = useState<Recess | undefined>();
  const { lang } = useLangContext();
  const accessToken = useAccessToken();
  const { loading } = useQuery(query, {
    fetchPolicy: "no-cache",
    onCompleted: ({ recess_detail }) => {
      if (recess_detail) {
        setRecess(recess_detail);
      } else {
        console.error("Parece que no existe el recreo", recess_detail);
        alert("Parece que no existe el recreo que intenta ver.");
      }
    },
    onError: error => {
      if (id === null) {
        console.error(
          "Esta pantalla debería venir con un id para indicar el ReCreo que se busca"
        );
        alert("Parece que no ha indicado el ReCreo que desea ver.");
      }
      console.error(
        "Tuvimos un problema al cargar los datos del recreo",
        JSON.stringify(error)
      );
      history.goBack();
      setTimeout(() => {
        Alert.alert("Parece que no pudimos cargar el recreo");
      }, 1000);
    },
    variables: {
      id: id,
      lang: lang.current
    }
  });

  async function getTicket() {
    if (accessToken) {
      if (recess?.ticket_purchase_link) {
        window.open(recess.ticket_purchase_link);
      } else {
        try {
          const {
            data: { reclame_ticket }
          } = await claimTicketMutation({ variables: { recess_id: id } });
          alert(reclame_ticket);
        } catch (error) {
          console.error(
            `Paso algo al reservar la entrada: ${JSON.stringify(error)}`
          );
          Alert.alert(`Tuvimos un problema`);
        }
      }
    } else {
      alert(
        "Registrate o inicia sesión para poder agregarlo a tus Reservar esta entrada."
      );
    }
  }

  return useMemo(
    () => ({
      ...recess,
      getTicket,
      loading
    }),
    [recess, loading, getTicket]
  );
};

const query = gql`
  query getRecess($id: String!, $lang: String) {
    recess_detail(id: $id, lang: $lang) {
      id
      title
      address
      date_from
      date_to
      is_interest_point
      is_safety_pin
      spotify_link
      score
      longitude
      latitude
      description_text
      is_sustainability
      is_favorite
      is_multiple
      ticket_purchase_link
      is_accessibility
      icon {
        uri
      }
      is_preventive_measures
      is_gender_diversity
      legals
      is_free
      stock
      stock_used
      categories(lang: $lang) {
        id
        icon {
          uri
        }
        name
      }
      city_hall {
        name
        id
      }
      images {
        uri
      }
      videos {
        url
      }
      audio_guide {
        uri
      }
    }
  }
`;

const getQuery = gql`
  mutation claimTicket($recess_id: String!) {
    reclame_ticket(recess_id: $recess_id)
  }
`;

export default useSimpleRecess;
