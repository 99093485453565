import AccordionFAQ from "../../components/AccordionFAQ/AcordionFAQ";
import useFAQ from "./useFAQ";
import "./style.scss";
import useLangContext from "hooks/useLangContext";

const FAQ = () => {
  const [faqs] = useFAQ();
  const { lang } = useLangContext();

  return (
    <div className='FAQ'>
      <div className='container'>
        <h2 className='text-center font-weight-bold text-secondary my-5'>
          {lang.Navbar.header.top.frequentQuestions}
        </h2>
        <hr />
        <AccordionFAQ data={faqs} />
      </div>
    </div>
  );
};

export default FAQ;
