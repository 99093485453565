import classes from "./../classes.module.scss";
import { useState } from "react";
import Icon from "assets/icons";
import dayjs from "dayjs";
import { LIGHT_GRAY } from "assets/colors";
import AgendaSvg from "assets/images/blackAgenda.svg";

export type DaySelectorProps = {
  onClose: (param: any) => any;
  onClickDate: (date: any) => string;
  currentDate: any;
};

const DaySelector: React.FC<DaySelectorProps> = ({
  onClose,
  onClickDate,
  currentDate
}) => {
  const [selected, setSelected] = useState(currentDate);

  // selection via datepicker input

  function customDate(e: any) {
    setSelected(dayjs(e.currentTarget.value));
    onClickDate(dayjs(e.currentTarget.value));
    onClose("");
  }

  //day of the week picker

  function chooseThisDate(date: any) {
    setSelected(dayjs(date));
    onClickDate(dayjs(date));
    onClose("");
  }

  const date = [
    {
      date: dayjs(selected).subtract(3, "days").toDate()
    },
    {
      date: dayjs(selected).subtract(2, "days").toDate()
    },
    {
      date: dayjs(selected).subtract(1, "days").toDate()
    },
    {
      date: dayjs(selected)
    },
    {
      date: dayjs(selected).add(1, "days").toDate()
    },
    {
      date: dayjs(selected).add(2, "days").toDate()
    },
    {
      date: dayjs(selected).add(3, "days").toDate()
    }
  ];

  return (
    <div
      className={`${classes.datePicker} container p-2 rounded shadow align-items-center datePicker`}>
      <div className='w-100 d-flex'>
        <button onClick={onClose} className='p-0 h-auto align-top d-flex'>
          <Icon className='d-flex' custom='Cross' fill={LIGHT_GRAY} size={14} />
        </button>
      </div>

      <div className={`container bg-white p-0 `}>
        <div className='container'>
          <div className='container col-lg-10 col-10  d-flex justify-content-between align-items-center flex-nowrap'>
            {date.map((day, i) => {
              return (
                <div
                  key={i}
                  onClick={() => chooseThisDate(day.date)}
                  className={`${classes.buttonDay} ${
                    dayjs(day.date).format("YYYY-MM-DD") ===
                    dayjs(selected).format("YYYY-MM-DD")
                      ? classes.active
                      : ""
                  }`}>
                  <p className={`secondary secondary--purple`}>
                    {dayjs(day.date)
                      .locale("es")
                      .format("dd")
                      .slice(0, 1)
                      .toUpperCase()}
                  </p>
                  <p
                    className={`${classes.numericDate} ${
                      dayjs(day.date).format("YYYY-MM-DD") <
                      dayjs().format("YYYY-MM-DD")
                        ? classes.past
                        : ""
                    } `}>
                    {dayjs(day.date).format("DD")}
                  </p>
                </div>
              );
            })}
          </div>

          <button className='button primary m-auto d-flex position-relative'>
            <label className='date-picker placeholder'>
              <img src={AgendaSvg} />
              {selected
                ? dayjs(selected).locale("es").format("MMMM")
                : "Elegir fecha"}
            </label>

            <input
              className='d-block date-picker'
              type='date'
              onChangeCapture={e => customDate(e)}></input>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DaySelector;
