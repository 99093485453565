import * as React from "react";

function SvgLock(props) {
  return (
    <svg
      viewBox='0 0 26 32'
      xmlns='http://www.w3.org/2000/svg'
      width={26}
      height={32}
      {...props}>
      <path d='M23.883 11.595h-2.259V8.583a8.282 8.282 0 00-16.564 0v3.012H2.801a2.259 2.259 0 00-2.259 2.259v15.059a2.26 2.26 0 002.259 2.259h21.082a2.26 2.26 0 002.259-2.259V13.854a2.259 2.259 0 00-2.259-2.259zM7.319 8.584a6.024 6.024 0 1112.048 0v3.012H7.32V8.584zm16.564 20.329H2.801V13.854h21.082v15.059z' />
      <path d='M13.719 25.148a3.388 3.388 0 10-3.388-3.388 3.395 3.395 0 003.388 3.388zm0-4.517a1.129 1.129 0 110 2.258 1.129 1.129 0 010-2.258z' />
    </svg>
  );
}

export default SvgLock;
