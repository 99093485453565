import { gql, useMutation } from "@apollo/client";
import getEnvVariable from "env";
import { ACCESS_TOKEN_KEY } from "hooks/useAccessToken";
import useFirebaseEvent from "hooks/useFirebaseEvent";
import React, { useState } from "react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

export type EmailForm = HTMLFormElement & {
  email: HTMLInputElement;
  password: HTMLInputElement;
};

/**
 * Hook to login with custom email and password
 */
const useLoginWithEmail = (): [
  (event: React.FormEvent<EmailForm>) => void,
  boolean,
  string
] => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [error, setError] = useState(``);
  const [mutate, { loading }] = useMutation(mutation);
  const report = useFirebaseEvent({ eventName: "loginEmail" });

  const login = useCallback((event: React.FormEvent<EmailForm>) => {
    event.preventDefault();
    setError(``);
    mutate({
      variables: {
        username: event.currentTarget.email.value,
        password: event.currentTarget.password.value,
        client_secret: getEnvVariable("CLIENT_SECRET"),
        client_id: getEnvVariable("CLIENT_ID")
      }
    })
      .then(
        ({
          data: {
            login_simple: { access_token: accessToken }
          }
        }) => {
          report();
          localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
          window.location.reload();
        }
      )
      .catch(error => {
        if (
          error.graphQLErrors?.[0]?.extensions?.validation
            ?.invalid_grant?.[0] ===
          "The provided authorization grant (e.g., authorization code, resource owner credentials) or refresh token is invalid, expired, revoked, does not match the redirection URI used in the authorization request, or was issued to another client."
        ) {
          setError("El email o la contraseña ingresada no existen.");
        } else if (
          error.graphQLErrors?.[0]?.message ===
          "login_failed_email_has_not_been_verified"
        ) {
          setError(
            `Parece que el email aún no ha sido verificado. Por favor, ingresá en tu correo y verificá tu cuenta.`
          );
        } else {
          console.error(
            `Tuvimos un problema al ingresar sesión: ${JSON.stringify(error)}`
          );
          alert(
            "Parece que tuvimos un problema. No pudimos ingresarte. Parece que encontramos un problema desconocido."
          );
        }
      });
  }, []);

  return [login, loading, error];
};

const mutation = gql`
  mutation loginSimple(
    $username: String!
    $password: String!
    $client_id: String!
    $client_secret: String!
  ) {
    login_simple(
      username: $username
      password: $password
      client_id: $client_id
      client_secret: $client_secret
      grant_type: "password"
    ) {
      access_token
    }
  }
`;

export default useLoginWithEmail;
