import { useEffect, useMemo, useState } from "react";
import Recess from "typedef/Recess";
import getStartingDate from "./getStartingDate";

export type AgendaDay = {
  date: Date;
  recesses: Array<Recess>;
};

/**
 * Hook for managing the week's agenda given an offset
 * @param offset days to shift from the starting date
 * @param incomingAgenda list of recess events
 * @param pickDate the initial date to position the agenda
 */
const useAgenda = (
  offset: number,
  incomingAgenda: Array<Recess>,
  pickDate: any
) => {
  const [agenda, setAgenda] = useState<AgendaDay[]>([]);
  const startingDate = useMemo(
    () => getStartingDate(incomingAgenda, pickDate),
    [incomingAgenda, pickDate]
  );

  useEffect(() => {
    const generateAgenda = (days: number) => {
      const agendaDays: AgendaDay[] = [];
      for (let i = 0; i <= days; i++) {
        const currentDate = new Date(startingDate);
        currentDate.setDate(currentDate.getDate() + i + offset);

        const recesses =
          incomingAgenda?.filter(recess => {
            const dateFrom = new Date(recess.date_from);
            const dateTo = new Date(recess.date_to);

            // Check if currentDate is within the event range or if the event spans across the currentDate
            return (
              (currentDate >= dateFrom && currentDate <= dateTo) ||
              currentDate.toDateString() === dateFrom.toDateString() ||
              currentDate.toDateString() === dateTo.toDateString()
            );
          }) || [];

        agendaDays.push({ date: currentDate, recesses });
      }
      return agendaDays;
    };

    setAgenda(generateAgenda(6));
  }, [offset, incomingAgenda, pickDate, startingDate]);

  return [agenda];
};

export default useAgenda;
