import * as React from "react";

function SvgMoreHorizontal(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} {...props}>
      <path d='M14 12c0-.552-.225-1.053-.586-1.414a1.996 1.996 0 00-2.828 0 1.996 1.996 0 000 2.828 1.996 1.996 0 002.828 0c.361-.361.586-.862.586-1.414zm7 0c0-.552-.225-1.053-.586-1.414a1.996 1.996 0 00-2.828 0 1.996 1.996 0 000 2.828 1.996 1.996 0 002.828 0c.361-.361.586-.862.586-1.414zM7 12c0-.552-.225-1.053-.586-1.414a1.996 1.996 0 00-2.828 0 1.996 1.996 0 000 2.828 1.996 1.996 0 002.828 0C6.775 13.053 7 12.552 7 12z' />
    </svg>
  );
}

export default SvgMoreHorizontal;
