import { MAGENTA, VIOLET, VIOLETDARK } from "assets/colors";
import Icon from "assets/icons";
import Stars from "components/Stars/Stars";
import ShareIcon from "assets/images/share.svg";
import Agendar from "components/Agendar";

export type footerHeroProps = {
  stars?: number;
  onFav?: () => void;
  isFavorite?: boolean;
  onShare?: () => void;
  onUpdateShedule?: () => void;
  isScheduleActive?: boolean;
  loadingShedule?: boolean;
};

const FooterHero: React.FC<footerHeroProps> = ({
  stars,
  onFav,
  isFavorite,
  onShare,
  onUpdateShedule,
  isScheduleActive,
  loadingShedule
}) => {
  return (
    <div className='d-flex justify-content-between align-items-center w-100 mr-3'>
      <div className='flex-fill'>
        {stars !== undefined ? <Stars value={stars} color={VIOLET} /> : null}
      </div>
      {onUpdateShedule && (
        <Agendar
          isScheduleActive={isScheduleActive}
          onUpdateShedule={onUpdateShedule}
          loadingShedule={loadingShedule}
        />
      )}

      {onFav && (
        <button
          onClick={event => {
            event.stopPropagation();
            onFav?.();
          }}
          style={{ all: "unset", padding: "0 10px" }}>
          <Icon
            size={17}
            className='clickable'
            custom='Heart'
            fill={isFavorite ? VIOLETDARK : "transparent"}
            stroke={VIOLETDARK}
          />
        </button>
      )}
      <div
        onClick={onShare}
        className='clickable d-flex flex-column justify-content-center'>
        <img className='mx-2' src={ShareIcon} />
      </div>
    </div>
  );
};

export default FooterHero;
