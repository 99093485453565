import useNotificationsAuthorization from "./useNotificationsAuthorization";
import Destacado from "./Destacado";
import Amenities from "./Amenities";
import CityHalls from "./CityHalls";
import Recesses from "./Recesses";
import UtiliyBar from "components/UtilityBar";
import useCategories from "./useCategories";
import Subtitle from "./Subtitle";
import SearchResults from "./SearchResults";
import "./style.scss";
import { useLocation } from "react-router-dom";
import useScrollToTop from "hooks/useScrollToTop";
import useLangContext from "hooks/useLangContext";

import BannerAgendaCultural from "./../../assets/images/agenda-cultural/banner-1600x90px.jpg";
import BannerAgendaCulturalMobile from "./../../assets/images/agenda-cultural/banner-360x90px.jpg";

const Home = () => {
  useNotificationsAuthorization();
  const [categories] = useCategories();
  const location = useLocation();
  const { lang } = useLangContext();
  useScrollToTop();

  return (
    <div className='container-full home'>
      <Destacado />

      <div className='container'>
        {/* <a
          href='/agenda-cultural'
          className='banner-agenda-cultural'
          title='Agenda Cultural'>
          <picture>
            <source media='(min-width:540px)' srcset={BannerAgendaCultural} />
            <img src={BannerAgendaCulturalMobile} alt='Agenda Cultural' />
          </picture>
        </a> */}

        <div className='d-flex flex-column-reverse flex-sm-column position-relative'>
          <UtiliyBar
            className='mt-3'
            utils={categories}
            title={lang.Home.whatsMind}
            searchBar={lang.Home.whatsMindSearch}
            action={
              location.pathname === "/" &&
              location.search.includes("busqueda") &&
              !location.search.includes("filtro")
                ? ""
                : "/recess"
            }
          />
        </div>

        {location.search.length > 0 ? (
          <SearchResults />
        ) : (
          <>
            <Subtitle
              icon={"Calendar"}
              linkLabel={lang.Home.viewRecessSchedule}
              link='/agenda'
              more='/agenda'>
              {lang.Home.nearRecess}
            </Subtitle>
            <Amenities />

            <Subtitle
              icon={"Location"}
              linkLabel={lang.Home.viewRecommendRecess}
              link='/agenda'
              more='/cityhalls'>
              {lang.Home.knowCityHall}
            </Subtitle>
            <CityHalls />

            <Subtitle
              icon={"Like"}
              linkLabel={lang.Home.viewMoreRecessRecommend}
              link='/recreos-para-vos'
              more='/recreos-para-vos'>
              {lang.Home.recessForYou}
            </Subtitle>
            <Recesses className='mb-5' />
          </>
        )}
      </div>
    </div>
  );
};

export default Home;
