import { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import Locality from "typedef/Locality";

/**
 *  Hook to get a list of Localities
 */
const useLocalities = (cityHallId?: number): [Locality[], boolean] => {
  const [localities, setLocalities] = useState([]);
  const { loading } = useQuery(query, {
    onCompleted: ({ city_hall_detail }) => {
      if (city_hall_detail?.localities)
        setLocalities(city_hall_detail?.localities);
      else setLocalities([]);
    },
    onError: error => {
      if (cityHallId) {
        console.error("Tuvimos un problema al cargar las localidades", error);
        alert(`
          Parece que tuvimos un problema. 
          No pudimos cargar correctamente el listado de localidades, 
          por favor intentalo nuevamente más tarde. 
          Podes ingresar la localidad posteriormente en la aplicación.
        `);
      }
    },
    variables: {
      cityHallId
    }
  });

  return [localities, loading];
};

const query = gql`
  query getLocalities($cityHallId: String!) {
    city_hall_detail(id: $cityHallId) {
      name
      localities {
        id
        name
      }
    }
  }
`;

export default useLocalities;
