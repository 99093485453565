import { gql, useQuery } from "@apollo/client";
import { useRef } from "react";
import { useState } from "react";
import CityHall from "typedef/CityHall";

/**
 * Hook to get user's favorites cityHalls
 *
 */
const useFavCityHalls = () => {
  const [favs, setFavs] = useState<CityHall[]>([]);
  const page = useRef(1);
  const [bottomReached, setBottomReached] = useState(false);
  const { loading, refetch, fetchMore } = useQuery(query, {
    fetchPolicy: "no-cache",
    onError: error =>
      console.error(
        "Tuvimos un problema al cargas los municipios favoritos",
        error
      ),
    onCompleted: ({ favorite_city_hall: { data: cityHalls, last_page } }) => {
      setFavs(cityHalls);
      if (last_page === 1) setBottomReached(true);
    }
  });

  async function _refetch() {
    page.current = 1;
    setBottomReached(false);
    const {
      data: {
        favorite_city_hall: { data: cityHalls, last_page }
      }
    } = await refetch({
      variables: {
        page: 1
      }
    });

    if (last_page === 1) setBottomReached(true);
    setFavs(cityHalls);
  }

  async function _fetchMore() {
    if (!bottomReached) {
      const {
        data: {
          favorite_city_hall: { data: cityHalls, last_page }
        }
      } = await fetchMore({
        variables: {
          page: ++page.current
        }
      });
      setFavs(prev => [...prev, ...cityHalls]);
      if (last_page <= page.current) setBottomReached(true);
    }
  }

  return [favs, loading, _refetch, _fetchMore, bottomReached] as const;
};

const query = gql`
  query getFavs($page: Int) {
    favorite_city_hall(limit: 9999, page: $page) {
      last_page
      data {
        id
        name
        longitude
        latitude
        is_favorite
        icon {
          uri
        }
      }
    }
  }
`;

export default useFavCityHalls;
