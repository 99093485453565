import React, { useState } from "react";
import Check from "assets/images/active-point.svg";

const Checkbox = ({ label, selected, onSelect, style, ...props }) => {
  return (
    <>
      <label className='checkbox my-2 mr-3'>
        <img
          src={Check}
          className={selected ? "active mr-3" : "inactive mr-3"}
        />
        <input
          type='checkbox'
          onClick={() => onSelect(selected ? false : true)}
        />
        {label}
      </label>
    </>
  );
};

export default Checkbox;
