import { LIGHT_GRAY } from "assets/colors";
import Icon from "assets/icons";
import React, { useEffect } from "react";
import Twitter from "assets/images/twitter.svg";
import Facebook from "assets/images/facebook-share.svg";
import Linkedin from "assets/images/linkedin.svg";
import Telegram from "assets/images/telegram.svg";
import WhatsApp from "assets/images/whatsapp-share.svg";
import "./style.scss";
import useFirebaseLink from "hooks/useFirebaseLink";
import Loader from "assets/images/loader.svg";
import useLangContext from "hooks/useLangContext";

export type ShareProps = {
  url: string | undefined;
  type: string;
  content: any;
  onClose: (param: any) => any;
};

const ShareSocial: React.FC<ShareProps> = ({ url, onClose, type, content }) => {
  const baseUrl = window.location.origin.toString();
  const { lang } = useLangContext();
  let sharableUrl = "/";
  let mensaje = "";

  if (type === "recreo-simple") {
    sharableUrl = baseUrl + "/recess/simple/" + url;
    mensaje = `Quiero compartir con vos el recreo ${content?.title} y que disfrutes de Recreo en la Provincia`;
  } else if (type === "recreo-multiple") {
    sharableUrl = baseUrl + "/recess/multiple" + url;
    mensaje = `Quiero compartir con vos los recreos ${content?.title} y que disfrutes de Recreo en la Provincia`;
  } else if (type === "circuito") {
    sharableUrl = baseUrl + "recess/circuit/" + url;
    mensaje = `Quiero compartir con vos el circuito ${content?.title} y que disfrutes de Recreo en la Provincia`;
  } else if (type === "municipio") {
    sharableUrl = baseUrl + "/cityhall/" + url;
    mensaje = `Quiero compartir con vos el municipio ${content?.title} y que disfrutes de Recreo en la Provincia`;
  } else if (type === "beneficio") {
    sharableUrl = baseUrl + "/benefit/" + url;
    mensaje = `Quiero compartir con vos el beneficio ${content?.title} y que disfrutes de Recreo en la Provincia`;
  } else if (type === "recredito") {
    sharableUrl = baseUrl + "/recredit/" + url;
    mensaje = `Quiero compartir con vos el recredito ${content?.title} y que disfrutes de Recreo en la Provincia`;
  } else if (type === "convocatoria") {
    sharableUrl = baseUrl + "/convo/" + url;
    mensaje = `Quiero compartir con vos la convocatoria ${content?.title} y que disfrutes de Recreo en la Provincia`;
  }

  const DynamicLink = useFirebaseLink({
    link: sharableUrl,
    title: content?.title || content?.name,
    image: content?.icon
  });
  return (
    <>
      <div className='container-full modalContainer' onClick={onClose} />
      {DynamicLink && DynamicLink.shortLink ? (
        <div className='position-fixed test-modal m-auto bg-white p-4'>
          <button
            onClick={onClose}
            className='p-2 ml-auto h-auto align-top d-flex'>
            <Icon
              className='close'
              custom='Cross'
              fill={LIGHT_GRAY}
              size={14}
            />
          </button>
          <div className='content'>
            <h6 className='text-muted font-weight-bold '>
              {lang.Elements.shareTextUp}
            </h6>
            <div>
              <ul className='share align-items-center'>
                <li>
                  <a
                    href={`https://wa.me/?text=${mensaje}%20${DynamicLink.shortLink}`}
                    target='_blank'>
                    <img src={WhatsApp} alt='whatsapp' />
                  </a>
                </li>

                <li>
                  <a
                    href={`http://www.facebook.com/sharer.php?&quote=${mensaje}&u=${DynamicLink.shortLink}`}
                    target='_blank'>
                    <img src={Facebook} alt='facebook' />
                  </a>
                </li>
                <li>
                  <a
                    href={`https://twitter.com/intent/tweet?text=${mensaje}%20${DynamicLink.shortLink}`}
                    target='_blank'>
                    {" "}
                    <img src={Twitter} alt='twitter' />
                  </a>
                </li>
                <li>
                  <a
                    href={`https://t.me/share/url?url=${DynamicLink.shortLink}&text=${mensaje}`}
                    target='_blank'>
                    <img src={Telegram} alt='telegram' />
                  </a>
                </li>
                <li>
                  <a
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${
                      DynamicLink.shortLink
                    }&title=${
                      content?.title || content?.name
                    }&summary=${mensaje}`}
                    target='_blank'>
                    <img src={Linkedin} alt='linkedin' />
                  </a>
                </li>
              </ul>
              <div className='clipboard mt-2'>
                <span className='text-muted font-weight-bold '>
                  {lang.Elements.shareTextDown}
                </span>
                <label
                  className='d-flex justify-content-between align-items-center my-2 mt-3'
                  onClick={() => {
                    navigator.clipboard.writeText(DynamicLink.shortLink);
                  }}>
                  {DynamicLink.shortLink}
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(DynamicLink.shortLink);
                    }}>
                    Copiar
                  </button>
                </label>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='position-fixed test-modal m-auto bg-white p-4'>
          <img src={Loader} />
        </div>
      )}
    </>
  );
};

export default ShareSocial;
