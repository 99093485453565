import { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { distance } from "helpers";
import useGPS from "hooks/useGPS";
import Recess from "typedef/Recess";
import useLangContext from "hooks/useLangContext";

export type useRecessesReturnType = [Array<Recess>, boolean];

/**
 * Hook for returning a pageable list
 * of recesses.
 */
const useRecesses = (limit: number): useRecessesReturnType => {
  const [recesses, setRecesses] = useState<Array<Recess>>([]);
  const [coords] = useGPS();
  const { lang } = useLangContext();
  const { loading } = useQuery(query, {
    onCompleted: ({ recess_list: { data: recesses } }) => setRecesses(recesses),
    onError: error =>
      console.error(
        `Parece que tuvimos un problema cargando los recreos para el home, ${JSON.stringify(
          error
        )}`
      ),
    variables: {
      page: 1,
      limit: limit,
      latitude: coords?.latitude,
      longitude: coords?.longitude,
      lang: lang.current
    }
  });

  return [recesses, loading];
};

const query = gql`
  query getRecesses(
    $limit: Int
    $page: Int
    $latitude: Float
    $longitude: Float
    $lang: String
  ) {
    recess_list(
      limit: $limit
      page: $page
      lang: $lang
      latitude: $latitude
      longitude: $longitude
      time_proximity: 50
      space_proximity: 60
      preferences: 140
      home: true
    ) {
      data {
        id
        title
        date_to
        date_from
        score
        is_favorite
        is_multiple
        agenda {
          id
        }
        circuit {
          id
        }
        categories(lang: $lang) {
          name
          icon {
            uri
          }
        }
        city_hall {
          name
        }
        icon {
          uri
        }
      }
    }
  }
`;

export default useRecesses;
