import "./style.scss";
import Icon from "assets/icons";
import { LIGHT_GRAY } from "assets/colors";
import dayjs from "dayjs";
import QR from "components/QR";
import Ticket from "typedef/Ticket";
import BTNViewInTheMap from "components/BTNViewInTheMap";
import useLangContext from "hooks/useLangContext";

export type TicketQrProps = {
  ticket: Ticket;
  userId: String;
  onClose?: (param: any) => any;
  onGoToRecess?: () => void;
};

const TicketModal: React.FC<TicketQrProps> = ({
  ticket,
  onClose,
  userId,
  onGoToRecess
}) => {
  const { lang } = useLangContext();
  return (
    <div className='position-fixed ticketModal shadow m-auto bg-white p-4'>
      <div className='w-100 d-flex'>
        <button onClick={onClose} className='p-0 h-auto align-top d-flex '>
          <Icon className='d-flex' custom='Cross' fill={LIGHT_GRAY} size={14} />
        </button>
      </div>
      <div className='px-4'>
        <div className='w-100 my-4'>
          <h4 className='text-secondary text-center text-uppercase font-weight-bold mt-2'>
            {ticket.recess.title}
          </h4>
          {ticket.recess.date_from && ticket.recess.date_to ? (
            <div>
              <h6 className='font-weight-bold text-muted text-center my-3 text-capitalize'>
                {dayjs(ticket.recess.date_from)
                  .locale("es")
                  .format("dddd DD  MMMM")}{" "}
                - {dayjs(ticket.recess.date_from).format("HH:mm")} hs.
              </h6>
            </div>
          ) : null}
        </div>
        <div className=' qr-block '>
          <QR value={ticket.code_qr} />
          <div className='more-info mt-5 mb-2 text-center'>
            <h6 className='sub'>{lang.MyBenefits.code}</h6>
            <h6 className='code my-2 text-primary '>
              {ticket.code_qr}:{userId}
            </h6>
          </div>
        </div>

        <div
          className='cta d-flex justify-content-center mb-4'
          onClick={onClose}>
          <BTNViewInTheMap goToMap={() => onGoToRecess?.()} />
        </div>
      </div>
    </div>
  );
};

export default TicketModal;
