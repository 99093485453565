import * as React from "react";

function SvgLogoRecreoBlanco(props) {
  return (
    <svg
      width={121}
      height={69}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}>
      <defs>
        <path id='logo-recreo-blanco_svg__a' d='M0 68.85h120.1V.384H0z' />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <path
          fill='#FFF'
          d='M49.376 47.619h3.17v.918h-4.298v-6.324h4.256v.919h-3.128v1.71h2.7v.918h-2.7zM57.631 42.213h1.073v6.324H57.55l-3.003-4.61v4.61H53.47v-6.324h1.153l3.008 4.615zM62.08 48.537v-6.324h1.14v5.371h2.983v.953zM68.335 45.803h1.876l-.94-2.872-.936 2.872zm2.768 2.734l-.594-1.82h-2.47l-.594 1.82h-1.119l2.128-6.324h1.65l2.128 6.324h-1.129z'
        />
        <g transform='translate(0 .066)'>
          <path
            d='M78.108 45.357c.253-.216.379-.562.379-1.035 0-.478-.124-.828-.37-1.048-.246-.221-.629-.331-1.147-.331-.265 0-.52.02-.765.06v2.636c.194.028.427.042.7.042.551 0 .951-.108 1.203-.324zm-3.044-3.103a9.488 9.488 0 011.808-.18c.914 0 1.597.183 2.049.546.451.363.677.927.677 1.694 0 .738-.225 1.295-.675 1.673-.45.377-1.127.566-2.03.566-.145 0-.374-.008-.688-.026v1.944h-1.141v-6.217zM81.687 45.147c.271.026.523.039.757.039.538 0 .933-.09 1.183-.272.25-.18.376-.468.376-.86 0-.394-.117-.68-.353-.86-.234-.179-.604-.269-1.109-.269a4.81 4.81 0 00-.854.077v2.145zm2.654 3.325L82.6 46.04c-.04.003-.099.004-.179.004-.17 0-.416-.008-.735-.025v2.453h-1.123v-6.196a8.876 8.876 0 011.871-.201c.886 0 1.548.16 1.985.483.437.322.655.819.655 1.491.004.462-.113.846-.347 1.154-.236.308-.575.531-1.02.67l1.885 2.599H84.34zM89.919 47.077c.29-.364.434-.945.434-1.741 0-.82-.146-1.416-.436-1.785-.29-.368-.707-.553-1.248-.553-.54 0-.956.181-1.245.543-.29.361-.434.942-.434 1.743 0 .823.144 1.419.431 1.786.288.367.704.551 1.248.551s.96-.181 1.25-.544m-2.77 1.125c-.426-.234-.754-.592-.986-1.076-.232-.485-.348-1.09-.348-1.817 0-.726.116-1.33.348-1.813.232-.483.561-.842.99-1.077.427-.235.932-.352 1.516-.352.586 0 1.092.117 1.52.352.425.235.754.594.986 1.077.232.483.348 1.087.348 1.813 0 .724-.117 1.327-.35 1.813-.234.484-.563.843-.99 1.078-.425.236-.93.353-1.514.353-.587 0-1.093-.117-1.52-.35M96.561 42.148h1.12l-2.115 6.324H93.89l-2.115-6.324h1.196l1.787 5.456z'
            fill='#FFF'
          />
          <mask id='logo-recreo-blanco_svg__b' fill='#fff'>
            <use xlinkHref='#logo-recreo-blanco_svg__a' />
          </mask>
          <path
            fill='#FFF'
            mask='url(#logo-recreo-blanco_svg__b)'
            d='M98.326 48.472h1.14v-6.324h-1.14zM104.876 42.148h1.073v6.324h-1.155l-3.003-4.61v4.61h-1.077v-6.324h1.153l3.009 4.614zM106.961 45.322c0-1.088.275-1.902.827-2.444.552-.541 1.347-.811 2.387-.811.467 0 .92.054 1.358.162v.949a5.1 5.1 0 00-1.278-.158c-.472 0-.863.077-1.17.23-.308.154-.54.398-.695.733-.155.335-.233.775-.233 1.322 0 .559.077 1.006.232 1.344.153.337.38.58.68.729.301.148.68.221 1.14.221.43 0 .87-.069 1.324-.208v.948a4.356 4.356 0 01-.681.159 4.945 4.945 0 01-.742.055c-1.023 0-1.803-.26-2.342-.775-.538-.518-.807-1.336-.807-2.456zM112.35 48.472h1.14v-6.324h-1.14zM116.203 45.737h1.876l-.94-2.871-.936 2.871zm2.768 2.734l-.593-1.82h-2.47l-.594 1.82h-1.119l2.128-6.324h1.65l2.127 6.324h-1.129zM49.762 33.048c-3.404 16.228-21.07 23.648-30.372 19.725-9.303-3.923-9.415 2.017-9.415 2.017v12.777c0 .815-.828 2.08-3.128.599-2.433-1.569-2.588-.375-4.718-1.832C0 64.876 0 61.291 0 61.291l.25-45.273s-.086-1.205-.086-4.089c0-2.883 3.787-3.09 3.787-3.09h7.705s9.975-7.51 12.217-8.406c1.05-.42 4.932 1.966 8.761 4.62 2.03 1.409-.513 3.098 1.057 4.278 1.785 1.342 6.855 4.645 6.855 4.645s7.355 4.782 6.57 6.912c-.783 2.129-3.406 3.081-.828 5.995l2.392 2.505s1.662.899 1.082 3.66'
          />
          <path
            d='M17.27 29.128c.572.065 1.124.1 1.655.1.938 0 1.64-.18 2.11-.536.468-.357.703-.945.703-1.768 0-1.55-.946-2.327-2.838-2.327-.548 0-1.09.05-1.63.15v4.38zm5.264 10.229l-4.07-6.185-1.194-.037v6.222h-5.314V21.424a28.221 28.221 0 013.11-.535c1.121-.133 2.192-.2 3.212-.2 2.82 0 4.945.514 6.372 1.538 1.427 1.024 2.14 2.59 2.14 4.697 0 1.237-.278 2.299-.834 3.187-.555.887-1.348 1.589-2.376 2.102l4.803 7.144h-5.85zM35.028 35.089h8.762v4.268h-14.1V20.939h13.975v4.269h-8.637v2.625h7.331v4.257h-7.33z'
            fill='#1EB4CD'
            mask='url(#logo-recreo-blanco_svg__b)'
          />
          <path
            d='M50.94 30.198c0-2.033.41-3.76 1.231-5.177.821-1.419 2.026-2.496 3.615-3.23 1.59-.734 3.529-1.101 5.818-1.101 1.42 0 2.734.128 3.945.386v4.393a18.095 18.095 0 00-3.472-.349c-1.277 0-2.316.176-3.117.53-.8.352-1.394.898-1.78 1.636-.385.739-.579 1.692-.579 2.862 0 1.195.187 2.159.56 2.894.374.734.953 1.272 1.737 1.617.783.345 1.802.517 3.055.517 1.062 0 2.26-.17 3.596-.51v4.392c-.58.167-1.238.299-1.972.399-.734.1-1.458.149-2.172.149-6.977 0-10.466-3.136-10.466-9.408M72.88 29.128c.572.065 1.123.1 1.655.1.937 0 1.64-.18 2.11-.536.467-.357.702-.945.702-1.768 0-1.55-.945-2.327-2.837-2.327-.548 0-1.092.05-1.63.15v4.38zm5.263 10.229l-4.07-6.185-1.193-.037v6.222h-5.315V21.424a28.248 28.248 0 013.112-.535c1.12-.133 2.19-.2 3.21-.2 2.821 0 4.945.514 6.372 1.538 1.426 1.024 2.14 2.59 2.14 4.697 0 1.237-.278 2.299-.833 3.187-.556.887-1.348 1.589-2.377 2.102l4.803 7.144h-5.849zM90.638 35.089H99.4v4.268H85.3V20.939h13.974v4.269h-8.636v2.625h7.33v4.257h-7.33zM112.94 34.056c.647-.822.97-2.091.97-3.808 0-1.809-.325-3.135-.977-3.977-.65-.842-1.607-1.263-2.869-1.263-1.252 0-2.204.41-2.855 1.226-.652.818-.977 2.089-.977 3.815 0 1.825.322 3.154.964 3.988.643.834 1.6 1.25 2.868 1.25 1.27 0 2.228-.41 2.876-1.231m-7.853 4.48c-1.402-.713-2.485-1.777-3.248-3.193-.763-1.413-1.145-3.146-1.145-5.196 0-2.048.382-3.78 1.145-5.194.763-1.415 1.846-2.48 3.248-3.193 1.402-.713 3.062-1.07 4.977-1.07 1.917 0 3.579.359 4.985 1.076 1.406.718 2.49 1.784 3.255 3.199.762 1.414 1.145 3.142 1.145 5.182 0 2.042-.383 3.77-1.145 5.184-.764 1.415-1.849 2.481-3.255 3.198-1.406.719-3.068 1.077-4.985 1.077-1.915 0-3.575-.357-4.977-1.07'
            fill='#FFF'
            mask='url(#logo-recreo-blanco_svg__b)'
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgLogoRecreoBlanco;
