import useAccessToken from "hooks/useAccessToken";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";

const useRedirect = () => {
  const token = useAccessToken();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (token && !location.state) {
      history.goBack();
    } else if (token && location.state) {
      history.go(-5);
    }
  }, [token, history]);
};

export default useRedirect;
