import { gql, useQuery } from "@apollo/client";
import { useMemo, useState } from "react";
import { useHistory } from "react-router";
import ReCredit from "typedef/ReCredits";

const useRecredit = (id: string) => {
  const history = useHistory();
  const [recredit, setReCredit] = useState<ReCredit>();
  const { loading } = useQuery(query, {
    onCompleted: ({ benefit_detail }) => {
      if (benefit_detail) {
        setReCredit(benefit_detail);
      } else {
        history.goBack();
        alert("Parece que el ReCredito no existe");
      }
    },
    onError: error => {
      console.error(
        "Parece que tuvimos un problema al cargar los datos del ReCredito",
        error
      );
      history.goBack();
      alert("Parece que no pudimos cargar el ReCredito");
    },
    variables: {
      id
    }
  });

  return useMemo(
    () => ({
      recredit,
      loading
    }),
    [recredit, loading]
  );
};

const query = gql`
  query getBenefit($id: String!) {
    benefit_detail(id: $id) {
      id
      name
      validity_to
      legals
      cost_recredits
      code_qr
      images {
        uri
      }
      category {
        name
        icon {
          uri
        }
      }
      description
      apply_days
      commerce {
        id
        name
        is_ecommerce
        web_url
        instagram_url
        sustainability
        accessibility
        preventive_measures
        gender_diversity
        city_hall {
          id
          name
          address
        }
        images {
          uri
        }
        address
        latitude
        longitude
        branch_offices {
          is_ecommerce
          web_url
          name
          phone_number
          instagram_url
          sustainability
          accessibility
          preventive_measures
          gender_diversity
          address
          longitude
          latitude
        }
      }
    }
  }
`;

export default useRecredit;
