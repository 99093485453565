import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import { useHistory } from "react-router";

const useSaveResponse = survay => {
  const [mutate] = useMutation(mutation);
  const navigation = useHistory();

  const saveResponse = useCallback(async () => {
    try {
      const {
        data: { vote }
      } = await mutate({ variables: survay });
      if (vote) {
        alert("Gracias por responder la encuesta");
        navigation.goBack();
      }
    } catch (error) {
      console.error(
        `Tuvimos un problema guardando las respuestas: ${JSON.stringify(error)}`
      );
      alert("Tuvimos un problema");
    }
  }, [mutate, survay]);

  return saveResponse;
};

const mutation = gql`
  mutation saveResponse(
    $id: String!
    $qualification: Int!
    $recommendation: Int!
    $situation: Int!
    $is_recess: Int!
  ) {
    vote(
      id: $id
      qualification: $qualification
      recommendation: $recommendation
      situation: $situation
      is_recess: $is_recess
    ) {
      id
    }
  }
`;

export default useSaveResponse;
