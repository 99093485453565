import classes from "./style/index.module.scss";
import { formatExternalAssetUrl } from "helpers";
import dayjs from "dayjs";
import "dayjs/locale/es";

export type topTitleHeroProps = {
  children: any;
};

const TopTitleHero: React.FC<topTitleHeroProps> = ({ children }) => {
  return (
    <>
      {children.date_from && children.date_to ? (
        <h6 className='font-weight-bold small text-muted mb-3'>
          {dayjs(children.date_from).locale("es").format("DD  MMM")} ·{" "}
          {dayjs(children.date_to).locale("es").format("DD MMM")}
          <span> | </span>
          {dayjs(children.date_from).locale("es").format("HH")}:
          {dayjs(children.date_from).locale("es").format("mm")} hs.
        </h6>
      ) : (
        <h6 className={`${classes.benefitPorcentate}`}>{children.name}</h6>
      )}

      {/* TITULO */}
      <h2>{children.title || children.commerce?.name}</h2>

      {/* CATEGORIAS */}
      <div className={`d-flex ${classes.category}`}>
        <img
          className='col-auto p-0'
          src={formatExternalAssetUrl(
            children.category?.icon || children.categories?.[0].icon
          )}
        />
        <h6 className='text-muted m-0 col'>
          {children.category?.name || children.categories?.[0].name}
        </h6>
      </div>
    </>
  );
};

export default TopTitleHero;
