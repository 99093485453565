import * as React from "react";

function SvgDocumentIcon(props) {
  return (
    <svg
      width='229'
      height='314'
      viewBox='0 0 229 314'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M32 14H114.5C170.005 14 215 58.9954 215 114.5V282C215 291.941 206.941 300 197 300H32C22.0589 300 14 291.941 14 282V32C14 22.0589 22.0589 14 32 14Z'
        stroke={props.stroke}
        stroke-width='18'
      />
      <line
        x1='49.5'
        y1='73.5'
        x2='109.5'
        y2='73.5'
        stroke={props.colorLine}
        stroke-width='11'
        stroke-linecap='round'
      />
      <line
        x1='49.5'
        y1='116.5'
        x2='180.5'
        y2='116.5'
        stroke={props.colorLine}
        stroke-width='11'
        stroke-linecap='round'
      />
      <line
        x1='48.5'
        y1='159.5'
        x2='179.5'
        y2='159.5'
        stroke={props.colorLine}
        stroke-width='11'
        stroke-linecap='round'
      />
      <line
        x1='49.5'
        y1='202.5'
        x2='180.5'
        y2='202.5'
        stroke={props.colorLine}
        stroke-width='11'
        stroke-linecap='round'
      />
      <line
        x1='49.5'
        y1='247.5'
        x2='180.5'
        y2='247.5'
        stroke={props.colorLine}
        stroke-width='11'
        stroke-linecap='round'
      />
    </svg>
  );
}

export default SvgDocumentIcon;
