import * as React from "react";

function SvgActivePoint(props) {
  return (
    <svg width={59} height={61} xmlns='http://www.w3.org/2000/svg' {...props}>
      <g fill='none' fillRule='evenodd'>
        <path fill='#EB0189' d='M0 0h62v61H0z' />
        <circle fill='#12B6C9' cx={30.5} cy={30.5} r={20.5} />
      </g>
    </svg>
  );
}

export default SvgActivePoint;
