import {
  MapContainer,
  Marker,
  TileLayer,
  Popup,
  LayersControl
} from "react-leaflet";
import ubicationIcon from "../../assets/images/conoce.png";
import L from "leaflet";
import classes from "./classes.module.scss";
import useMapRange from "./useMapRange";
import { useHistory } from "react-router";
import useLangContext from "hooks/useLangContext";

type MapProps = {
  ubications: Array<{
    id?: string;
    name?: string;
    title?: string;
    latitude: number;
    longitude: number;
    visited?: boolean;
    hasQR?: boolean;
  }>;
  isVisible?: boolean;
  openModal?: () => void;
  type?: string;
};

const Map: React.FC<MapProps> = ({
  ubications,
  isVisible,
  type,
  openModal
}) => {
  const position = useMapRange(ubications);
  const history = useHistory();
  const { lang } = useLangContext();

  const icon = L.icon({
    iconUrl: ubicationIcon,
    iconSize: [30, 30],
    iconAnchor: [30, 30],
    popupAnchor: [-13, -20]
  });

  return (
    <>
      {isVisible !== false && (
        <MapContainer
          className={`${classes.mapSize}`}
          center={position}
          zoom={
            //segun la cantidad hago un zoom, si son muchos lo alejo sino acerco
            ubications?.length > 10
              ? 7
              : ubications?.length < 7 && ubications?.length > 2
              ? 8
              : 9
          }
          scrollWheelZoom={true}>
          <LayersControl name='OpenStreetMap.BlackAndWhite'>
            <TileLayer
              className={`${classes.applyfill}`}
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url='https://tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
          </LayersControl>
          {ubications?.map((ubication, i) => {
            return (
              <Marker
                key={i}
                icon={icon}
                position={[ubication.latitude, ubication.longitude]}>
                <Popup>
                  <div className='d-flex flex-column justify-content-center align-items-center'>
                    <h6 className='text-center'>
                      <small>{ubication.name || ubication.title} </small>
                    </h6>
                    {type && (
                      <button
                        className={
                          "text-uppercase secondary secondary--magenta pt-1 pb-1 pr-2 pl-2 mt-1"
                        }
                        onClick={() =>
                          history.push(
                            `${
                              type === "benefit"
                                ? "benefit/" + ubication.id
                                : type === "city-hall"
                                ? "cityhall/" + ubication.id
                                : type === "recess" && "recreo/" + ubication.id
                            }`
                          )
                        }>
                        <small>{lang.Elements.btnViewMap}</small>
                      </button>
                    )}
                    {!ubication.visited && openModal && ubication.hasQR && (
                      <button
                        onClick={openModal}
                        className={
                          "text-uppercase secondary secondary--magenta pt-1 pb-1 pr-2 pl-2 mt-1"
                        }>
                        <small>Registrar Vista</small>
                      </button>
                    )}
                  </div>
                </Popup>
              </Marker>
            );
          })}
        </MapContainer>
      )}
    </>
  );
};

export default Map;
