import * as React from "react";

function SvgRecycle(props) {
  return (
    <svg
      viewBox='0 0 32 32'
      xmlns='http://www.w3.org/2000/svg'
      width={32}
      height={32}
      {...props}>
      <path d='M28.289 6.41A14.948 14.948 0 0018.212.826c-3.8-.432-7.543.567-10.608 2.82L6.28 1.982a.588.588 0 00-1.002.137l-3.612 8.525a.59.59 0 00.643.809l9.119-1.605a.59.59 0 00.358-.945l-1.307-1.642a10.433 10.433 0 0110.17-1.062.59.59 0 00.466-1.08A11.606 11.606 0 009.277 6.694a.586.586 0 00-.094.827l1.068 1.341-7.08 1.246 2.804-6.619L7.043 4.83a.588.588 0 00.826.094C13.858.16 22.605 1.156 27.368 7.143a13.77 13.77 0 012.966 9.829l-2.321.409a11.576 11.576 0 00-2.415-8.83.588.588 0 00-.92.732 10.407 10.407 0 012.035 8.699.588.588 0 00.574.712c.034 0 3.687-.641 3.687-.641a.587.587 0 00.482-.51 14.948 14.948 0 00-3.169-11.132z' />
      <path d='M30.709 20.112l-9.119 1.604a.59.59 0 00-.358.945l1.307 1.644a10.372 10.372 0 01-5.441 1.895v-7.729c.606.15 1.205.225 1.788.225 1.097 0 2.135-.265 3.045-.791 1.602-.925 2.701-2.593 3.094-4.698a.587.587 0 00-.382-.662c-2.02-.713-4.014-.596-5.616.329-1.096.633-1.956 1.614-2.517 2.844-.56-1.23-1.421-2.211-2.517-2.844-1.602-.925-3.597-1.042-5.616-.329a.587.587 0 00-.382.662c.393 2.104 1.492 3.773 3.094 4.698.911.526 1.948.791 3.045.791.583 0 1.182-.075 1.788-.225v7.728a10.411 10.411 0 01-7.581-3.917 10.407 10.407 0 01-2.035-8.699.59.59 0 00-.676-.702l-3.585.63a.587.587 0 00-.482.51 14.952 14.952 0 003.169 11.134 14.956 14.956 0 0010.077 5.584c.58.066 1.158.099 1.732.099 3.193 0 6.279-1.009 8.875-2.918l1.324 1.664a.588.588 0 001.002-.137l3.612-8.525a.587.587 0 00-.643-.808zm-11.095-6.22c1.173-.677 2.628-.812 4.142-.394-.396 1.52-1.241 2.711-2.413 3.388s-2.628.813-4.143.396c.396-1.521 1.241-2.713 2.414-3.39zm-7.938 2.994c-1.172-.677-2.017-1.869-2.413-3.388 1.514-.418 2.969-.282 4.142.394s2.018 1.869 2.414 3.39c-1.515.417-2.97.281-4.143-.396zm15.367 11.19l-1.068-1.341a.588.588 0 00-.826-.094 13.814 13.814 0 01-8.626 3.011c-4.087 0-8.138-1.792-10.874-5.231a13.778 13.778 0 01-2.966-9.831l2.321-.408a11.575 11.575 0 002.415 8.831 11.6 11.6 0 009.101 4.378c2.535 0 5.087-.824 7.22-2.52a.588.588 0 00.094-.826l-1.068-1.343 7.08-1.245-2.804 6.619zM23.197 7.601a.588.588 0 10-.417-.173l.002.002a.585.585 0 00.415.172z' />
    </svg>
  );
}

export default SvgRecycle;
