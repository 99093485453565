import { gql, useQuery } from "@apollo/client";
import React, { useMemo, useState } from "react";

const useBenefitsLocations = () => {
  const { data, loading } = useQuery(query, {
    onError: error => {
      console.error(
        "Parece que tuvimos un problema al cargar los datos del beneficio",
        error
      );
    }
  });

  //transformo la informacion para que sea mas adaptable.
  let benefitLocation = data?.benefit_list?.data.map(benefit => {
    return {
      id: benefit?.id,
      name: benefit.commerce.name,
      longitude: benefit.commerce.longitude,
      latitude: benefit.commerce.latitude
    };
  });

  return useMemo(
    () => ({
      benefitLocation,
      loading: loading
    }),
    [benefitLocation, loading]
  );
};

const query = gql`
  query getBenefits {
    benefit_list(limit: 99999, page: 1) {
      data {
        id
        name
        commerce {
          name
          longitude
          latitude
        }
      }
    }
  }
`;

export default useBenefitsLocations;
