import * as React from "react";

function SvgHome(props) {
  return (
    <svg
      width='28px'
      height='26px'
      viewBox='0 0 28 26'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}>
      <title>Shape</title>
      <desc>Created with Sketch.</desc>
      <g id='Symbols' stroke='none'>
        <g id='Home'>
          <g id='Shape'>
            <path d='M27.4176,10.5283596 L15.5358,0.448359559 C14.8306527,-0.149453186 13.7965473,-0.149453186 13.0914,0.448359559 L1.2096,10.5283596 C0.619558278,11.0310888 0.396313553,11.8431366 0.646440153,12.5768413 C0.896566753,13.310546 1.56934551,13.8171313 2.3436,13.8547596 L2.3436,23.9347596 C2.3436,24.9785777 3.18978182,25.8247596 4.2336,25.8247596 L24.3936,25.8247596 C25.4374182,25.8247596 26.2836,24.9785777 26.2836,23.9347596 L26.2836,13.8610596 C27.0578545,13.8234313 27.7306332,13.316846 27.9807598,12.5831413 C28.2308864,11.8494366 28.0076417,11.0373888 27.4176,10.5346596 L27.4176,10.5283596 Z M13.6836,23.9410596 L12.4236,23.9410596 L12.4236,18.2710596 L16.2036,18.2710596 L16.2036,23.9410596 L13.6836,23.9410596 Z M24.3936,11.9710596 L24.3936,23.9410596 L18.0936,23.9410596 L18.0936,18.2710596 C18.0936,17.2272414 17.2474182,16.3810596 16.2036,16.3810596 L12.4236,16.3810596 C11.3797818,16.3810596 10.5336,17.2272414 10.5336,18.2710596 L10.5336,23.9410596 L4.2336,23.9410596 L4.2336,11.9710596 L2.4318,11.9710596 L14.3136,1.89105956 L26.1954,11.9710596 L24.3936,11.9710596 Z'></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgHome;
