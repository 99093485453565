import * as React from "react";

function SvgMail(props) {
  return (
    <svg width={33} height={33} xmlns='http://www.w3.org/2000/svg' {...props}>
      <g>
        <circle cx={16.5} cy={16.5} r={16.5} />
        <path
          d='M23.238 22c.399 0 .75-.152 1.055-.457.305-.305.457-.656.457-1.055v-7.136c0-.61-.234-1.043-.703-1.301L16.875 8.5l-7.172 3.55c-.469.282-.703.716-.703 1.302v7.136c0 .399.152.75.457 1.055.305.305.656.457 1.055.457h12.726zm-6.363-5.063L10.863 13l6.012-2.988L22.887 13l-6.012 3.938z'
          fill='#FFF'
        />
      </g>
    </svg>
  );
}

export default SvgMail;
