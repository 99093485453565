import React from "react";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { CR_BLACK_COLOR, GRAY, WHITE } from "assets/colors";
import { FONT_SEMIBOLD } from "assets/fonts/constants";
import Icon from "assets/icons";
import RecessCategory from "typedef/RecessCategory";
import { formatExternalAssetUrl } from "helpers";
import "./style.scss";
import { useHistory } from "react-router-dom";
import useNotificationToRead from "./useNotificationToRead";
/**
 *
 * @param {{
 *  notification: RecreoNotification
 *  categories: Array<RecessCategory>
 * }} NotificationProps
 */
const Notification = ({ notification, categories }) => {
  const history = useHistory();
  const notificationRead = useNotificationToRead(notification.id); //una vez se despliega la card, hace de cuenta que se leyo la notificacion ( para que luego no aparesca para notificarte)
  const category = categories.filter(
    c => notification.data.categories?.[0] === c.id
  );

  return (
    <>
      {notification.data ? (
        <div className='container notification'>
          <a
            className='row flex-nowrap'
            href={
              notification.data.link != "sin_link"
                ? notification.data.link
                : null
            }>
            <div className='icon'>
              {category[0] ? (
                <img
                  src={formatExternalAssetUrl(category[0]?.icon)}
                  className='w-100'
                />
              ) : (
                <Icon custom='Bell' color='#CCCCCC' size={44} className='' />
              )}
            </div>
            <div>
              <h6 className=''>{notification.name}</h6>

              <span className='text-muted'>
                {dayjs(notification.created_at)
                  .locale("es")
                  .format("MMMM")
                  .slice(0, 3)}{" "}
                {dayjs(notification.created_at).locale("es").format("DD")}
                {", "}
                {dayjs(notification.created_at)
                  .locale("es")
                  .format("HH:mm")}{" "}
                hs.
              </span>
            </div>
          </a>
        </div>
      ) : null}
    </>
  );
};

export default Notification;
