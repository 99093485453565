import { gql, useQuery } from "@apollo/client";
import useLangContext from "hooks/useLangContext";
import { useMemo, useState } from "react";
import Recess from "typedef/Recess";
import Reward from "typedef/Reward";

type QueryResponse = {
  city_hall_detail: {
    safety_pins: Array<Recess>;
  } | null;
};

const useImperdibles = (id: string | null) => {
  const [imperdibles, setImperdibles] = useState<Recess[]>([]);
  const { lang } = useLangContext();
  const { loading } = useQuery(query, {
    fetchPolicy: "no-cache",
    onCompleted: (data: QueryResponse) => {
      setImperdibles(
        [...(data.city_hall_detail?.safety_pins || [])].sort(
          (a, b) => (a.safety_pin_order || 0) - (b.safety_pin_order || 0)
        )
      );
    },
    onError: error =>
      console.error(
        `Tuvimos un problema al cargar los imperdibles del municipio: ${JSON.stringify(
          error
        )}`
      ),
    variables: {
      id: id,
      lang: lang.current
    }
  });

  return useMemo(
    () => ({
      imperdibles,
      loading
    }),
    [imperdibles, loading]
  );
};

const query = gql`
  query getImperdibles($id: String!, $lang: String) {
    city_hall_detail(id: $id, lang: $lang) {
      safety_pins(lang: $lang) {
        id
        title
        safety_pin_order
        is_multiple
        visited
        categories(lang: $lang) {
          name
          icon {
            uri
          }
        }
        score
        date_from
        date_to
        city_hall {
          name
        }
        icon {
          id
          uri
        }
        latitude
        longitude
        is_favorite
      }
    }
  }
`;

export default useImperdibles;
