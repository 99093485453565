export default {
  title: "Events",
  search: "What activities do you looking for?",
  today: "Today",
  tomorrow: "Tomorrow",
  thisWeek: "This week",
  nextWeek: "Next week",
  thisMonth: "This month",
  nextMonth: "Next month",
  selectDate: "Select Date",
  loadMore: "Load more events"
};
