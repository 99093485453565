export default function reducer(state, { type, payload }) {
  switch (type) {
    case "setQualification":
      return { ...state, qualification: payload };
    case "setSituation":
      return { ...state, situation: payload };
    case "setIsRecess":
      return { ...state, is_recess: payload };
    case "setRecommendation":
      return { ...state, recommendation: payload };
    default:
      return state;
  }
}
