import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import "./style.scss";
import useScrollToTop from "hooks/useScrollToTop";
const PasswordRecoveryRequest = () => {
  const [email, setEmail] = useState("");
  const history = useHistory();
  useScrollToTop();
  const [requestCode, { data, loading, error }] = useMutation(gql`
    mutation forgetPassword($email: String!) {
      forget_password(email: $email)
    }
  `);

  const sendRequest = (
    /** @type {React.MouseEvent<HTMLButtonElement, MouseEvent>} */ e,
    /** @type {string} */ email
  ) => {
    e.preventDefault();
    requestCode({ variables: { email } }).catch(error =>
      alert("El correo electrónico ingresado no es válido")
    );
  };

  useEffect(() => {
    if (!loading && data?.forget_password) {
      if (
        window.confirm(
          `Revisá tu correo. Hemos enviado un correo a ${email} con instrucciones para cambiar tu contraseña`
        )
      ) {
        history.push({
          pathname: "password-recovery-code",
          state: `${email}`
        });
      }
    }
  }, [loading]);

  return (
    <div className='container-full bg-white py-5'>
      <div className='password-recovery-request container bg-white'>
        <h5 className='text-secondary text-center bold title'>
          Recuperá tu contraseña
        </h5>
        <h6 className='text-muted text-center col-lg-3 p-0 m-auto'>
          Te enviaremos un código a tu e-mail para que puedas crear una nueva
          contraseña.
        </h6>

        <form className='col-lg-5 col-sm-12 col-12 mx-auto'>
          <div className='email'>
            <input
              required
              type='email'
              name='email'
              className='email'
              placeholder='E-mail'
              defaultValue={email}
              onBlur={({ currentTarget }) => setEmail(currentTarget.value)}
            />
          </div>
          <button
            disabled={loading}
            onClick={e => sendRequest(e, email)}
            className='secondary secondary--magenta mx-auto'
            type='submit'>
            {loading && (
              <span
                className='spinner-border spinner-border-sm mr-2'
                role='status'
                aria-hidden='true'
              />
            )}
            Enviar
          </button>
        </form>

        <div className='separador col-lg-5 col-md-12 col-12'></div>

        <h6 className='text-muted text-center col-lg-5 m-auto'>
          ¿Recordás tu contraseña?{" "}
          <Link to='/login' className='text-magenta font-weight-bold'>
            Iniciá sesión
          </Link>
        </h6>
      </div>
    </div>
  );
};

export default PasswordRecoveryRequest;
