export default {
  title: "Agenda",
  search: "Qual atividade você procura?",
  today: "Hoje",
  tomorrow: "Amanhã",
  thisWeek: "Esta semana",
  nextWeek: "Próxima semana",
  thisMonth: "Este mês",
  nextMonth: "Próximo mês",
  selectDate: "Selecionar Data",
  loadMore: "Carregar outros eventos"
};
