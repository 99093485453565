import useMyBenefits from "./useMyBenefits";
import Loader from "assets/images/loader.svg";
import BenefitCard from "components/BenefitCard";
import { useHistory } from "react-router";
const MyBenefitsList = () => {
  const { benefits, loading, refetch } = useMyBenefits();
  const history = useHistory();
  return (
    <>
      {!loading ? (
        <div className='container row my-benefits p-0 m-0'>
          {benefits?.length && !loading ? (
            benefits.map((benefit, i) => (
              <BenefitCard
                key={i}
                used={benefit.used}
                item={benefit}
                hasQR={!benefit.used}
                index={i}
                onClick={() => {
                  if (benefit.used) history.push(`Benefit/${benefit.id}`);
                }}
              />
            ))
          ) : (
            <div className='d-flex justify-content-center align-items-center text-center m-auto'>
              <h5 className='font-weight-bold'>
                ¡Ups! Parece que aún no canjeaste tus beneficios.
              </h5>
            </div>
          )}
        </div>
      ) : (
        <div className='d-flex justify-content-center align-items-center'>
          <img src={Loader} />
        </div>
      )}
    </>
  );
};

export default MyBenefitsList;
