import Banner from "assets/images/banner.png";
import useCityHalls from "./useCityHalls";
import useActivities from "./useActivities";
import "./style.scss";
import useScrollToTop from "hooks/useScrollToTop";
import reducer from "./reducer";
import { useReducer } from "react";
import useSendMail from "./useSendMail";
import useCategories from "./useCategories";
import useLangContext from "hooks/useLangContext";

const ContactForm = () => {
  const [formData, dispatch] = useReducer(reducer, {});
  const sendMail = useSendMail();
  const [cityHalls, loadingCityHalls] = useCityHalls();
  const [activities, loading] = useActivities();
  const categories = useCategories();
  const { lang } = useLangContext();
  useScrollToTop();

  const send = (event: React.FormEvent<HTMLFormElement>) => {
    sendMail.save(formData);
    event.stopPropagation();
    event.preventDefault();
  };
  return (
    <>
      <div className='container-fluid contactForm p-0'>
        <img src={Banner} />

        <div className='container banner'>
          <div className='text-side col-lg-5 h-100 d-flex flex-column justify-content-center '>
            <h2 className='text-secondary font-weight-bold mb-4'>
              {lang.ToBePart.titleHero}
            </h2>
            <h6 className='my-2'>{lang.ToBePart.subTitleHero}</h6>
          </div>
        </div>
      </div>

      <div className='container-full'>
        <form onSubmit={send} className='container bg-white formContent'>
          <div className='intro text'>
            <h3 className='font-weight-bold mb-1'>{lang.ToBePart.title}</h3>
          </div>

          <div className='row fields mt-5'>
            <label className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <span className='font-weight-bold'>{lang.ToBePart.name}</span>
              <input
                className='border-rounded no-arrow'
                type='text'
                onChange={e => {
                  dispatch({ type: "name", payload: e.target.value });
                }}
                required
                name='first_name'
                placeholder={lang.ToBePart.namePlaceholder}
              />
            </label>
            <label className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <span className='font-weight-bold'>{lang.ToBePart.lastName}</span>
              <input
                onChange={e => {
                  dispatch({ type: "lastname", payload: e.target.value });
                }}
                className='border-rounded no-arrow'
                type='text'
                required
                name='last_name'
                placeholder={lang.ToBePart.lastNamePlaceholder}
              />
            </label>
            {!loadingCityHalls && cityHalls ? (
              <label className='col-lg-6 col-md-12 col-sm-12 col-12'>
                <label>{lang.ToBePart.location}</label> <br />
                <select
                  onChange={e => {
                    dispatch({
                      type: "municipio",
                      payload: cityHalls[e.target.value - 1]?.name
                    });
                  }}
                  name='municipio'
                  placeholder={lang.ToBePart.locationSelect}>
                  <option value={undefined}>
                    {lang.ToBePart.locationSelect}
                  </option>
                  {cityHalls.map(cityHall => (
                    <option key={cityHall.id} value={cityHall.id}>
                      {cityHall.name}
                    </option>
                  ))}
                </select>
              </label>
            ) : null}
            {!loading && activities ? (
              <label className='col-lg-6 col-md-12 col-sm-12 col-12'>
                <label>{lang.ToBePart.kindOfActivity}</label> <br />
                <select
                  onChange={e => {
                    dispatch({
                      type: "actividad",
                      payload: activities.find(
                        activitie => activitie.id === e.target.value
                      )?.tag
                    });
                  }}
                  name='actividad'
                  placeholder={lang.ToBePart.kindOfActivityPlaceholder}>
                  <option value={undefined}>
                    {lang.ToBePart.kindOfActivity}
                  </option>
                  {activities.map(activity => (
                    <option key={activity?.id} value={activity.id}>
                      {activity.tag}
                    </option>
                  ))}
                </select>
              </label>
            ) : null}
            {categories && (
              <label className='col-lg-6 col-md-12 col-sm-12 col-12'>
                <label>{lang.ToBePart.category}</label> <br />
                <select
                  onChange={e => {
                    dispatch({
                      type: "categoria",
                      payload: categories.find(
                        category => category == e.target.value
                      )
                    });
                  }}
                  name='categories'
                  placeholder={lang.ToBePart.categorySelect}>
                  <option value={undefined}>
                    {lang.ToBePart.categorySelect}
                  </option>
                  {categories.map((category, i) => (
                    <option key={i} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </label>
            )}
            <label className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <span className='font-weight-bold'>{lang.ToBePart.email}</span>
              <input
                onChange={e => {
                  dispatch({ type: "email", payload: e.target.value });
                }}
                className='border-rounded no-arrow'
                type='email'
                required
                name='email'
                placeholder='ejemplo@mail.com'
              />
            </label>
            <label className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <span className='font-weight-bold'>{lang.ToBePart.tel}</span>
              <input
                onChange={e => {
                  dispatch({ type: "phone", payload: e.target.value });
                }}
                className='border-rounded no-arrow mw-100'
                type='number'
                required
                name='phone'
                placeholder='3424558219'
              />
            </label>
            <label className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <span className='font-weight-bold'>{lang.ToBePart.web}</span>
              <input
                onChange={e => {
                  dispatch({ type: "web", payload: e.target.value });
                }}
                className='border-rounded no-arrow'
                type='text'
                name='web'
                placeholder={lang.ToBePart.webPlaceholder}
              />
            </label>
            <label className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <span className='font-weight-bold'>
                {lang.ToBePart.instagram}
              </span>
              <input
                onChange={e => {
                  dispatch({
                    type: "instagram_profile",
                    payload: e.target.value
                  });
                }}
                className='border-rounded no-arrow'
                type='text'
                name='instagram'
                placeholder={lang.ToBePart.instPlaceholder}
              />
            </label>
            <label className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <span className='font-weight-bold'>{lang.ToBePart.facebook}</span>
              <input
                onChange={e => {
                  dispatch({
                    type: "facebook_profile",
                    payload: e.target.value
                  });
                }}
                className='border-rounded no-arrow'
                type='text'
                name='facebook'
                placeholder={lang.ToBePart.facebookPlaceHolder}
              />
            </label>
            <label className='col-lg-12'>
              <span className='font-weight-bold'>
                {lang.ToBePart.description}
              </span>
              <textarea
                onChange={e => {
                  dispatch({ type: "detail", payload: e.target.value });
                }}
                rows={4}
                className='border-rounded no-arrow'
                maxLength={300}
                required
                name='description'
                placeholder={lang.ToBePart.descriptionPlaceholder}
              />
            </label>
          </div>
          <button
            disabled={
              !(
                formData.name !== "" &&
                formData.name !== undefined &&
                formData.lastname !== "" &&
                formData.lastname !== undefined &&
                formData.email !== "" &&
                formData.email !== undefined &&
                formData.phone !== "" &&
                formData.phone !== undefined &&
                formData.detail !== "" &&
                formData.detail !== undefined
              )
            }
            className={`secondary secondary--magenta mx-auto mt-4`}
            type='submit'>
            {sendMail.loadingAction && (
              <span
                className='spinner-border spinner-border-sm mr-2'
                role='status'
                aria-hidden='true'
              />
            )}
            {lang.Elements.btnSend}
          </button>
        </form>
      </div>
    </>
  );
};

export default ContactForm;
