import usePrompt from "hooks/usePrompt";
import { useCallback } from "react";
import BenefitQR from "./BenefitQR";

const useBenefitQRModal = (benefitId: string | null, goToMap?: () => void) => {
  const prompt = usePrompt();

  return useCallback(async () => {
    if (benefitId) {
      prompt<any>(props => (
        <BenefitQR
          onClickMap={() => goToMap?.()}
          benefitId={benefitId}
          {...props}
        />
      ))
        .then(response => console.log())
        .catch(error => console.error(error));
    }
  }, [benefitId]);
};

export default useBenefitQRModal;
