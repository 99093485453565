import CarruselHero from "components/CarruselHero/CarruselHero";
import useFaved from "hooks/useFaved";
import useSocialShare from "hooks/useSocialShare";
import { useState } from "react";
import { distance } from "helpers";
import useGPS from "hooks/useGPS";
import classes from "./styles/index.module.scss";
import FooterHero from "components/TextualHero/FooterHero";
import useLangContext from "hooks/useLangContext";
import { useParams } from "react-router-dom";

const CityHallHero = (cityHall: any) => {
  const param = useParams<{ id: string }>();
  const { lang } = useLangContext();
  const [coords] = useGPS();
  const [more, setMore] = useState(false);
  const useShareSocial = useSocialShare(param.id, "municipio", cityHall);
  const [faved, fav] = useFaved(cityHall.id, "municipio", cityHall.is_favorite);

  return (
    <div className='container-fluid bg-white p-0 overflow-hidden'>
      <div className='row bg-white'>
        {cityHall.images ? (
          <CarruselHero uris={cityHall.images} />
        ) : (
          <div
            className={`col-12 col-md-12 col-lg-5 col-xl-6 ${classes.imageSide}`}
            style={{
              backgroundImage: "url(" + cityHall.media?.[0].uri + ")"
            }}>
            <img
              className='w-100 rounded'
              src={cityHall.media?.[0].uri}
              alt=''
            />
          </div>
        )}
        <div
          className={`col-sm-12 col-md-12 col-lg-5 col-xl-4 ${classes.textSide}`}>
          <div className='flex-wrap d-flex justify-content-between h-100'>
            <div className={classes.top}>
              {coords && (
                <h5 className='text-muted small'>
                  A {Math.floor(distance(coords, cityHall))} Km
                </h5>
              )}
              <h2 className='my-3'>{cityHall.name}</h2>
            </div>
            <div
              className={`bottom d-flex flex-wrap justify-content-between ${classes.container}`}>
              <div className='d-flex justify-content-between align-items-end w-100'>
                <div className='col-12  p-0'>
                  {cityHall.review && cityHall.review?.length > 400 ? (
                    <div className={classes.descriptionBlock}>
                      {!more ? (
                        <>
                          <p className={classes.description} id='description'>
                            {cityHall.review?.slice(0, 200)}...
                            <span
                              className='text-magenta clickable ml-2'
                              onClick={e => {
                                setMore(true);
                                document
                                  .getElementById("description")
                                  ?.classList.add("scrollDescription");
                              }}>
                              {lang.Elements.seeMore}
                            </span>
                          </p>
                        </>
                      ) : (
                        <p className={classes.description}>{cityHall.review}</p>
                      )}
                    </div>
                  ) : (
                    <p className={classes.description}>{cityHall.review}</p>
                  )}
                </div>
              </div>

              <div className={`${classes.separador} my-5`}></div>

              <FooterHero
                onShare={useShareSocial}
                onFav={fav}
                isFavorite={faved}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CityHallHero;
