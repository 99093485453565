import { useMemo } from "react";
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import getEnvVariable from "env";
import useAccessToken from "hooks/useAccessToken";
import { createUploadLink } from "apollo-upload-client";

const useCustomClient = () => {
  const token = useAccessToken();
  const authorizationHeader = token
    ? { authorization: `Bearer ${token}` }
    : undefined;

  const link = new HttpLink({
    uri: getEnvVariable(token ? "API_URL_AUTH" : "API_URL"),
    headers: {
      ...authorizationHeader,
      clientInformation: "web"
    }
  });
  const client = useMemo(
    () =>
      new ApolloClient({
        uri: getEnvVariable("API_URL"),
        // link: createUploadLink(),
        // link,
        link: createUploadLink({
          uri: getEnvVariable(token ? "API_URL_AUTH" : "API_URL"),
          headers: token
            ? {
                authorization: `Bearer ${token}`,
                clientinformation: "app"
              }
            : {
                clientinformation: "app"
              }
        }),
        cache: new InMemoryCache({
          dataIdFromObject: ({ id, __typename }) =>
            id && __typename ? id + __typename : String(Math.random())
        })
      }),
    [token]
  );

  return client;
};

export default useCustomClient;
