import { LIGHT_GRAY } from "assets/colors";
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import classes from "./classes.module.scss";
import Icon from "assets/icons";
import useCodeBurner from "hooks/useCodeBurner";

type BurnQRProps = {
  onClose: (param: any) => any;
  recessId?: string;
};

const BurnQR: React.FC<BurnQRProps> = ({ onClose, recessId }) => {
  console.log(recessId);

  const codeBurner = useCodeBurner(recessId);

  const burnner = (codeQR: string) => {
    codeBurner.burn(codeQR);
    if (codeBurner.loadingAction == false) {
      onClose("");
    }
  };
  return (
    <div className={classes.container}>
      <div className={`${classes.modal} p-3 shadow align-items-center`}>
        <div className='w-100 d-flex'>
          <button onClick={onClose} className='p-0 h-auto align-top d-flex'>
            <Icon
              className='d-flex'
              custom='Cross'
              fill={LIGHT_GRAY}
              size={14}
            />
          </button>
        </div>
        {codeBurner.loadingAction ? (
          <div className='p-0 px-2'>
            <span
              className='spinner-border spinner-border-sm primary--magenta'
              role='status'
              aria-hidden='true'
            />
          </div>
        ) : (
          <BarcodeScannerComponent
            width={280}
            height={250}
            onUpdate={(err, result) => {
              if (result) burnner(result.text);
            }}
          />
        )}
        <button
          className='secondary secondary--magenta px-2 py-1'
          onClick={onClose}>
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default BurnQR;
