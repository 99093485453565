import React from "react";
import { VIOLET, WHITE } from "assets/colors";
import classes from "./classes.module.scss";

const Range = ({ scale, value: selectedValue, onChange }) => {
  return (
    <div
      className={`d-flex justify-content-between align-items-center ${classes.range}`}>
      <h6 className={`${classes.textLeft}`}>Nada Probable</h6>
      {scale.map(value => (
        <div
          className='d-flex align-items-center justify-content-center '
          key={value}>
          <div
            className={`rounded-circle d-flex align-items-center ${
              value === selectedValue
                ? classes.itemRangeSelect
                : classes.itemRange
            }`}
            onClick={() => onChange(value)}
            style={{
              background: value === selectedValue ? VIOLET : "transparent",
              borderColor: value === selectedValue ? VIOLET : "black"
            }}>
            <h6
              className='d-flex align-items-center m-auto'
              style={{ color: value === selectedValue ? WHITE : "black" }}>
              <small>{value}</small>
            </h6>
          </div>
        </div>
      ))}
      <h6 className={`${classes.textRight}`}>Muy Probable</h6>
    </div>
  );
};

export default Range;
