export default {
  title: "My schedule",
  mear: "Upcoming",
  from: "",
  daysEnd: "days ago",
  past: "Past",
  missing: "",
  days: "days",
  noResult: "You have not yet added a ReCreo to your schedule"
};
